import * as React from "react";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import QuestionMarkIcon from "@mui/icons-material/Help";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface TableColumn {
  id: string;
  label: string;
  helpText?: React.ReactNode;
  width?: string;
}

interface Props {
  tableHeaders: TableColumn[];
  data: any;
  rowRenderer: (data: any) => React.ReactNode;
  keyExtractor: (key: any) => string | number;
  loading: boolean;
}

export default function QuoteDataTableTemplate({
  tableHeaders,
  data,
  rowRenderer,
  keyExtractor,
  loading,
}: Props) {
  return (
    <div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((column) => (
                <TableCell key={column.id}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" fontWeight="bold">
                      {column.label}
                    </Typography>
                    {column.helpText && (
                      <Tooltip title={column.helpText} placement="top">
                        <QuestionMarkIcon
                          fontSize="small"
                          color="action"
                          sx={{
                            "&:hover": {
                              cursor: "help",
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Render the rows */}
            {data.map((row: any) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={keyExtractor(row)}
              >
                {rowRenderer(row)}
              </TableRow>
            ))}

            {/* No data, not loading, show empty message */}
            {!loading && data.length === 0 && (
              <TableRow>
                <TableCell colSpan={tableHeaders.length}>
                  <Typography
                    sx={{ textAlign: "center", fontStyle: "italic" }}
                    variant="body2"
                  >
                    No data
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {/* Loading, show loading message */}
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={tableHeaders.length}
                  sx={{ backgroundColor: "#eee" }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    mt={1}
                  >
                    <Typography sx={{ fontStyle: "italic" }} variant="body2">
                      Loading ...
                    </Typography>
                    <CircularProgress color="info" size={18} />
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ position: "relative" }}></div>
    </div>
  );
}
