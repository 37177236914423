import { useRef, useEffect } from "react";
import { addDays, format, differenceInDays } from "date-fns";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Download";
import Button from "@mui/lab/LoadingButton";
import { CSVLink } from "react-csv";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";
import {
  search,
  selectLoading as selectLoadingReport,
} from "../../lib/store/features/quoteReports.slice";
import {
  getLastModifiedDates,
  makeSelectStampDuty,
} from "../../lib/store/features/quoteData.slice";
import { useAppDispatch, useAppSelector, useCallbackState } from "../../hooks";
import {
  QuoteFormData,
  QuoteFormState,
  QuotePageResult,
  QuotePageResultProductPubLiab,
  QuotePageResultProductVehicle,
  QuotePageResultProductWrkCmp,
  QuoteType,
} from "../../lib/types";
import {
  getTotalsCalculations,
  calculatePremium,
  calculateTppdLimitIncreaseAmount,
} from "../../lib/helpers";
import {
  makeSelectAllQuoteData,
  selectLoadingQuoteData,
} from "../../lib/store/features/quoteData.slice";

const headers = [
  {
    label: "Reference",
    key: "quoteReference",
  },
  {
    label: "Status",
    key: "quoteStatus",
  },
  {
    label: "Effective Date",
    key: "effectiveDate",
  },
  {
    label: "Expiry Date",
    key: "expiryDate",
  },
  {
    label: "Client",
    key: "client",
  },
  {
    label: "Products (type:premium)",
    key: "products",
  },
  {
    label: "Quote Total",
    key: "total",
  },
  {
    label: "User",
    key: "user",
  },
  {
    label: "User Brokerage",
    key: "userBrokerage",
  },
];

function Reports() {
  const dispatch = useAppDispatch();
  const loadingReport = useAppSelector(selectLoadingReport);
  const loadingQuoteData = useAppSelector(selectLoadingQuoteData);
  const [data, setData] = useCallbackState<any[] | null>(null);
  const [fromDate, setFromDate] = useCallbackState<Date>(new Date());
  const [toDate, setToDate] = useCallbackState<Date>(addDays(new Date(), 7));
  const csvLink = useRef(null);
  const allQuoteData = useAppSelector(makeSelectAllQuoteData());
  const stampDuty = useAppSelector(makeSelectStampDuty());

  useEffect(() => {
    dispatch(getLastModifiedDates({ quoteType: "all" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculatePremiumValue =
    (quoteType: QuoteType) => (sumInsured: number) => {
      if (!allQuoteData[quoteType]) return 0;

      return calculatePremium({
        sumInsured,
        quoteData: allQuoteData[quoteType] as QuoteFormData,
      });
    };

  const calculateTppdLimitIncreaseValue =
    (
      quoteType: QuoteType,
      insuranceValues: QuoteFormState["insuranceValues"]
    ) =>
    (premium: number) => {
      if (!allQuoteData[quoteType]) return 0;

      return calculateTppdLimitIncreaseAmount({
        quoteData: allQuoteData[quoteType] as QuoteFormData,
        premium,
        insuranceValues,
      });
    };

  const calculateTotal = (products: QuotePageResult["products"]) => {
    return products.reduce((acc, product) => {
      const quoteType = product.quoteType as QuoteType;
      if (quoteType === "workers_compensation") {
        const { subTotal, sumInsuredPremium, optionalExtras } =
          product as QuotePageResultProductWrkCmp;
        const grandTotal = subTotal + sumInsuredPremium + optionalExtras;
        return acc + Number(grandTotal.toFixed(2));
      }

      if (quoteType === "public_liability") {
        const { lolPremiumAmount } = product as QuotePageResultProductPubLiab;
        const grandTotal = lolPremiumAmount;
        return acc + Number(grandTotal.toFixed(2));
      }

      const { grandTotal } = getTotalsCalculations({
        sumInsuredPremium: (product as QuotePageResultProductVehicle)
          .sumInsuredPremium,
        yearsRequired: (product as QuotePageResultProductVehicle).quoteYears,
        sumInsured: (product as QuotePageResultProductVehicle).sumInsured,
        calculationFunctions: {
          calculatePremium: calculatePremiumValue(quoteType),
          calculateExtras: calculateTppdLimitIncreaseValue(quoteType, {
            cFreeDiscount: (product as QuotePageResultProductVehicle)
              .cFreeDiscount,
            cFreeDiscountAmount: (product as QuotePageResultProductVehicle)
              .cFreeDiscountAmount,
            aExcessDiscount: (product as QuotePageResultProductVehicle)
              .aExcessDiscount,
            aExcessDiscountAmount: (product as QuotePageResultProductVehicle)
              .aExcessDiscountAmount,
            nDriverDiscount: (product as QuotePageResultProductVehicle)
              .nDriverDiscount,
            nDriverDiscountAmount: (product as QuotePageResultProductVehicle)
              .nDriverDiscountAmount,
            sumInsured: (product as QuotePageResultProductVehicle).sumInsured,
            premium: (product as QuotePageResultProductVehicle).premium,
            standardExcess: (product as QuotePageResultProductVehicle)
              .standardExcess,
            totalLossExcess: (product as QuotePageResultProductVehicle)
              .totalLossExcess,
            inexperiencedDriverExcess: (
              product as QuotePageResultProductVehicle
            ).inexperiencedDriverExcess,
            driverNames: (product as QuotePageResultProductVehicle).driverNames,
            tppdLimitInc: (product as QuotePageResultProductVehicle)
              .tppdLimitInc,
            tppdLimitIncAmount: (product as QuotePageResultProductVehicle)
              .tppdLimitIncAmount,
          }),
        },
        discountPercentage: (product as QuotePageResultProductVehicle)
          .discountPercentage,
        minPremium: allQuoteData[quoteType]?.minimumPremium.premium || 0,
        stampDuty,
      });

      return acc + Number(grandTotal.toFixed(2));
    }, 0);
  };

  const handleClick = async () => {
    try {
      setData(() => null);

      const result = await dispatch(
        search({
          from: format(fromDate, "yyyy-MM-dd"),
          to: format(toDate, "yyyy-MM-dd"),
        })
      );

      if (result.meta.requestStatus === "rejected") {
        dispatch(
          showNotif({
            message: result.payload || "There was a problem getting the quotes",
            options: { variant: "error" },
          })
        );
        console.error("error:", result.payload);
        return;
      }

      setData(
        (result.payload as QuotePageResult[]).map((result) => {
          return {
            quoteReference: result.quoteReference,
            client: `${result.clientTitle} ${result.clientFirstName} ${result.clientLastName}`,
            quoteStatus: result.quoteStatus,
            effectiveDate: result.effectiveDate,
            expiryDate: format(
              addDays(new Date(result.effectiveDate), 30),
              "yyyy-MM-dd"
            ),
            user: result.user.name,
            userBrokerage: result.user.brokerage_id
              ? result.user.brokerage?.name
              : "",
            products: `${result.products
              .map((product) => {
                if (product.quoteType === "workers_compensation") {
                  const { subTotal, sumInsuredPremium, optionalExtras } =
                    product as QuotePageResultProductWrkCmp;
                  const grandTotal =
                    subTotal + sumInsuredPremium + optionalExtras;
                  return `${product.quoteType}:${grandTotal.toFixed(2)}`;
                } else if (product.quoteType === "public_liability") {
                  const { lolPremiumAmount } =
                    product as QuotePageResultProductPubLiab;
                  const grandTotal = lolPremiumAmount;
                  return `${product.quoteType}:${grandTotal.toFixed(2)}`;
                }

                return `${product.quoteType}:${(
                  product as QuotePageResultProductVehicle
                ).sumInsuredPremium.toFixed(2)}`;
              })
              .join(" | ")}`,
            total: calculateTotal(result.products),
          };
        }),
        () => {
          if (csvLink.current) {
            (csvLink.current as any).link.click();
          }
        }
      );
    } catch (error) {
      dispatch(
        showNotif({
          message: "There was a problem getting the quotes",
          options: { variant: "error" },
        })
      );
      console.error(error);
    }
  };

  const handleChangeFromDate = (date: Date | null) => {
    if (!date) return;
    setFromDate(date);
  };

  const handleChangeToDate = (date: Date | null) => {
    if (!date) return;
    setToDate(date);
  };

  const incorrectLength = differenceInDays(toDate, fromDate) > 90;

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          pt: 2,
          pb: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              textAlign="center"
            >
              CSV - Quote Reports
            </Typography>
            <Typography color="GrayText" variant="body2" textAlign="center">
              (Maximum report length is 90 days)
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={handleChangeFromDate}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={incorrectLength}
                    helperText={
                      incorrectLength
                        ? "Report length must be less than 90 days"
                        : ""
                    }
                    size="small"
                  />
                )}
              />
              <Typography variant="body2" fontWeight="bold">
                -
              </Typography>
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={handleChangeToDate}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={toDate < fromDate}
                    size="small"
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="large"
              disabled={
                toDate < fromDate || loadingQuoteData || incorrectLength
              }
              loading={loadingReport}
              loadingPosition="end"
              endIcon={<SearchIcon />}
              onClick={handleClick}
            >
              <Typography variant="body2" fontWeight="bold">
                Download CSV
              </Typography>
            </Button>
            {data && (
              <CSVLink
                ref={csvLink}
                data={data}
                headers={headers}
                filename={`quote-report-${format(
                  fromDate,
                  "yyyyMMdd"
                )}-${format(toDate, "yyyyMMdd")}.csv`}
                target="_blank"
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default Reports;
