import { useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import { validationSchema } from "./editProfileValidationSchema";

interface Props {
  open: boolean;
  name: string;
  email: string;
  phone: string;
  onSubmit: ({
    name,
    email,
    phone,
  }: {
    name: string;
    email: string;
    phone: string;
  }) => Promise<boolean>;
  onClose: (_: any, reason?: "escapeKeyDown" | "backdropClick") => void;
}

function EditProfileDialog({
  open,
  name,
  phone,
  email,
  onClose,
  onSubmit,
}: Props) {
  const [loading, setLoading] = useState(false);

  const fields: { [key: string]: string | boolean } = {
    name: name,
    email: email,
    phone: phone,
  };

  const inputProps: { [key: string]: any } = {
    name: {
      label: "Your name",
      placeholder: "Enter your full name",
      type: "text",
      Component: TextField,
      required: true,
    },
    email: {
      label: "Your email address",
      placeholder: "Enter your email address",
      type: "text",
      Component: TextField,
      required: true,
    },
    phone: {
      label: "Your phone number",
      placeholder: "Enter your phone number",
      type: "text",
      Component: TextField,
      required: false,
    },
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown
        disableEnforceFocus
        fullWidth
        maxWidth="sm"
      >
        <Formik
          initialValues={{ ...fields }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            await onSubmit({
              name: values.name as string,
              email: values.email as string,
              phone: values.phone as string,
            });
            setSubmitting(false);
            setLoading(false);
          }}
        >
          <Form noValidate>
            <DialogTitle>Edit Profile</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={0.1}>
                {Object.keys(fields).map((fieldName) => {
                  if (!inputProps[fieldName]) return null;

                  const { Component, type, name, grid, evalProps, ...props } =
                    inputProps[fieldName];

                  return (
                    <Grid item xs={12} key={fieldName} {...grid}>
                      <Field type={type} name={fieldName}>
                        {({ field, meta, form }: FieldProps) => {
                          return (
                            <Component
                              {...field}
                              {...props}
                              {...(evalProps
                                ? evalProps({ field, meta, form })
                                : {})}
                              type={type}
                              disabled={loading || form.isSubmitting}
                              fullWidth={type === "text"}
                              error={meta.touched && !!meta.error}
                              helperText={
                                meta.touched && !!meta.error ? meta.error : null
                              }
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="contained"
                type="submit"
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
}

export default EditProfileDialog;
