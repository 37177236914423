import type {
  WrkCmpQuoteFormState,
  QuotePageResultProductWrkCmp,
  PublicLiabilityQuoteFormState,
  QuotePageResultProductPubLiab,
} from "../types";

export const pubLiabReactToApi = (pubLiab: PublicLiabilityQuoteFormState) => {
  return {
    workOccupationId: pubLiab.selectedOccupation?.id,
    workCategoryId: pubLiab.selectedCategory?.id,
    claimsHistory: pubLiab.claimsHistory.map((claim) => ({
      year: claim.year,
      claim_amount: claim.claim_amount,
      claim_count: claim.claim_count,
    })),
    k1millLOLSelectedVal: pubLiab.selectedLOLValues["K1million LOL"],
    k2millLOLSelectedVal: pubLiab.selectedLOLValues["K2million LOL"],
    k5millLOLSelectedVal: pubLiab.selectedLOLValues["K5million LOL"],
    k10millLOLSelectedVal: pubLiab.selectedLOLValues["K10million LOL"],
    k1millLOLSelectedAmt: pubLiab.selectedLOLAmounts["K1million LOL"],
    k2millLOLSelectedAmt: pubLiab.selectedLOLAmounts["K2million LOL"],
    k5millLOLSelectedAmt: pubLiab.selectedLOLAmounts["K5million LOL"],
    k10millLOLSelectedAmt: pubLiab.selectedLOLAmounts["K10million LOL"],
    turnover: pubLiab.turnover,
    lolPremiumAmount: pubLiab.lolPremiumAmount,
  };
};

export const pubLiabApiToReact = (pubLiab: QuotePageResultProductPubLiab) => {
  // id?: number;
  // selectedCategory: WorkCategory | null;
  // selectedOccupation: WorkOccupation | null;
  // selectedLOLValues: PublicLiabilityLOLSelectedValues;
  // selectedLOLAmounts: PublicLiabilityLOLSelectedAmounts;
  // loading: boolean;
  // claimsHistory: PublicLiabilityClaimHistoryItem[];
  // turnover: number;
  // lolPremiumAmount: number;

  return {
    selectedCategory: pubLiab.workCategory,
    selectedOccupation: pubLiab.workOccupation,
    loading: false,
    claimsHistory: pubLiab.claimsHistory,
    selectedLOLValues: {
      "K1million LOL": pubLiab.k1millLOLSelectedVal,
      "K2million LOL": pubLiab.k2millLOLSelectedVal,
      "K5million LOL": pubLiab.k5millLOLSelectedVal,
      "K10million LOL": pubLiab.k10millLOLSelectedVal,
    },
    selectedLOLAmounts: {
      "K1million LOL": pubLiab.k1millLOLSelectedAmt,
      "K2million LOL": pubLiab.k2millLOLSelectedAmt,
      "K5million LOL": pubLiab.k5millLOLSelectedAmt,
      "K10million LOL": pubLiab.k10millLOLSelectedAmt,
    },
    turnover: pubLiab.turnover,
    lolPremiumAmount: pubLiab.lolPremiumAmount,
  };
};

export const wrkCmpReactToApi = (wrkCmp: WrkCmpQuoteFormState) => {
  return {
    workOccupationId: wrkCmp.selectedOccupation?.id,
    workCategoryId: wrkCmp.selectedCategory?.id,
    claimsHistory: wrkCmp.claimsHistory.map((claim) => ({
      year: claim.year,
      claim_amount: claim.claim_amount,
      claim_count: claim.claim_count,
    })),
    oeHausKraiId: wrkCmp.selectedOptionalExtras["Haus Krai"],
    oeHausKraiAmount: wrkCmp.selectedOptionalExtraAmounts["Haus Krai"],
    oeBekimPeiId: wrkCmp.selectedOptionalExtras["BEKIM PEI"],
    oeBekimPeiAmount: wrkCmp.selectedOptionalExtraAmounts["BEKIM PEI"],
    oeCommonLawId: wrkCmp.selectedOptionalExtras["Common Law"],
    oeCommonLawAmount: wrkCmp.selectedOptionalExtraAmounts["Common Law"],
    oeWethaId: wrkCmp.selectedOptionalExtras.WETHA,
    oeWethaAmount: wrkCmp.selectedOptionalExtraAmounts.WETHA,
    sumInsuredPremium: wrkCmp.calculations.sumInsuredPremium,
    optionalExtras: wrkCmp.calculations.optionalExtras,
    subTotal: wrkCmp.calculations.subTotal,
    wageRoll: wrkCmp.wageRoll,
  };
};

export const wrkCmpApiToReact = (wrkCmp: QuotePageResultProductWrkCmp) => {
  return {
    selectedCategory: wrkCmp.workCategory,
    selectedOccupation: wrkCmp.workOccupation,
    selectedOptionalExtras: {
      "Haus Krai": wrkCmp.oeHausKraiId,
      "BEKIM PEI": wrkCmp.oeBekimPeiId,
      "Common Law": wrkCmp.oeCommonLawId,
      WETHA: wrkCmp.oeWethaId,
    },
    selectedOptionalExtraAmounts: {
      "Haus Krai": wrkCmp.oeHausKraiAmount,
      "BEKIM PEI": wrkCmp.oeBekimPeiAmount,
      "Common Law": wrkCmp.oeCommonLawAmount,
      WETHA: wrkCmp.oeWethaAmount,
    },
    wageRoll: wrkCmp.wageRoll,
    loading: false,
    claimsHistory: wrkCmp.claimsHistory,
    calculations: {
      sumInsuredPremium: wrkCmp.sumInsuredPremium,
      optionalExtras: wrkCmp.optionalExtras,
      subTotal: wrkCmp.subTotal,
    },
  };
};
