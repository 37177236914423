import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
