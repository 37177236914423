import React from "react";
import grey from "@mui/material/colors/grey";
import blue from "@mui/material/colors/blue";
import cyan from "@mui/material/colors/cyan";
import green from "@mui/material/colors/green";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FaceIcon from "@mui/icons-material/Face";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { getContrast } from "../../lib/helpers";
import { Role as RoleType } from "../../lib/types";

function Role({ role }: { role: RoleType }) {
  let bgColor: string = grey[200];
  let Icon = FaceIcon;
  let tooltip = "";

  switch (role) {
    case "admin":
      bgColor = blue[900];
      Icon = AccountCircleIcon;
      tooltip = "Can create and search quotes from Brokerage";
      break;
    case "agent":
      bgColor = green[900];
      Icon = FaceIcon;
      tooltip = "Can create and search own quotes";
      break;
    case "superadmin":
      bgColor = cyan[900];
      Icon = LocalPoliceIcon;
      tooltip = "Has access to everything";
      break;
  }

  return (
    <Tooltip title={tooltip} placement="top">
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        sx={{
          padding: 0.5,
          backgroundColor: bgColor,
          borderRadius: 1,
          maxWidth: 105,
          "&:hover": {
            cursor: "help",
          },
        }}
      >
        <Icon sx={{ color: getContrast(bgColor), fontSize: 18 }} />
        <Typography
          variant="body2"
          sx={{
            color: getContrast(bgColor),
            p: 0,
            m: 0,
            textTransform: "capitalize",
            fontSize: 13,
          }}
        >
          {role}
        </Typography>
      </Stack>
    </Tooltip>
  );
}

export default Role;
