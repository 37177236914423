import BrokerageDialog from "../../../../components/BrokerageDialog";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../../../lib/store/features/dialogs.slice";
import {
  deleteById,
  updateBrokerage,
  selectedBrokerage,
  setSelectedBrokerageId,
} from "../../../../lib/store/features/brokerages.slice";
import { enqueue as showNotif } from "../../../../lib/store/features/notifications.slice";
import { Brokerage } from "../../../../lib/types";

function EditBrokerage() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("EDIT_BROKERAGE"));
  const brokerage = useAppSelector(selectedBrokerage);

  const handleClose = () => {
    dispatch(toggle({ dialog: "EDIT_BROKERAGE", open: false }));
    setTimeout(() => {
      dispatch(setSelectedBrokerageId(null));
    }, 250);
  };

  const handleSubmit = async (updBrokerage: Brokerage) => {
    if (!brokerage) return false;

    const result = await dispatch(
      updateBrokerage({
        id: brokerage.id,
        data: {
          name: updBrokerage.name,
          email: updBrokerage.email ? updBrokerage.email : null,
          phone: updBrokerage.phone,
          address_line_one: updBrokerage.address_line_one,
          address_line_two: updBrokerage.address_line_two,
        },
      })
    );

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "Brokerage successfully created",
        options: { variant: "success" },
      })
    );

    handleClose();

    return true;
  };

  const handleClickDeleteBrokerage = async () => {
    if (!brokerage) return false;

    const result = await dispatch(deleteById({ id: brokerage.id }));

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "Brokerage successfully deleted",
        options: { variant: "success" },
      })
    );

    handleClose();

    return true;
  };

  return (
    <BrokerageDialog
      open={open}
      brokerage={brokerage}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onClickDeleteBrokerage={handleClickDeleteBrokerage}
    />
  );
}

export default EditBrokerage;
