const { REACT_APP_API_HOST: apiHost } = process.env;

export const apiUrls = {
  /* User */
  auth: {
    login: `${apiHost}/login`,
  },

  profile: {
    me: `${apiHost}/me`,
    updatePassword: `${apiHost}/changePassword`,
  },

  /* Brokerages */
  brokerages: {
    list: `${apiHost}/brokerages`,
    parent: `${apiHost}/parent-brokerage`,
    byId: `${apiHost}/brokerages/:BROKERAGE_ID`,
    byIdQuoteTypesAccess: `${apiHost}/brokerages/:BROKERAGE_ID/quoteTypesAccess`,
    byIdQuoteFeaturesAccess: `${apiHost}/brokerages/:BROKERAGE_ID/quoteFeaturesAccess`,
    byIdStatus: `${apiHost}/brokerages/:BROKERAGE_ID/status`,
    byIdLogo: `${apiHost}/brokerages/:BROKERAGE_ID/logo`,
    assignUsers: `${apiHost}/brokerages/:BROKERAGE_ID/assign`,
    unassignUsers: `${apiHost}/brokerages/:BROKERAGE_ID/unassign`,
  },

  /* Quote Data */
  quoteData: {
    data: `${apiHost}/quoteData/:QUOTE_TYPE`,
    quoteTypes: `${apiHost}/quoteData/quoteTypes`,
    quoteFeatures: `${apiHost}/quoteData/quoteFeatures`,
    optionalExtras: `${apiHost}/optionalExtras`,
    stampDuty: `${apiHost}/quoteData/stampDuty`,
    lastModified: `${apiHost}/quoteData/:QUOTE_TYPE/lastModified`,
    wrkCmpCategories: `${apiHost}/workers-comp/categories`,
    wrkCmpOccupations: `${apiHost}/workers-comp/categories/:CATEGORY_ID/occupations`,
    publicLiabilityCategories: `${apiHost}/public-liability/categories`,
    publicLiabilityOccupations: `${apiHost}/public-liability/categories/:CATEGORY_ID/occupations`,
  },

  /* Quotes */
  quote: {
    save: `${apiHost}/quotes`,
    update: `${apiHost}/quotes/:QUOTE_ID`,
    sendCoCEmail: `${apiHost}/quotes/:QUOTE_ID/send-coc`,
    updateStatusAccept: `${apiHost}/quotes/:QUOTE_ID/status-accepted`,
    updateStatusReject: `${apiHost}/quotes/:QUOTE_ID/status-rejected`,
  },

  /* Quotes Pagination */
  quotesPagination: {
    page: `${apiHost}/quotes/page/:PAGE_NUMBER?limit=:LIMIT`,
  },

  /* Quote Reports */
  quoteReports: {
    search: `${apiHost}/quotes/search?range=FROM:TO`,
  },

  /* Settings email / app / other */
  settings: {
    emailTest: `${apiHost}/settings/email/send-test`,
    email: `${apiHost}/settings/email`,
    app: `${apiHost}/settings/app`,
    other: `${apiHost}/settings/other`,
  },

  /* Users */
  users: {
    list: `${apiHost}/users`,
    byId: `${apiHost}/users/:USER_ID`,
    byIdStatus: `${apiHost}/users/:USER_ID/status`,
    changePassword: `${apiHost}/users/:USER_ID/changePassword`,
  },
};
