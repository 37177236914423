import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SaveIcon from "@mui/icons-material/Save";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import HelpIcon from "@mui/icons-material/Help";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Brokerage,
  QuoteFeature,
  QuoteFeatureAccess,
  QuoteTypeAccess,
} from "../../lib/types";
import { mapQuoteTypeName, mapQuoteFeatureName } from "../../lib/helpers";
import { VEHICLE_QUOTE_TYPES } from "../../lib/constants";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

const StyledTooltip = styled(Tooltip)(() => ({
  "&:hover": {
    cursor: "help",
  },
}));

interface Props {
  open: boolean;
  brokerageName?: Brokerage["name"];
  quoteTypes: QuoteTypeAccess[];
  quoteFeatures: QuoteFeatureAccess[];
  selectedQuoteTypes?: Brokerage["quote_types_access"];
  selectedQuoteFeatures?: Brokerage["quote_features_access"];
  onClose: () => void;
  onSubmit: (data: {
    quoteTypes: QuoteTypesState;
    quoteFeatures: QuoteFeaturesState;
  }) => Promise<boolean>;
}

interface QuoteTypesState {
  [key: QuoteTypeAccess["id"]]: boolean;
}

interface QuoteFeaturesState {
  [key: QuoteFeatureAccess["id"]]: boolean;
}

export default function BrokerageQuoteTypesAccessDialog({
  open,
  brokerageName,
  onClose,
  selectedQuoteTypes,
  selectedQuoteFeatures,
  quoteTypes,
  quoteFeatures,
  onSubmit,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQT, setSelectedQuoteTypes] = useState<QuoteTypesState>({});
  const [selectedQF, setSelectedQuoteFeatures] = useState<QuoteFeaturesState>(
    {}
  );

  useEffect(() => {
    if (open && selectedQuoteTypes) {
      const _selected = selectedQuoteTypes.reduce((acc, quoteType) => {
        acc[quoteType.id] = true;
        return acc;
      }, {} as QuoteTypesState);

      setSelectedQuoteTypes(_selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedQuoteTypes)]);

  useEffect(() => {
    if (open && selectedQuoteFeatures) {
      const _selected = selectedQuoteFeatures.reduce((acc, quoteFeature) => {
        acc[quoteFeature.id] = true;
        return acc;
      }, {} as QuoteFeaturesState);

      setSelectedQuoteFeatures(_selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedQuoteFeatures)]);

  const handleChangeQT =
    (id: QuoteTypeAccess["id"]) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Compute the new selected quote types state
      const newSelectedQT = {
        ...selectedQT,
        [id]: !!event.target.checked,
      };

      // Set the new selected quote types state
      setSelectedQuoteTypes(newSelectedQT);

      // Check if certificate_of_currency feature is enabled
      const certificateOfCurrencyFeatureId = quoteFeatures.find(
        (qf) => qf.feature_name === "certificate_of_currency"
      )?.id;

      if (certificateOfCurrencyFeatureId !== undefined) {
        // Determine if any selected quote type is a vehicle product based on the new state
        const isSelectedVehicleProduct = quoteTypes.some(
          (quoteType) =>
            VEHICLE_QUOTE_TYPES.includes(quoteType.name) &&
            newSelectedQT[quoteType.id]
        );

        // If no vehicle products are selected and certificate_of_currency is enabled, disable it
        if (
          !isSelectedVehicleProduct &&
          selectedQF[certificateOfCurrencyFeatureId]
        ) {
          setSelectedQuoteFeatures((prevSelectedQF) => ({
            ...prevSelectedQF,
            [certificateOfCurrencyFeatureId]: false,
          }));
        }
      }
    };

  const handleChangeQF =
    (id: QuoteFeatureAccess["id"]) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedQuoteFeatures((values) => ({
        ...values,
        [id]: !!event.target.checked,
      }));
    };

  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit({
      quoteTypes: selectedQT,
      quoteFeatures: selectedQF,
    });
    setIsLoading(false);
  };

  const handleSelectAll = () => {
    const _selected = quoteTypes.reduce((acc, quoteType) => {
      acc[quoteType.id] = true;
      return acc;
    }, {} as QuoteTypesState);

    setSelectedQuoteTypes(_selected);
  };

  const handleSelectNone = () => {
    const _selected = quoteTypes.reduce((acc, quoteType) => {
      acc[quoteType.id] = false;
      return acc;
    }, {} as QuoteTypesState);

    setSelectedQuoteTypes(_selected);
  };

  const QFHelp: any = {
    certificate_of_currency:
      "The Certificate of Currency feature can only be enabled when at least one Motor Vehicle product is enabled",
  };

  const isQuoteFeatureDisabled = (quoteFeature: QuoteFeature) => {
    if (quoteFeature === "certificate_of_currency") {
      // Check if any of the selected quote types are in the VEHICLE_QUOTE_TYPES array
      const isSelectedVehicleProduct = quoteTypes.some(
        (quoteType) =>
          VEHICLE_QUOTE_TYPES.includes(quoteType.name) &&
          selectedQT[quoteType.id]
      );

      // Disable the feature if no vehicle product is selected
      return !isSelectedVehicleProduct;
    }

    return false;
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <DialogTitle>Quote Type Access</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Use the below list to control what quote types {brokerageName} has
              access to.
            </DialogContentText>

            <FormControl
              required
              error={false}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                {quoteTypes.map((quoteType) => (
                  <FormControlLabel
                    key={quoteType.id}
                    control={
                      <Checkbox
                        checked={!!selectedQT[quoteType.id]}
                        onChange={handleChangeQT(quoteType.id)}
                        name={mapQuoteTypeName(quoteType.name)}
                      />
                    }
                    label={mapQuoteTypeName(quoteType.name)}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Stack direction="row" spacing={1}>
              <Button
                onClick={handleSelectAll}
                variant="outlined"
                size="small"
                color="info"
                title="Select all quote types"
              >
                Select All
              </Button>
              <Button
                onClick={handleSelectNone}
                variant="outlined"
                size="small"
                color="warning"
                title="Deselect all quote types"
              >
                Deselect All
              </Button>
            </Stack>
          </DialogContent>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mr: "-1px", mt: 10 }} />
        <Grid item xs={12} lg={6}>
          <DialogTitle>Quote Features</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select the features that {brokerageName} has access to.
            </DialogContentText>

            <FormControl
              required
              error={false}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                {quoteFeatures.map((quoteFeature) => (
                  <Stack
                    key={quoteFeature.id}
                    direction="row"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!selectedQF[quoteFeature.id]}
                          onChange={handleChangeQF(quoteFeature.id)}
                          name={mapQuoteFeatureName(quoteFeature.feature_name)}
                          disabled={isQuoteFeatureDisabled(
                            quoteFeature.feature_name
                          )}
                        />
                      }
                      label={mapQuoteFeatureName(quoteFeature.feature_name)}
                    />
                    {!!QFHelp[quoteFeature.feature_name] && (
                      <StyledTooltip
                        title={QFHelp[quoteFeature.feature_name]}
                        placement="top"
                      >
                        <HelpIcon fontSize="small" htmlColor="grey" />
                      </StyledTooltip>
                    )}
                  </Stack>
                ))}
              </FormGroup>
            </FormControl>
          </DialogContent>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={isLoading}
          loadingPosition="end"
          endIcon={<SaveIcon />}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
