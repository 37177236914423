import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuestionMarkIcon from "@mui/icons-material/Help";
import ResetPasswordIcon from "@mui/icons-material/LockReset";
import EditIcon from "@mui/icons-material/Edit";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import Role from "../Role";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Title from "../Title";
import Badge from "../Badge";
import { Brokerage, Role as RoleType } from "../../lib/types";

function SectionTitle({ title, tooltip }: { title: string; tooltip?: string }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="body1" color="primary">
        {title}
      </Typography>
      {tooltip && (
        <Tooltip title={tooltip} placement="top">
          <QuestionMarkIcon
            fontSize="small"
            color="primary"
            sx={{
              "&:hover": {
                cursor: "help",
              },
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
}

interface Props {
  loading: boolean;
  name: string;
  email: string;
  role?: RoleType;
  brokerage: Brokerage | null;
  phone: string;
  isActive: boolean;
  onEdit: () => void;
  onResetPassword: () => void;
}

function UserProfile({
  name,
  email,
  loading,
  role,
  phone,
  brokerage,
  isActive,
  onEdit,
  onResetPassword,
}: Props) {
  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AccountCircleIcon color="primary" fontSize="large" />
              <Title>Acount Details</Title>
              {loading && <CircularProgress size={20} />}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Tooltip title="Edit your profile" placement="top">
                <span>
                  <IconButton disabled={loading} onClick={onEdit}>
                    <EditIcon color={loading ? "disabled" : "primary"} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Change your password" placement="top">
                <span>
                  <IconButton disabled={loading} onClick={onResetPassword}>
                    <ResetPasswordIcon
                      color={loading ? "disabled" : "primary"}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <SectionTitle title="Name" />
            <Typography variant="body1" color="textPrimary">
              {name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SectionTitle title="Email" />
            <Typography variant="body1" color="textPrimary">
              {email}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SectionTitle title="Phone" />
            <Typography
              variant="body1"
              color={phone ? "textPrimary" : "GrayText"}
            >
              {phone || "Not provided"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SectionTitle title="Brokerage" />
            <Typography
              variant="body1"
              color={brokerage ? "textPrimary" : "GrayText"}
            >
              {brokerage?.name || "Not with brokerage"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SectionTitle title="Role" />
            {!!role && <Role role={role} />}
          </Grid>

          <Grid item xs={12}>
            <SectionTitle
              title="Account Status"
              tooltip="If your accont status is inactive, you will not be able to login to the system."
            />
            <Badge
              status={isActive ? "Active" : "Inactive"}
              color={isActive ? green["600"] : red["100"]}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default UserProfile;
