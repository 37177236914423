import * as Yup from "yup";

export const validationSchema = Yup.object({
  host: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Host name is required"),
  port: Yup.number().required("Port is required"),
  username: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Username is required"),
  password: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Password is required"),
});
