import { request } from "../index";
import { apiUrls } from "../api-urls";

const urls = apiUrls.quotesPagination;

export function getPage({
  page,
  limit,
  query,
}: {
  page: number;
  limit: number;
  query?: string;
}) {
  let endpoint = urls.page
    .replace(":PAGE_NUMBER", `${page}`)
    .replace(":LIMIT", `${limit}`);

  if (query) {
    endpoint += `&query=${query}`;
  }

  return request({
    url: endpoint,
    method: "GET",
  });
}
