import { request } from "../index";
import { apiUrls } from "../api-urls";
import { wrkCmpReactToApi, pubLiabReactToApi } from "../../helpers";
import type {
  PublicLiabilityQuoteFormState,
  Quote,
  QuoteFormState,
  QuoteStatus,
  WrkCmpQuoteFormState,
} from "../../types";

const urls = apiUrls.quote;

export function saveQuote(quote: Quote) {
  const _quote = {
    ...quote,
    id: undefined,
    quoteReference: undefined,
    products: quote.products.map((product) => {
      if (product.quoteType === "workers_compensation") {
        return {
          ...wrkCmpReactToApi(product.data as WrkCmpQuoteFormState),
          quoteType: product.quoteType,
          id: undefined, // if we are saving a new quote, then the product id will have been generated locally... remove them so the backend allocates them.
        };
      } else if (product.quoteType === "public_liability") {
        return {
          ...pubLiabReactToApi(product.data as PublicLiabilityQuoteFormState),
          quoteType: product.quoteType,
        };
      } else {
        return {
          ...(product.data as QuoteFormState).clientVehicleValues,
          ...(product.data as QuoteFormState).insuranceValues,
          ...(product.data as QuoteFormState).calculations,
          quoteYears: (product.data as QuoteFormState).quoteYears,
          quoteType: product.quoteType,
          id: undefined, // if we are saving a new quote, then the product id will have been generated locally... remove them so the backend allocates them.
        };
      }
    }),
  };

  return request({
    url: urls.save,
    method: "POST",
    data: { quote: _quote },
  });
}

export function updateQuote(quote: Quote) {
  const _quote = {
    ...quote,
    products: quote.products.map((product) => {
      if (product.quoteType === "workers_compensation") {
        return {
          ...wrkCmpReactToApi(product.data as WrkCmpQuoteFormState),
          quoteType: product.quoteType,
        };
      } else if (product.quoteType === "public_liability") {
        return {
          ...pubLiabReactToApi(product.data as PublicLiabilityQuoteFormState),
          quoteType: product.quoteType,
        };
      } else {
        return {
          ...(product.data as QuoteFormState).clientVehicleValues,
          ...(product.data as QuoteFormState).insuranceValues,
          ...(product.data as QuoteFormState).calculations,
          quoteYears: (product.data as QuoteFormState).quoteYears,
          quoteType: product.quoteType,
        };
      }
    }),
  };

  return request({
    url: urls.update.replace(":QUOTE_ID", `${quote.id}`),
    method: "PUT",
    data: { quote: _quote },
  });
}

export function updateQuoteStatus(
  quoteId: Quote["id"],
  status: QuoteStatus,
  blob?: Blob,
  fileName?: string
) {
  // if status === 'accepted' then upload the blob along with the status and filename, otherwise just update the status
  if (status === "accepted" && blob && fileName) {
    const formData = new FormData();
    formData.append("status", status);
    formData.append("file", blob, fileName);

    return request({
      url: urls.updateStatusAccept.replace(":QUOTE_ID", `${quoteId}`),
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  return request({
    url: urls.updateStatusReject.replace(":QUOTE_ID", `${quoteId}`),
    method: "POST",
    data: { status },
  });
}

export function sendCoCEmail(
  quoteId: Quote["id"],
  blob: Blob,
  fileName: string
) {
  const formData = new FormData();
  formData.append("file", blob, fileName);

  return request({
    url: urls.sendCoCEmail.replace(":QUOTE_ID", `${quoteId}`),
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
