import * as React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import QuestionMarkIcon from "@mui/icons-material/Help";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Brokerage } from "../../lib/types";
import { mapQuoteTypeName, mapQuoteFeatureName } from "../../lib/helpers";
import Typography from "@mui/material/Typography";

interface Column {
  id: string;
  label: string;
  helpText?: React.ReactNode;
}

interface Props {
  loading: boolean;
  brokerages: Brokerage[];
  onClickAdd: () => void;
  onClickEdit: (brokerageId: Brokerage["id"]) => void;
  onClickEditLogo: (brokerageId: Brokerage["id"]) => void;
  onClickEditQuoteTypeAccess: (brokerageId: Brokerage["id"]) => void;
  onClickDeleteLogo: (brokerageId: Brokerage["id"]) => void;
}

export default function BrokeragesTable({
  loading,
  brokerages,
  onClickAdd,
  onClickEdit,
  onClickEditLogo,
  onClickEditQuoteTypeAccess,
  onClickDeleteLogo,
}: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickEdit = (brokerageId: Brokerage["id"]) => () => {
    onClickEdit(brokerageId);
  };

  const handleClickEditLogo = (brokerageId: Brokerage["id"]) => () => {
    onClickEditLogo(brokerageId);
  };

  const handleClickEditQuoteTypeAccess =
    (brokerageId: Brokerage["id"]) => () => {
      onClickEditQuoteTypeAccess(brokerageId);
    };

  const handleClickRemoveLogo = (brokerageId: Brokerage["id"]) => () => {
    if (
      window.confirm(
        "Are you sure you want to delete the logo for this brokerage?"
      )
    ) {
      onClickDeleteLogo(brokerageId);
    }
  };

  const columns: readonly Column[] = [
    { id: "name", label: "Brokerage" },
    { id: "quote_types", label: "Quote Type Access" },
    { id: "quote_features", label: "Quote Features" },
    {
      id: "logo",
      label: "Logo",
      helpText:
        "If no logo is set for the brokerage, the quotes will show no logo",
    },
  ];

  return (
    <div>
      <Box mb={2}>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button
            color="info"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClickAdd}
          >
            New Brokerage
          </Button>
        </Stack>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" fontWeight="bold">
                        {column.label}
                      </Typography>
                      {column.helpText && (
                        <Tooltip title={column.helpText} placement="top">
                          <QuestionMarkIcon
                            fontSize="small"
                            color="action"
                            sx={{
                              "&:hover": {
                                cursor: "help",
                              },
                            }}
                          />
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {brokerages
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((brokerage) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={brokerage.id}
                    >
                      <TableCell>
                        <Tooltip
                          title={`Edit ${brokerage.name}`}
                          placement="top"
                        >
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleClickEdit(brokerage.id)}
                            underline="hover"
                          >
                            {brokerage.name}
                          </Link>
                        </Tooltip>
                        {brokerage.email && (
                          <Typography variant="body2" sx={{ mt: 0.5 }}>
                            <Typography variant="caption">E: </Typography>
                            <Link
                              href={`mailto:${brokerage.email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              underline="hover"
                              color="inherit"
                            >
                              {brokerage.email}
                            </Link>
                          </Typography>
                        )}
                        {brokerage.phone && (
                          <Typography variant="body2">
                            <Typography variant="caption">P: </Typography>
                            <Link
                              href={`phone:${brokerage.phone}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              underline="hover"
                              color="inherit"
                            >
                              {brokerage.phone}
                            </Link>
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title="Edit Quote Type / Feature Access"
                          placement="top"
                        >
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleClickEditQuoteTypeAccess(
                              brokerage.id
                            )}
                            underline="hover"
                          >
                            {brokerage.quote_types_access.length > 0 &&
                              brokerage.quote_types_access.map((qt) => (
                                <Typography key={qt.id} variant="body2">
                                  {mapQuoteTypeName(qt.name)}
                                </Typography>
                              ))}
                            {brokerage.quote_types_access.length === 0 && (
                              <Typography variant="body2">
                                No types enabled
                              </Typography>
                            )}
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title="Edit Quote Type / Feature Access"
                          placement="top"
                        >
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleClickEditQuoteTypeAccess(
                              brokerage.id
                            )}
                            underline="hover"
                          >
                            {brokerage.quote_features_access.length > 0 &&
                              brokerage.quote_features_access.map((qf) => (
                                <Typography key={qf.id} variant="body2">
                                  {mapQuoteFeatureName(qf.feature_name)}
                                </Typography>
                              ))}
                            {brokerage.quote_features_access.length === 0 && (
                              <Typography variant="body2">
                                No features enabled
                              </Typography>
                            )}
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {brokerage.logo ? (
                          <div style={{ position: "relative" }}>
                            <Tooltip
                              title={`Delete the logo for ${brokerage.name}?`}
                              placement="top"
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: -20,
                                  left: -20,
                                }}
                              >
                                <IconButton
                                  onClick={handleClickRemoveLogo(brokerage.id)}
                                  color="error"
                                  aria-label="delete"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </Tooltip>
                            <img
                              src={brokerage.logo}
                              width="120"
                              alt={`${brokerage.name}`}
                            />
                          </div>
                        ) : (
                          <Typography variant="body2">
                            <Link
                              component="button"
                              onClick={handleClickEditLogo(brokerage.id)}
                            >
                              Add a logo
                            </Link>
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ position: "relative" }}>
          {loading && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              sx={{
                position: "absolute",
                left: 0,
                height: "100%",
                padding: "0 1rem",
              }}
            >
              <Typography sx={{ fontStyle: "italic" }} variant="body2">
                Loading ...
              </Typography>
              <CircularProgress color="info" size={18} />
            </Stack>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={brokerages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
}
