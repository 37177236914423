import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    paddingTop: 40,
    paddingBottom: 40,
    paddingRight: 30,
    paddingLeft: 30,
    position: "relative",
  },
  pageFooter: {
    position: "absolute",
    bottom: 20,
    right: 0,
    left: 0,
    borderTopWidth: 0.7,
    borderColor: "#a6a6a6",
    marginRight: 30,
    marginLeft: 30,
  },
  pageFooterText: {
    color: "#a6a6a6",
    fontSize: 10,
  },
  section: {
    borderWidth: 1,
    borderColor: "green",
  },
  headerImageContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 80,
    marginBottom: 20,
  },
  headerText: {
    fontSize: 10,
  },
  sectionHeadingContainer: {
    borderRadius: 3,
    backgroundColor: "#00456d",
    padding: 3,
  },
  sectionPadding: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  subSectionHeadingContainer: {
    padding: 3,
  },
  subSectionHeaderLine: {
    height: 1,
    borderBottomWidth: 0.5,
    borderColor: "#002354",
  },
  dottedLine: {
    flexGrow: 1,
    height: 1,
    borderBottomWidth: 0.7,
    borderBottomColor: "#A7A7A7",
    borderStyle: "dotted",
  },
  image: {
    width: 101,
    height: 58,
    // width: 105,
    // height: 60
  },
  itemHeading: {
    fontFamily: "Helvetica",
    fontSize: 10,
    textTransform: "uppercase",
    marginBottom: 3,
  },
  textNote: {
    fontFamily: "Helvetica",
    fontSize: 10,
    lineHeight: 1.3,
  },
  textSmall: {
    fontFamily: "Helvetica",
    fontSize: 9.5,
    lineHeight: 1.2,
  },
  textSmallBold: {
    fontFamily: "Helvetica-Bold",
    fontSize: 9.5,
    lineHeight: 1.2,
  },
  textInfo: {
    fontFamily: "Helvetica-Oblique",
    fontSize: 9,
    lineHeight: 1.2,
  },
  textNormal: {
    fontFamily: "Helvetica",
    fontSize: 12,
    lineHeight: 1.3,
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    lineHeight: 1.3,
  },
  textWhite: {
    color: "#ffffff",
  },
  textBlue: {
    color: "#002354",
  },
  textDarkGrey: {
    color: "#6F6F6F",
  },
  paddingTop8: {
    paddingTop: 8,
  },
  paddingBottom8: {
    paddingBottom: 8,
  },
  padding8: {
    padding: 8,
  },
  padding10: {
    padding: 10,
  },
  marginBottom8: {
    marginBottom: 8,
  },
  marginTop14: {
    marginTop: 14,
  },
  marginBottom12: {
    marginBottom: 12,
  },
  marginBottom14: {
    marginBottom: 14,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottom30: {
    marginBottom: 30,
  },
  marginBottom80: {
    marginBottom: 80,
  },
  flex1: {
    flex: 1,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifySpaceBetween: {
    justifyContent: "space-between",
  },
  alignEnd: {
    alignItems: "flex-end",
  },
  alignCenter: {
    alignItems: "center",
  },
  width30: {
    width: "30%",
  },
  width50: {
    width: "50%",
  },
  thText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  trBorderBottom: {
    borderBottomWidth: 0.7,
    borderColor: "#E0E0E0",
  },
  tdText: {
    fontFamily: "Helvetica",
    fontSize: 11,
  },
  borderBottom: {
    borderBottomWidth: 0.7,
    borderColor: "#E0E0E0",
  },
  textAlignRight: {
    textAlign: "right",
  },
  containerWithBorder: {
    borderColor: "#2a2a2a",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingVertical: 3,
  },
});
