import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { green, red } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import CheckIcon from "@mui/icons-material/Check";
import type { QuoteStatus } from "../../../lib/types";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import SelectedIcon from "@mui/icons-material/Check";
import AcceptIcon from "@mui/icons-material/CheckBox";
import RejectIcon from "@mui/icons-material/Cancel";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

interface Props {
  open: boolean;
  pdfLoading: boolean;
  hasCoCFeature: boolean;
  onClose: () => void;
  onSubmitStatus: ({ status }: { status: QuoteStatus }) => Promise<boolean>;
  onSubmitCoCEmail: ({ status }: { status: QuoteStatus }) => Promise<boolean>;
  onComplete: () => void;
}

function StatusFormDialog({
  open,
  pdfLoading,
  hasCoCFeature,
  onClose,
  onSubmitStatus,
  onSubmitCoCEmail,
  onComplete,
}: Props) {
  const [status, setStatus] = useState<QuoteStatus>("pending");
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isStatusUpdated, setIsStatusUpdated] = useState<null | Boolean>(null);
  const [isLoadingCoC, setIsLoadingCoC] = useState(false);
  const [isCoCEmailSent, setIsCoCEmailSent] = useState<null | Boolean>(null);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (open) {
      setStatus("pending");
      setIsLoadingStatus(false);
      setIsStatusUpdated(null);
      setIsLoadingCoC(false);
      setIsCoCEmailSent(null);
      setComplete(false);
    }
  }, [open]);

  const handleSubmit = async () => {
    setIsLoadingStatus(true);

    let res: boolean | undefined = undefined;
    let res1: boolean | undefined = undefined;

    res = await onSubmitStatus({ status });
    setIsStatusUpdated(res);

    setIsLoadingStatus(false);

    if (hasCoCFeature) {
      setIsLoadingCoC(true);
      res1 = await onSubmitCoCEmail({ status });
      setIsCoCEmailSent(res1);
      setIsLoadingCoC(false);
    }

    if ((res && !hasCoCFeature) || (res && res1 && hasCoCFeature)) {
      onComplete();
      setComplete(true);
    }
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    status: QuoteStatus
  ) => {
    setStatus(status);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Accept / Reject Quote</DialogTitle>
      <DialogContent>
        <Box>
          <List component="nav" aria-label="main mailbox folders">
            <ListItemButton
              disabled={isLoadingStatus || isLoadingCoC || complete}
              selected={status === "accepted"}
              onClick={(event) => handleListItemClick(event, "accepted")}
            >
              <ListItemIcon>
                <AcceptIcon sx={{ color: green[600] }} />
              </ListItemIcon>
              <ListItemText primary="Accept Quote" sx={{ color: green[600] }} />
              {status === "accepted" && (
                <ListItemIcon>
                  <SelectedIcon color="primary" />
                </ListItemIcon>
              )}
            </ListItemButton>
            <Stack direction="column" spacing={0.4} sx={{ ml: 9 }}>
              <Typography variant="body2" color="GrayText">
                Accepting the quote will also:
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2" color="GrayText">
                  • Send the Quote accepted email
                </Typography>
                {isLoadingStatus && <CircularProgress size={10} />}
                {isStatusUpdated === true && status === "accepted" && (
                  <CheckIcon sx={{ fontSize: 16 }} htmlColor="green" />
                )}
              </Stack>
              {hasCoCFeature && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" color="GrayText">
                    • Send the Certificate of Currency email
                  </Typography>
                  {isLoadingCoC && <CircularProgress size={10} />}
                  {isCoCEmailSent && status === "accepted" && (
                    <CheckIcon sx={{ fontSize: 16 }} htmlColor="green" />
                  )}
                </Stack>
              )}
            </Stack>
            <ListItemButton
              disabled={isLoadingStatus || isLoadingCoC || complete}
              selected={status === "rejected"}
              onClick={(event) => handleListItemClick(event, "rejected")}
            >
              <ListItemIcon>
                <RejectIcon sx={{ color: red[300] }} />
              </ListItemIcon>
              <ListItemText primary="Reject Quote" sx={{ color: red[300] }} />
              {status === "rejected" && (
                <ListItemIcon>
                  <SelectedIcon color="primary" />
                </ListItemIcon>
              )}
            </ListItemButton>
          </List>
          <Divider sx={{ mt: 0, mb: 3 }} />
        </Box>
        <Box mt={2}>
          <Typography variant="body2" color="GrayText">
            Note: The quote can no longer be edited once it is accepted or
            rejected.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {!complete && <Button onClick={onClose}>Cancel</Button>}
        <LoadingButton
          loading={isLoadingStatus || isLoadingCoC || pdfLoading}
          disabled={status === "pending" || pdfLoading}
          loadingPosition="end"
          endIcon={complete ? <DoneIcon /> : <SaveIcon />}
          variant="contained"
          onClick={complete ? onClose : handleSubmit}
        >
          {complete ? "Close" : "Save Status"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default StatusFormDialog;
