import { PDFDownloadLink } from "@react-pdf/renderer";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import { useAppSelector } from "../../../hooks";
import {
  selectClientQuote,
  selectHasQuoteChanges,
} from "../../../lib/store/features/quotes.slice";
import PDFQuoteDocument from "./PDFQuoteDocument";
import "./styles.css";
import { usePdfQuoteDetails } from "../../../hooks/usePdfQuoteDetails";

interface DisabledProps {
  disabled: boolean;
}

const PDFLink = ({ disabled }: DisabledProps) => {
  const { quoteDetails, agentDetails, clientDetails } = usePdfQuoteDetails();

  return (
    <PDFDownloadLink
      document={
        <PDFQuoteDocument
          quoteDetails={quoteDetails}
          agentDetails={agentDetails}
          clientDetails={clientDetails}
        />
      }
      fileName={quoteDetails.fileName}
    >
      {({ blob, url, loading, error }) => (
        <Button
          variant="contained"
          color={loading ? "warning" : "success"}
          startIcon={<PDFIcon />}
          component="span"
          fullWidth
          disabled={disabled}
          style={{ textDecoration: "none !important" }}
        >
          {loading ? "..." : "Quote"}
        </Button>
      )}
    </PDFDownloadLink>
  );
};

function PDFLinkButton({ disabled }: DisabledProps) {
  const clientQuote = useAppSelector(selectClientQuote);
  const hasChanges = useAppSelector(selectHasQuoteChanges);
  const canGenerateQuote = !!clientQuote.id && !hasChanges;

  function handleFakeClick() {
    console.log(
      "This is a fake button that fouces the PDFLink button to re-render when a chnage is made to the quote."
    );
  }

  return (
    <Tooltip
      title={canGenerateQuote ? "Generate the Quote PDF" : null}
      placement="top"
    >
      <span>
        {!canGenerateQuote && (
          <Button
            variant="contained"
            color="success"
            startIcon={<PDFIcon />}
            disabled={true}
            onClick={handleFakeClick}
            fullWidth
          >
            Quote
          </Button>
        )}
        {canGenerateQuote && <PDFLink disabled={disabled} />}
      </span>
    </Tooltip>
  );
}

export default PDFLinkButton;
