import { useEffect } from "react";
import { getOptionalExtras } from "../../lib/store/features/quoteData.slice";
import { useAppDispatch } from "../../hooks";

function InitQuoteDataOptionalExtras() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const init = async () => {
      await dispatch(getOptionalExtras());
    };
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default InitQuoteDataOptionalExtras;
