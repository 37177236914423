import { useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import { validationSchema } from "./validationSchema";

interface Props {
  disabled: boolean;
  onSubmit: ({
    from_address,
    to_address,
  }: {
    from_address: string;
    to_address: string;
  }) => Promise<boolean>;
}

export default function EmailTestForm({ disabled, onSubmit }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const fields: { [key: string]: string } = {
    from_address: "",
    to_address: "",
  };

  const inputProps: { [key: string]: any } = {
    from_address: {
      label: "From Email Address",
      placeholder: 'Enter the "from" email address',
      type: "text",
      Component: TextField,
    },
    to_address: {
      label: "To Email Address",
      placeholder: 'Enter the "to" email address',
      type: "text",
      Component: TextField,
    },
  };

  return (
    <Formik
      initialValues={{ ...fields }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setIsLoading(true);
        await onSubmit(values as any);
        setSubmitting(false);
        setIsLoading(false);
      }}
    >
      <Form noValidate>
        <Typography variant="h6" component="h1">
          Test Email Server Settings
        </Typography>

        <Typography variant="body1" component="span" color="GrayText">
          Enter a "From" email address and a "To" email address to test the
          email server settings.
        </Typography>
        <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
          {Object.keys(fields).map((fieldName) => {
            const { Component, type, name, grid, evalProps, ...props } =
              inputProps[fieldName];

            return (
              <Grid item xs={12} md={6} key={fieldName} {...grid}>
                <Field type={type} name={fieldName}>
                  {({ field, meta, form }: FieldProps) => {
                    return (
                      <Component
                        {...field}
                        {...props}
                        {...(evalProps ? evalProps({ field, meta, form }) : {})}
                        type={type}
                        disabled={isLoading || form.isSubmitting || disabled}
                        required
                        fullWidth
                        error={!!meta.error}
                        helperText={!!meta.error ? meta.error : null}
                      />
                    );
                  }}
                </Field>
              </Grid>
            );
          })}
        </Grid>
        <Stack direction="row">
          <LoadingButton
            loading={isLoading}
            disabled={disabled}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            type="submit"
          >
            Send Test Email
          </LoadingButton>
        </Stack>
      </Form>
    </Formik>
  );
}
