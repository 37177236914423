import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import SaveIcon from "@mui/icons-material/Save";
import type { TextFieldProps } from "@mui/material/TextField";
import Section from "../forms/Section";
import TextInput from "../forms/TextInput";
import { OtherSettingsFormValues } from "../../lib/types";
import format from "date-fns/format";
import headerColor from "@mui/material/colors/indigo";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import type { OtherSettings as OtherSettingsType } from "../../lib/types";

interface QuoteDataState {
  formValues: OtherSettingsFormValues;
}

interface Props {
  settings: OtherSettingsType["other_settings"];
  onSave: (values: OtherSettingsFormValues) => void;
  loading: boolean;
  saving: boolean;
  lastModified: Date | null; // haven't implemented last modified in the DB yet
}

function OtherSettingsForm({
  onSave,
  settings,
  lastModified,
  loading,
  saving,
}: Props) {
  const [values, setValues] = useState<QuoteDataState>({
    formValues: {
      stamp_duty: settings.stamp_duty,
    },
  });

  useEffect(() => {
    setValues((vals) => ({
      ...vals,
      formValues: {
        ...vals.formValues,
        stamp_duty: settings.stamp_duty,
      },
    }));
  }, [settings]);

  const handleSaveData = () => {
    onSave(values.formValues);
  };

  const handleInputChange =
    (field: keyof typeof values.formValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValues((vals) => ({
        ...vals,
        formValues: {
          ...vals.formValues,
          [field]: parseFloat(value),
        },
      }));
    };

  const { stamp_duty } = values.formValues;

  const defaultTextFieldProps = {
    autoComplete: "off",
    variant: "outlined" as TextFieldProps["variant"],
    type: "number",
    sx: {
      width: "100%",
    },
  };

  const fields = {
    stampDuty: {
      ...defaultTextFieldProps,
      label: "Stamp Duty (Per Policy)",
      required: true,
      value: stamp_duty,
      onChange: handleInputChange("stamp_duty"),
    },
  };

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: 120,
            backgroundImage: `linear-gradient(to top, rgba(255,0,0,0), ${headerColor[100]})`,
          }}
        />
        {lastModified && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 20,
            }}
          >
            <Typography
              component="span"
              variant="body2"
              style={{
                fontSize: "0.65em",
                display: "inline-block",
                marginTop: "0.3em",
                fontFamily: "monospace",
              }}
            >
              Last Modified: {format(lastModified, "do LLL yyyy @ h:mmaaa")}
            </Typography>
          </div>
        )}

        <Grid container spacing={2} zIndex={10000}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box style={{ flexGrow: 1 }}>
                <Typography component="div">
                  <Box
                    textAlign="center"
                    m={1}
                    style={{
                      fontSize: "1.3em",
                      color: "#000000",
                      // backgroundColor: color,
                    }}
                  >
                    Other Settings
                  </Box>
                </Typography>
              </Box>
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <LoadingButton
                  loadingPosition="end"
                  variant="contained"
                  size="large"
                  endIcon={<SaveIcon />}
                  onClick={handleSaveData}
                  loading={saving}
                  disabled={loading}
                >
                  Save Settings
                </LoadingButton>
              </Box>
            </Box>
          </Grid>

          {loading && (
            <Grid item xs={12}>
              <Box
                my={2}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Box mr={2}>
                  <Typography component="p" variant="body1">
                    <em>Loading data</em>
                  </Typography>
                </Box>
                <CircularProgress size={24} />
              </Box>
            </Grid>
          )}

          {!loading && (
            <Grid item xs={12}>
              <form
                id="othersettingsform"
                style={{ width: "100%" }}
                noValidate
                autoComplete="off"
              >
                <Section subsection title="Default Fees">
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextInput {...fields.stampDuty} />
                    </Grid>
                  </Grid>
                </Section>
              </form>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}

export default OtherSettingsForm;
