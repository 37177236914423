import * as Yup from "yup";

export const validationSchema = Yup.object({
  email: Yup.string()
    .max(100, "Maximum characters reached")
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Password is required"),
});
