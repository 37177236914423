import { useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SaveIcon from "@mui/icons-material/Save";
import DialogTitle from "@mui/material/DialogTitle";
import Dropzone from "../../containers/Dropzone";
import { Brokerage } from "../../lib/types";

interface Props {
  open: boolean;
  name: Brokerage["name"];
  logo?: Brokerage["logo"];
  onClose: () => void;
  onSubmit: (file: File) => Promise<boolean>;
}

export default function BrokerageLogoDialog({
  open,
  name,
  logo,
  onClose,
  onSubmit,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const handleChanges = (files: File[]) => {
    setFiles(files);
  };

  const handleSubmit = async () => {
    if (!files[0]) {
      window.alert("No file selected");
      return;
    }

    setIsLoading(true);

    const success = await onSubmit(files[0]);
    if (success) {
      onClose();
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { height: 420 } }}
    >
      <DialogTitle>Logo - {name}</DialogTitle>
      <DialogContent>
        <Dropzone
          title="Drag 'n' drop an image file here, or click to select an image"
          subtitle="File type *.jpg or *.png; Maximum file size 150kb; Image width: 200px; Image height: 100px"
          maxFiles={1}
          maxSize={150000}
          accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
          onChange={handleChanges}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={isLoading}
          disabled={files.length === 0}
          loadingPosition="end"
          endIcon={<SaveIcon />}
          variant="contained"
          type="button"
        >
          Save Logo
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
