import { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import blue from "@mui/material/colors/blue";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import NextIcon from "@mui/icons-material/ChevronRight";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import MuiListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import type { QuoteType } from "../../lib/types";

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>(() => ({
  "&.Mui-selected": {
    backgroundColor: alpha(blue[600], 0.2),
  },
}));

interface Props {
  open: boolean;
  products: { title: string; subtitle: string; quoteType: QuoteType }[];
  onClose: () => void;
  onSubmit: (quoteType: QuoteType) => Promise<boolean>;
}

export default function QuoteSelectProductDialog({
  open,
  products,
  onClose,
  onSubmit,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(products[selectedIndex].quoteType);
    setIsLoading(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Select a Product</DialogTitle>
      <DialogContent>
        <List component="nav">
          {products.map((product, index) => (
            <ListItemButton
              key={product.title}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemText
                primary={product.title}
                secondary={product.subtitle}
                sx={{ textTransform: "capitalize" }}
              />
            </ListItemButton>
          ))}
          {products.length === 0 && (
            <ListItemButton disabled>
              <ListItemText
                primary="No products available"
                secondary="Please contact your admin for more information"
              />
            </ListItemButton>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={isLoading}
          disabled={products.length === 0}
          loadingPosition="end"
          endIcon={<NextIcon />}
          variant="contained"
          onClick={handleSubmit}
        >
          Next
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
