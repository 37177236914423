import BrokerageDialog from "../../../../components/BrokerageDialog";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../../../lib/store/features/dialogs.slice";
import { addBrokerage } from "../../../../lib/store/features/brokerages.slice";
import { enqueue as showNotif } from "../../../../lib/store/features/notifications.slice";
import { Brokerage } from "../../../../lib/types";

function AddBrokerage() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("ADD_BROKERAGE"));

  const handleClose = () => {
    dispatch(toggle({ dialog: "ADD_BROKERAGE", open: false }));
  };

  const handleSubmit = async (brokerage: Brokerage) => {
    const result = await dispatch(
      addBrokerage({
        name: brokerage.name,
        email: brokerage.email ? brokerage.email : undefined,
        phone: brokerage.phone ? brokerage.phone : undefined,
        address_line_one: brokerage.address_line_one
          ? brokerage.address_line_one
          : undefined,
        address_line_two: brokerage.address_line_two
          ? brokerage.address_line_two
          : undefined,
        quote_types_access: [],
      })
    );

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "Brokerage successfully created",
        options: { variant: "success" },
      })
    );

    handleClose();

    return true;
  };

  return (
    <BrokerageDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
}

export default AddBrokerage;
