import { QuoteFeature, QuoteType } from "../types";

export function mapQuoteTypeName(quote_type: QuoteType) {
  switch (quote_type) {
    case "business":
      return "Business Vehicle";
    case "commercial":
      return "Commercial";
    case "pmv":
      return "PMV";
    case "private":
      return "Private Motor Vehicle";
    case "taxihirecar":
      return "Taxi / Hire Car";
    case "workers_compensation":
      return "Workers Compensation";
    case "public_liability":
      return "Public Liability";
    default:
      return quote_type;
  }
}

export function mapQuoteFeatureName(quote_feature: QuoteFeature) {
  switch (quote_feature) {
    case "certificate_of_currency":
      return "Certificate of Currency";
    default:
      return quote_feature;
  }
}
