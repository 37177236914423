import * as React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ResetPasswordIcon from "@mui/icons-material/LockReset";
// import DeleteIcon from "@mui/icons-material/Delete";
import QuestionMarkIcon from "@mui/icons-material/Help";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Role from "../Role";
import { Brokerage, User } from "../../lib/types";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface UserColumn {
  id: string;
  label: string;
  helpText?: React.ReactNode;
}

interface Props {
  loading: boolean;
  myId?: number;
  users: User[];
  brokerages: { [key: Brokerage["id"]]: Brokerage };
  onClickToggleActive: (userId: User["id"], setTo: boolean) => void;
  onClickAdd: () => void;
  onClickEdit: (userId: User["id"]) => void;
  onClickRemove: (userId: User["id"]) => void;
  onResetPassword: (userId: User["id"]) => void;
}

export default function UsersTable({
  loading,
  myId,
  users,
  brokerages,
  onClickToggleActive,
  onClickAdd,
  onClickEdit,
  onClickRemove,
  onResetPassword,
}: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleToggleActive =
    (userId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      onClickToggleActive(userId, checked as boolean);
    };

  const handleClickEdit = (userId: number) => () => {
    onClickEdit(userId);
  };

  // const handleClickRemove = (userId: number) => () => {
  //   if (
  //     window.confirm(
  //       "Are you sure you want to delete this user? This will also delete all quotes created by this user."
  //     )
  //   ) {
  //     onClickRemove(userId);
  //   }
  // };

  const handleClickResetUserPassword = (userId: number) => () => {
    onResetPassword(userId);
  };

  const columns: readonly UserColumn[] = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    {
      id: "role",
      label: "Role",
      helpText: (
        <List>
          <ListItem>
            <ListItemText
              primary="Agent"
              secondary="Can create and search own quotes"
              secondaryTypographyProps={{ color: "white" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Admin"
              secondary="Can create and search quotes from Brokerage"
              secondaryTypographyProps={{ color: "white" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Superadmin"
              secondary="Has access to everything"
              secondaryTypographyProps={{ color: "white" }}
            />
          </ListItem>
        </List>
      ),
    },
    { id: "isActive", label: "Status" },
    { id: "changePassword", label: "Change Password" },
    // { id: "remove", label: "Delete" },
  ];

  return (
    <div>
      <Box mb={2}>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button
            color="info"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClickAdd}
          >
            New User
          </Button>
        </Stack>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2" fontWeight="bold">
                        {column.label}
                      </Typography>
                      {column.helpText && (
                        <Tooltip title={column.helpText} placement="top">
                          <QuestionMarkIcon
                            fontSize="small"
                            color="action"
                            sx={{
                              "&:hover": {
                                cursor: "help",
                              },
                            }}
                          />
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => {
                  const isCurrentUser = myId === user.id;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                      <TableCell>
                        <Tooltip title={`Edit ${user.name}`} placement="top">
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleClickEdit(user.id)}
                            underline="hover"
                          >
                            {user.name}
                          </Link>
                        </Tooltip>
                        {user.brokerage_id && brokerages[user.brokerage_id] && (
                          <Typography
                            variant="caption"
                            component="div"
                            sx={{ mt: 0.5, fontWeight: 500 }}
                          >
                            {brokerages[user.brokerage_id].name}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Link
                          href={`mailto:${user.email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                          color="inherit"
                        >
                          {user.email}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Role role={user.role} />
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            isCurrentUser
                              ? "Cannot toggle your own status"
                              : `Toggle status of ${user.name}. If disabled, ${user.name} will not be able to log in.`
                          }
                          placement="top"
                        >
                          <span>
                            <Switch
                              inputProps={{
                                "aria-label": `toggle ${user.name}`,
                              }}
                              checked={user.isActive}
                              onChange={handleToggleActive(user.id)}
                              disabled={isCurrentUser}
                            />
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            isCurrentUser
                              ? "Go to your profile to change your password"
                              : `Change the password for ${user.name}`
                          }
                          placement="top"
                        >
                          <span>
                            <IconButton
                              disabled={loading || isCurrentUser}
                              onClick={handleClickResetUserPassword(user.id)}
                            >
                              <ResetPasswordIcon
                                color={
                                  loading || isCurrentUser
                                    ? "disabled"
                                    : "primary"
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                      {/* <TableCell>
                        <Tooltip title={`Edit ${user.name}`} placement="top">
                          <span>
                            <IconButton
                              aria-label={`edit ${user.name}`}
                              onClick={handleClickEdit(user.id)}
                              disabled={disableInput}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell> */}
                      {/* Deleting a user will also delete the quotes the user created. Deleting users is disabled for now. */}
                      {/* <TableCell>
                        <Tooltip
                          title={`Deleting ${user.name} AND the quotes this user created.`}
                          placement="top"
                        >
                          <span>
                            <IconButton
                              aria-label={`delete ${user.name}`}
                              onClick={handleClickRemove(user.id)}
                              disabled={disableInput || true}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ position: "relative" }}>
          {loading && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              sx={{
                position: "absolute",
                left: 0,
                height: "100%",
                padding: "0 1rem",
              }}
            >
              <Typography sx={{ fontStyle: "italic" }} variant="body2">
                Loading ...
              </Typography>
              <CircularProgress color="info" size={18} />
            </Stack>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
}
