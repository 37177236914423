import { AxiosError } from "axios";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { sort } from "fast-sort";
import type {
  RootState,
  User as UserType,
  API as ApiType,
  ApiUsersFetchListResponse,
  ApiUsersUpdateResponse,
  ApiUsersAddResponse,
  ApiUsersRemoveResponse,
  Role,
  Brokerage,
  User,
} from "../../types";
import * as usersApi from "../../api/handlers/users";
import { handleError } from "../../helpers";

interface UserState {
  list: UserType[];
  selectedId: null | UserType["id"];
  api: ApiType;
}

const initialState: UserState = {
  list: [],
  selectedId: null,
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const fetchList = createAsyncThunk(
  "users/fetchList",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { api } = (getState() as RootState).users;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await usersApi.fetchList()) as any;
      return response.data as ApiUsersFetchListResponse;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const toggleStatus = createAsyncThunk(
  "users/toggleStatus",
  async (
    { userId, isActive }: { userId: number; isActive: boolean },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).users;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await usersApi.toggleUserStatus({
        userId,
        isActive,
      })) as any;
      return response.data as ApiUsersUpdateResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const addUser = createAsyncThunk(
  "users/addUser",
  async (
    {
      name,
      email,
      phone,
      password,
      role,
      isActive,
      brokerage_id,
    }: {
      name: string;
      email: string;
      phone: string;
      password: string;
      isActive: boolean;
      role: Role;
      brokerage_id: null | Brokerage["id"];
    },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).users;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await usersApi.addUser({
        name,
        email,
        phone,
        password,
        role,
        isActive,
        brokerage_id,
      })) as any;

      return response.data as ApiUsersAddResponse;
    } catch (error: any) {
      if (
        (error as AxiosError).isAxiosError &&
        (error as AxiosError).response?.status === 409
      ) {
        return rejectWithValue("Email address already exists");
      }
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (
    {
      name,
      email,
      phone,
      role,
      brokerage_id,
      userId,
    }: {
      name: string;
      email: string;
      phone: string;
      role: Role;
      brokerage_id: null | Brokerage["id"];
      userId: User["id"];
    },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).users;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await usersApi.updateUser({
        name,
        email,
        phone,
        role,
        brokerage_id,
        userId,
      })) as any;

      return response.data as ApiUsersUpdateResponse;
    } catch (error: any) {
      if (
        (error as AxiosError).isAxiosError &&
        (error as AxiosError).response?.status === 409
      ) {
        return rejectWithValue("Email address already exists");
      }
      return handleError(rejectWithValue)(error);
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "users/changeUserPassword",
  async (
    {
      newPassword,
      userId,
    }: {
      newPassword: string;
      userId: User["id"];
    },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).users;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await usersApi.changeUserPassword({
        newPassword,
        userId,
      })) as any;

      return response.data as ApiUsersUpdateResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const deleteById = createAsyncThunk(
  "users/deleteById",
  async (
    { userId }: { userId: number },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).users;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await usersApi.deleteUser({
        userId,
      })) as any;

      return response.data as ApiUsersRemoveResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSelectedUserId: (state, action: PayloadAction<User["id"] | null>) => {
      state.selectedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* Change User Password */
    builder.addCase(changeUserPassword.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(changeUserPassword.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;
    });
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Fetch Users List */
    builder.addCase(fetchList.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(fetchList.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.list = payload.users.map(({ brokerage, ...user }) => ({
          ...user,
          isActive: !!user.isActive,
          password: "",
          brokerage,
        }));
      }
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Toggle User Status */
    builder.addCase(toggleStatus.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(toggleStatus.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload?.updated) {
        state.list = state.list.map((user) => {
          if (meta.arg.userId === user.id) {
            return { ...user, isActive: meta.arg.isActive };
          }
          return { ...user };
        });
      }
    });
    builder.addCase(toggleStatus.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Add User */
    builder.addCase(addUser.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(addUser.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.list = sort([
          ...state.list,
          {
            ...meta.arg,
            id: parseInt(payload.user_id, 10),
            brokerage: null,
          },
        ]).by([
          {
            asc: (u: UserType) => u.brokerage?.name,
          },
          {
            asc: (u: UserType) => u.role,
          },
          {
            asc: (u: UserType) => u.name,
          },
        ]);
      }
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Update User */
    builder.addCase(updateUser.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(updateUser.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.list = sort(
          state.list.map((user) => {
            if (meta.arg.userId === user.id) {
              return {
                ...user,
                ...meta.arg,
              };
            }
            return { ...user };
          })
        ).by([
          {
            asc: (u: UserType) => u.brokerage?.name,
          },
          {
            asc: (u: UserType) => u.role,
          },
          {
            asc: (u: UserType) => u.name,
          },
        ]);

        // .asc([(u: UserType) => u.role, (u: UserType) => u.name]);
      }
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Delete User */
    builder.addCase(deleteById.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(deleteById.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload.deleted) {
        state.list = state.list.filter((u) => u.id !== meta.arg.userId);
      }
    });
    builder.addCase(deleteById.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });
  },
});

// Actions
export const { setSelectedUserId } = usersSlice.actions;

// Selectors
const selector = (state: RootState) => state.users;
export const selectedUser = createSelector(selector, (users) => {
  return users.list.find((u) => u.id === users.selectedId);
});
export const selectList = createSelector(selector, (users) => users.list);
export const loadingList = createSelector(
  selector,
  (users) => users.api.loading === "pending"
);

export default usersSlice.reducer;
