import type { QuoteType } from "../types";

export const STATUS_COLORS = {
  pending: "#dce775",
  accepted: "#80cbc4",
  rejected: "#ff8a80",
  expired: "#b0bec5",
};

export const VALID_QUOTE_TYPES: QuoteType[] = [
  "business",
  "commercial",
  "pmv",
  "private",
  "taxihirecar",
];

export const QUOTE_DATA_INFO = {
  business: {
    title: "Business Vehicle Quote Data",
    color: "rgba(128, 203, 196, 0.45)",
  },
  commercial: {
    title: "Commercial Quote Data",
    color: "rgba(255, 224, 178, 0.45)",
  },
  pmv: { title: "PMV Quote Data", color: "rgba(220, 231, 117, 0.45)" },
  private: { title: "Private Quote Data", color: "rgba(100, 181, 246, 0.45)" },
  taxihirecar: {
    title: "Taxi / Hire Car Quote Data",
    color: "rgba(176, 190, 197, 0.45)",
  },
  workers_compensation: {
    title: "Workers Compensation Quote Data",
    color: "rgba(197, 176, 197, 0.45)",
  },
  public_liability: {
    title: "Public Liability Quote Data",
    color: "rgba(202, 204, 57, 0.45)",
  },
};

export const QUOTE_INFO = {
  business: {
    title: "Business Vehicle",
    subTitle: "Business Motor Vehicle Policy",
    quoteTitle: "Business Motor Vehicle",
    color: "rgba(128, 203, 196, 0.45)",
  },
  commercial: {
    title: "Commercial",
    subTitle: "Heavy & Light Motor Vehicle Policy",
    quoteTitle: "Commercial Motor Vehicle",
    color: "rgba(255, 224, 178, 0.45)",
  },
  pmv: {
    title: "PMV",
    subTitle: "Buses Motor Vehicle Policy",
    quoteTitle: "PMV",
    color: "rgba(220, 231, 117, 0.45)",
  },
  private: {
    title: "Private",
    subTitle: "Private Motor Vehicle Policy",
    quoteTitle: "Private Motor Vehicle",
    color: "rgba(100, 181, 246, 0.45)",
  },
  taxihirecar: {
    title: "Taxi / Hire Car",
    subTitle: "Taxi Motor Vehicle Policy",
    quoteTitle: "Taxi / Hire Car",
    color: "rgba(176, 190, 197, 0.45)",
  },
  workers_compensation: {
    title: "Workers Compensation",
    subTitle: "Workers Compensation Policy",
    quoteTitle: "Workers Compensation",
    color: "rgba(189, 132, 189, 0.45)",
  },
  public_liability: {
    title: "Public Liability",
    subTitle: "Public Liability Policy",
    quoteTitle: "Public Liability",
    color: "rgba(202, 204, 57, 0.45)",
  },
};

export const VEHICLE_QUOTE_TYPES = [
  "private",
  "business",
  "commercial",
  "pmv",
  "taxihirecar",
] as QuoteType[];

export const WRK_CMP_QUOTE_TYPES = ["workers_compensation"];

export const PL_QUOTE_TYPES = ["public_liability"];
