import Dialog from "../../../../components/BrokerageQuoteTypesAccessDialog";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../../../lib/store/features/dialogs.slice";
import {
  selectedBrokerage,
  setSelectedBrokerageId,
  updateQuoteTypeAccess,
  updateQuoteFeatureAccess,
} from "../../../../lib/store/features/brokerages.slice";
import {
  selectQuoteTypes,
  selectQuoteFeatures,
} from "../../../../lib/store/features/quoteData.slice";
import { enqueue as showNotif } from "../../../../lib/store/features/notifications.slice";
import { QuoteTypeAccess, QuoteFeatureAccess } from "../../../../lib/types";

function EditQuoteTypeAccess() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(
    selectDialogOpen("EDIT_BROKERAGE_QUOTE_TYPE_ACCESS")
  );
  const brokerage = useAppSelector(selectedBrokerage);
  const quoteTypes = useAppSelector(selectQuoteTypes);
  const quoteFeatures = useAppSelector(selectQuoteFeatures);

  const handleClose = () => {
    dispatch(
      toggle({ dialog: "EDIT_BROKERAGE_QUOTE_TYPE_ACCESS", open: false })
    );
    setTimeout(() => {
      dispatch(setSelectedBrokerageId(null));
    }, 250);
  };

  const handleSubmit = async (data: {
    quoteTypes: { [key: QuoteTypeAccess["id"]]: boolean };
    quoteFeatures: { [key: QuoteFeatureAccess["id"]]: boolean };
  }) => {
    if (!brokerage) return false;

    const {
      quoteTypes: selectedQuoteTypes,
      quoteFeatures: selectedQuoteFeatures,
    } = data;

    const quote_type_ids = Object.keys(selectedQuoteTypes)
      .filter((key: any) => !!selectedQuoteTypes[key])
      .map((key) => parseInt(key));

    const selected_quote_types = quoteTypes.filter((quoteType) =>
      quote_type_ids.includes(quoteType.id)
    );

    const resultQT = await dispatch(
      updateQuoteTypeAccess({
        id: brokerage.id,
        quote_type_ids,
        selected_quote_types,
      })
    );

    if (resultQT.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: resultQT.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    const quote_feature_ids = Object.keys(selectedQuoteFeatures)
      .filter((key: any) => !!selectedQuoteFeatures[key])
      .map((key) => parseInt(key));

    const selected_quote_features = quoteFeatures.filter((quoteFeature) =>
      quote_feature_ids.includes(quoteFeature.id)
    );

    const resultQF = await dispatch(
      updateQuoteFeatureAccess({
        id: brokerage.id,
        quote_feature_ids,
        selected_quote_features,
      })
    );

    if (resultQF.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: resultQF.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "Quote Access updated",
        options: { variant: "success" },
      })
    );

    handleClose();

    return true;
  };

  return (
    <Dialog
      open={open}
      brokerageName={brokerage?.name}
      selectedQuoteTypes={brokerage?.quote_types_access}
      selectedQuoteFeatures={brokerage?.quote_features_access}
      onClose={handleClose}
      quoteTypes={quoteTypes}
      quoteFeatures={quoteFeatures}
      onSubmit={handleSubmit}
    />
  );
}

export default EditQuoteTypeAccess;
