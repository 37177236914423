import * as React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Copyright from "../../components/Copyright";
import {
  quotesNavLinks,
  quoteSearchNavLinks,
  settingsNavLinks,
  userNavLinks,
} from "../navigationLinks";
import {
  toggleNavOpen,
  selectDashBoardOpen,
} from "../../lib/store/features/dashboard.slice";
import { selectMe } from "../../lib/store/features/user.slice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import cyan from "@mui/material/colors/cyan";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface Props {
  title?: string;
  children?: React.ReactElement;
}

function Dashboard({ title, children }: Props) {
  const open = useAppSelector(selectDashBoardOpen);
  const myRole = useAppSelector(selectMe)?.role ?? "";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case "/quotes/create":
        return "Search / Create Quotes";
      case "/quotes/reports":
        return "Quote Reports";
      case "/settings/quote-data":
        return "Settings / Quote Data";
      case "/settings/brokerages":
        return "Settings / Brokerages";
      case "/settings/users":
        return "Settings / Users";
      case "/settings/quote-data/business":
        return "Settings / Quote Data / Business";
      case "/settings/quote-data/commercial":
        return "Settings / Quote Data / Commercial";
      case "/settings/quote-data/pmv":
        return "Settings / Quote Data / PMV";
      case "/settings/quote-data/private":
        return "Settings / Quote Data / Private";
      case "/settings/quote-data/taxihirecar":
        return "Settings / Quote Data / Taxi - Hire Car";
      case "/my-profile":
        return "My Profile";
      default:
        return "Quote Dashboard (SI)";
    }
  };

  const toggleDrawer = () => {
    dispatch(toggleNavOpen(!open));
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const createQuoteMenu = {
    id: "create-quote-menu",
    callback: quotesNavLinks,
    params: [
      handleNavigate,
      location.pathname,
      myRole === "agent" || myRole === "admin",
    ],
  };

  const searchQuoteMenu = {
    id: "search-quote-menu",
    callback: quoteSearchNavLinks,
    params: [handleNavigate, location.pathname],
  };

  const quoteMenuOrder =
    myRole === "superadmin"
      ? [searchQuoteMenu, createQuoteMenu]
      : [createQuoteMenu, searchQuoteMenu];

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {getTitle() || "Quote Dashboard (SI)"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {["superadmin", "admin", "agent"].includes(myRole) && (
            <>
              <ListSubheader component="div" inset sx={{ color: cyan[800] }}>
                Quotes
              </ListSubheader>
              {quoteMenuOrder.map((item: any) => {
                return (
                  <React.Fragment key={item.id}>
                    {item.callback(...item.params)}
                  </React.Fragment>
                );
              })}
            </>
          )}

          <>
            {myRole === "superadmin" && (
              <>
                <Divider sx={{ my: 1 }} />
                <ListSubheader component="div" inset sx={{ color: cyan[800] }}>
                  Settings
                </ListSubheader>
                {settingsNavLinks(handleNavigate, location.pathname)}
              </>
            )}
          </>

          <>
            <Divider sx={{ my: 1 }} />
            {userNavLinks(handleNavigate, location.pathname)}
          </>
        </List>
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Typography variant="caption" color="CaptionText">
            v{process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Outlet />
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default Dashboard;
