import React from "react";
import isPropsEqual from "react-fast-compare";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItemComponent, { MenuItemProps } from "@mui/material/MenuItem";

class MenuItemInput extends React.Component<MenuItemProps> {
  shouldComponentUpdate(nextProps: MenuItemProps) {
    return !isPropsEqual(this.props, nextProps);
  }

  render() {
    return <MenuItemComponent {...this.props} />;
  }
}

export const MenuItem = MenuItemInput;

function SelectInput(props: SelectProps) {
  return <Select {...props} />;
}

export default React.memo(SelectInput, isPropsEqual);
