import { request } from "../index";
import { apiUrls } from "../api-urls";

const urls = apiUrls.quoteReports;

export function searchQuotes({ from, to }: { from: string; to: string }) {
  let endpoint = urls.search.replace("FROM", `${from}`).replace("TO", `${to}`);

  return request({
    url: endpoint,
    method: "GET",
  });
}
