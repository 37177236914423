import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  API as ApiType,
  AppSettings,
  ApiFetchAppSettingsResponse,
  ApiUpdateAppSettingResponse,
} from "../../types";
import { handleError } from "../../helpers";
import * as settingsApi from "../../api/handlers/settings";

interface SettingsEmailState {
  app: AppSettings;
  hasChanges: boolean;
  api: ApiType;
}

const initialState: SettingsEmailState = {
  app: {
    quote_settings: {
      quote_from_email: "",
      quote_from_email_name: "",
      quote_accepted_to_email: "",
    },
  },
  hasChanges: false,
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const fetchAppSettings = createAsyncThunk(
  "settings-app/fetchAppSettings",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { api } = (getState() as RootState).settingsApp;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return handleError(rejectWithValue)("Request in progress");
      }

      const response = (await settingsApi.fetchAppSettings()) as any;
      return response.data as ApiFetchAppSettingsResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateAppSettings = createAsyncThunk(
  "settings-app/updateEmailSettings",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { api, app } = (getState() as RootState).settingsApp;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return handleError(rejectWithValue)("Request in progress");
      }

      const { quote_settings } = app;

      const r1 = (await settingsApi.updateAppSettingValue({
        key: "quote_from_email",
        value: quote_settings.quote_from_email.trim(),
      })) as any;

      const r2 = (await settingsApi.updateAppSettingValue({
        key: "quote_from_email_name",
        value: quote_settings.quote_from_email_name.trim(),
      })) as any;

      const r3 = (await settingsApi.updateAppSettingValue({
        key: "quote_accepted_to_email",
        value: quote_settings.quote_accepted_to_email.trim(),
      })) as any;

      if (r1.data.update && r2.data.update && r3.data.update) {
        return { update: true } as ApiUpdateAppSettingResponse;
      } else {
        return handleError(rejectWithValue)("Update failed");
      }
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const settingsAppSlice = createSlice({
  name: "settings-app",
  initialState,
  reducers: {
    updateValue: (state, { payload }) => {
      const { key, value } = payload;
      state.app.quote_settings[key as keyof AppSettings["quote_settings"]] =
        value;
      state.hasChanges = true;
    },
  },
  extraReducers: (builder) => {
    /* Fetch App Settings */
    builder.addCase(fetchAppSettings.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(fetchAppSettings.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.app = payload;
      }
    });
    builder.addCase(fetchAppSettings.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Update App Settings */
    builder.addCase(updateAppSettings.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(updateAppSettings.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload.update) {
        state.hasChanges = false;
      }
    });
    builder.addCase(updateAppSettings.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });
  },
});

// Actions
export const { updateValue } = settingsAppSlice.actions;

// Selectors
const selector = (state: RootState) => state.settingsApp;
export const selectAppSettings = createSelector(
  selector,
  (settings) => settings.app
);
export const selectHasChanges = createSelector(
  selector,
  (settings) => settings.hasChanges
);

export default settingsAppSlice.reducer;
