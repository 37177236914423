import React from "react";
import Paper from "@mui/material/Paper";
import isPropsEqual from "react-fast-compare";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Calculations from "../../containers/QuotesCreate/Calculations";
import Section from "../forms/Section";
import FormControl, { FormHelperText } from "../forms/FormControl";
import TextField from "../forms/TextInput";
import Select, { MenuItem } from "../forms/SelectInput";
import { useNumberFormatter } from "../../hooks";
import type {
  ClientVehicleFieldType,
  PolicyFieldType,
  CalculationsType,
  InsuranceValuesTypes,
  CalculationsFunctionsType,
  QuoteFormState,
} from "../../lib/types";
import Stack from "@mui/material/Stack";

function ReadOnlyText({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) {
  return (
    <Stack direction="column">
      <Typography variant="caption" color="rgba(0,0,0,0.6)">
        {label}
      </Typography>
      <Typography variant="body1" color="rgba(0,0,0,0.87)">
        {value}
      </Typography>
    </Stack>
  );
}

interface Props {
  // Page props
  color: string;
  subTitle: string;
  title: string;
  withNamedDriverDiscount: boolean;
  isLoading: boolean;
  // Quote calculation props
  minPremium: number;
  calculations: CalculationsType;
  calculationFunctions: CalculationsFunctionsType;
  stampDuty: number;
  // Quote data values
  additionalExcessDiscounts: number[];
  claimsFreeDiscounts: string[];
  tppdLimitIncrease: number[];

  // Quote form values
  quoteYears?: number;
  clientVehicleValues: QuoteFormState["clientVehicleValues"];
  insuranceValues: InsuranceValuesTypes;
  // Event handlers
  onChangeClientVehicleValue: ({
    field,
    value,
  }: {
    field: ClientVehicleFieldType;
    value: string | number;
  }) => void;
  onChangeInsuranceValue: ({
    field,
    value,
  }: {
    field: PolicyFieldType;
    value: string | number;
  }) => void;
  onChangeQuoteYearsValue: ({
    field,
    value,
  }: {
    field: "quoteYears";
    value: number;
  }) => void;
}

function VehicleProductForm({
  // Page props
  color,
  subTitle,
  title,
  withNamedDriverDiscount,
  isLoading,
  // Quote calculation props
  minPremium,
  calculations,
  calculationFunctions,
  stampDuty,
  // Quote data values
  additionalExcessDiscounts,
  claimsFreeDiscounts,
  tppdLimitIncrease,
  // Quote form values
  quoteYears,
  clientVehicleValues,
  insuranceValues,
  // Event handlers
  onChangeClientVehicleValue,
  onChangeInsuranceValue,
  onChangeQuoteYearsValue,
}: Props) {
  const moneyFormatter = useNumberFormatter();
  const percentFormatter = useNumberFormatter({
    style: "percent",
  });
  const decimalFormatter = useNumberFormatter({
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const handleChangeClientVehicleValue =
    (field: ClientVehicleFieldType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChangeClientVehicleValue({ field, value });
    };

  const handleChangePolicyValue =
    (field: PolicyFieldType) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { value } = event.target;
      onChangeInsuranceValue({ field, value });
    };

  const handleChangeQuoteYears = ({
    field,
    value,
  }: {
    field: "quoteYears";
    value: number;
  }) => {
    onChangeQuoteYearsValue({ field, value });
  };

  const inputFields = {
    vehicleFields: [
      {
        name: "vehicleMake",
        label: "Vehicle Make",
        autoComplete: "off",
        fullWidth: true,
        value: clientVehicleValues.vehicleMake,
        onChange: handleChangeClientVehicleValue("vehicleMake"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "vehicleModel",
        label: "Vehicle Model",
        autoComplete: "off",
        fullWidth: true,
        value: clientVehicleValues.vehicleModel,
        onChange: handleChangeClientVehicleValue("vehicleModel"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "vehicleYear",
        label: "Vehicle Year",
        autoComplete: "off",
        fullWidth: true,
        type: "number",
        value: clientVehicleValues.vehicleYear,
        onChange: handleChangeClientVehicleValue("vehicleYear"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "vehicleRegistrationNumber",
        label: "Vehicle Registration No.",
        autoComplete: "off",
        fullWidth: true,
        value: clientVehicleValues.vehicleRegistrationNumber,
        onChange: handleChangeClientVehicleValue("vehicleRegistrationNumber"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "vehicleEngineNumber",
        label: "Vehicle Engine No.",
        autoComplete: "off",
        fullWidth: true,
        value: clientVehicleValues.vehicleEngineNumber,
        onChange: handleChangeClientVehicleValue("vehicleEngineNumber"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "vehicleChassisNumber",
        label: "Vehicle Chassis No.",
        autoComplete: "off",
        fullWidth: true,
        value: clientVehicleValues.vehicleChassisNumber,
        onChange: handleChangeClientVehicleValue("vehicleChassisNumber"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },

      {
        name: "vehicleColour",
        label: "Vehicle Colour",
        autoComplete: "off",
        fullWidth: true,
        value: clientVehicleValues.vehicleColour,
        onChange: handleChangeClientVehicleValue("vehicleColour"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "vehicleTrim",
        label: "Vehicle Trim",
        autoComplete: "off",
        fullWidth: true,
        value: clientVehicleValues.vehicleTrim,
        onChange: handleChangeClientVehicleValue("vehicleTrim"),
        grid: {
          xs: 12,
          lg: 4,
        },
      },
    ],
    policyFields: [
      {
        name: "sumInsured",
        label: "Sum Insured",
        value: insuranceValues.sumInsured,
        onChange: handleChangePolicyValue("sumInsured"),
        required: true,
        // type: "number",
        min: 0,
        max: 500000,
        grid: {
          xs: 12,
        },
      },
      {
        name: "premium",
        label: "Premium",
        value: insuranceValues.premium,
        readOnly: true,
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "standardExcess",
        label: "Standard Excess for this policy",
        value: insuranceValues.standardExcess,
        readOnly: true,
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "totalLossExcess",
        label: "Excess in the event of a Total Loss",
        value: insuranceValues.totalLossExcess,
        readOnly: true,
        grid: {
          xs: 12,
          lg: 4,
        },
      },
      {
        name: "inexperiencedDriverExcess",
        label: "Inexperienced Driver Excess",
        value: insuranceValues.inexperiencedDriverExcess,
        onChange: handleChangePolicyValue("inexperiencedDriverExcess"),
        // type: "number",
        readOnly: false,
        grid: {
          xs: 12,
        },
      },
    ],
    discountFields: [
      {
        id: "cFreeDiscount",
        select: {
          label: "Claims Free Discount",
          value: insuranceValues.cFreeDiscount,
          handleChange: handleChangePolicyValue("cFreeDiscount"),
          options: claimsFreeDiscounts,
          grid: {
            xs: 12,
            lg: 6,
          },
        },
        input: {
          name: "cFreeDiscountAmount",
          label: "Discount (%)",
          value: insuranceValues.cFreeDiscountAmount,
          type: "number",
          readOnly: true,
          grid: {
            xs: 12,
            lg: 6,
          },
        },
      },
      {
        id: "aExcessDiscount",
        select: {
          label: "Additional Excess Discount",
          value: insuranceValues.aExcessDiscount,
          handleChange: handleChangePolicyValue("aExcessDiscount"),
          options: additionalExcessDiscounts,
          optionDisplay: (aed: number) => {
            return aed === 0
              ? "No Additional"
              : `Additional ${moneyFormatter.format(aed)}`;
          },
          grid: {
            xs: 12,
            lg: 6,
          },
        },
        input: {
          name: "aExcessDiscountAmount",
          label: "Discount (%)",
          value: insuranceValues.aExcessDiscountAmount,
          type: "number",
          readOnly: true,
          grid: {
            xs: 12,
            lg: 6,
          },
        },
      },
    ],
    optionalExtrasFields: [
      {
        id: "tppdLimitInc",
        select: {
          label: "TPPD Limit Increase",
          value: insuranceValues.tppdLimitInc,
          handleChange: handleChangePolicyValue("tppdLimitInc"),
          options: tppdLimitIncrease,
          optionDisplay: (val: number) =>
            val === 0 ? "No" : moneyFormatter.format(val),
          grid: {
            xs: 12,
            lg: 6,
          },
        },
        input: {
          name: "tppdLimitIncAmount",
          label: "Premium",
          value: insuranceValues.tppdLimitIncAmount,
          readOnly: true,
          type: "number",
          min: "0",
          grid: {
            xs: 12,
            lg: 6,
          },
        },
      },
    ],
  };

  if (withNamedDriverDiscount) {
    inputFields.discountFields.push({
      id: "nDriverDiscount",
      select: {
        label: "Named Driver Discount",
        value: insuranceValues.nDriverDiscount,
        handleChange: handleChangePolicyValue("nDriverDiscount"),
        options: ["Yes", "No"],
        grid: {
          xs: 12,
          lg: 6,
        },
      },
      input: {
        name: "nDriverDiscountAmount",
        label: "Discount (%)",
        value: insuranceValues.nDriverDiscountAmount,
        type: "number",
        readOnly: true,
        grid: {
          xs: 12,
          lg: 6,
        },
      },
    });
  }

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            my={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box mr={2}>
              <Typography component="p" variant="body1">
                <em>Loading data</em>
              </Typography>
            </Box>
            <CircularProgress size={24} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: 120,
          zIndex: 0,
          backgroundImage: `linear-gradient(to top, rgba(255,0,0,0), ${color})`,
        }}
      />

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h1" sx={{ mb: 2, mt: 2, px: 2 }}>
            {title}
          </Typography>
        </Grid>
      </Grid>

      <form id="quoteform" autoComplete="off">
        <Grid container spacing={2} p={2}>
          {/**
           * Vehicle Details
           **/}
          <Grid item xs={12}>
            <Section title="Client Vehicle Details">
              <Grid container spacing={2} direction="row">
                {inputFields.vehicleFields.map(
                  ({ name, grid, ...fieldProps }) => (
                    <Grid key={name} item {...grid}>
                      <TextField
                        name={name}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        {...fieldProps}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Section>
          </Grid>

          <Grid item xs={12}>
            <Section title={`${subTitle} Details`}>
              <Grid container spacing={2}>
                {/**
                 * Policy Details
                 **/}
                {inputFields.policyFields.map(
                  ({ name, grid, readOnly, ...fieldProps }) => {
                    if (readOnly) {
                      return (
                        <Grid key={name} item {...grid}>
                          <ReadOnlyText
                            label={fieldProps.label}
                            value={
                              typeof fieldProps.value === "number"
                                ? decimalFormatter.format(fieldProps.value)
                                : "0.00"
                            }
                          />
                        </Grid>
                      );
                    }

                    return (
                      <Grid key={name} item {...grid}>
                        <TextField
                          autoComplete="off"
                          name={name}
                          variant="outlined"
                          sx={{ width: "100%" }}
                          {...fieldProps}
                        />
                      </Grid>
                    );
                  }
                )}

                {/**
                 * Discount Details
                 **/}
                <Grid item xs={12}>
                  <Section subsection title="Discounts">
                    <Grid container spacing={2}>
                      {inputFields.discountFields.map(
                        ({ id, select, input }) => (
                          <React.Fragment key={id}>
                            <Grid item {...select.grid}>
                              <FormControl sx={{ width: "100%" }}>
                                <FormHelperText>{select.label}</FormHelperText>
                                <Select
                                  variant="outlined"
                                  value={select.value}
                                  onChange={select.handleChange as any}
                                >
                                  {select.options.map((val, count) => (
                                    <MenuItem key={count} value={val}>
                                      {select.optionDisplay
                                        ? select.optionDisplay(+val)
                                        : val}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item {...input.grid}>
                              <FormHelperText>&nbsp;</FormHelperText>
                              <ReadOnlyText
                                label={input.label}
                                value={percentFormatter.format(
                                  input.value / 100
                                )}
                              />
                            </Grid>
                          </React.Fragment>
                        )
                      )}
                      {withNamedDriverDiscount && (
                        <Grid item xs={12}>
                          <TextField
                            autoComplete="off"
                            label="Driver Names"
                            name="driverNames"
                            variant="outlined"
                            value={insuranceValues.driverNames}
                            onChange={handleChangePolicyValue("driverNames")}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Section>
                </Grid>

                {/**
                 * Optional Extras
                 **/}
                <Grid item xs={12}>
                  <Section subsection title="Optional Extras">
                    <Grid container spacing={2}>
                      {inputFields.optionalExtrasFields.map(
                        ({
                          id,
                          select,
                          input: { grid: inputGrid, ...restInput },
                        }) => (
                          <React.Fragment key={id}>
                            <Grid item {...select.grid}>
                              <FormControl sx={{ width: "100%" }}>
                                <FormHelperText>{select.label}</FormHelperText>
                                <Select
                                  variant="outlined"
                                  value={select.value}
                                  onChange={select.handleChange as any}
                                >
                                  {select.options.map((val, count) => (
                                    <MenuItem key={count} value={val}>
                                      {select.optionDisplay
                                        ? select.optionDisplay(val)
                                        : val}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item {...inputGrid}>
                              <FormHelperText>&nbsp;</FormHelperText>
                              <ReadOnlyText
                                label={restInput.label}
                                value={
                                  typeof restInput.value === "number"
                                    ? decimalFormatter.format(restInput.value)
                                    : "0.00"
                                }
                              />
                            </Grid>
                          </React.Fragment>
                        )
                      )}
                    </Grid>
                  </Section>
                </Grid>

                {/**
                 * Quote Calculations
                 **/}
                <Grid item xs={12}>
                  <Section subsection title="Quotation Totals">
                    <Calculations
                      quoteYears={quoteYears}
                      minPremium={minPremium}
                      calculations={calculations}
                      calculationFunctions={calculationFunctions}
                      stampDuty={stampDuty}
                      onChange={handleChangeQuoteYears}
                    />
                  </Section>
                </Grid>
              </Grid>
            </Section>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

VehicleProductForm.defaultProps = {
  withNamedDriverDiscount: false,
  title: "",
  subTitle: "",
  claimsFreeDiscounts: [
    "No previous insurance",
    "1 Year Claims Free",
    "2 Years Claims Free",
  ],
  additionalExcessDiscounts: [0, 500, 1000],
  tppdLimitIncrease: [0, 5000000, 10000000],
};

export default React.memo(VehicleProductForm, isPropsEqual);
