import React from "react";
import isPropsEqual from "react-fast-compare";
import FormControlComponent, {
  FormControlProps,
} from "@mui/material/FormControl";
import FormHelperTextComponent, {
  FormHelperTextProps,
} from "@mui/material/FormHelperText";

function FormHelperTextC(props: FormHelperTextProps) {
  return <FormHelperTextComponent {...props} />;
}

export const FormHelperText = React.memo(FormHelperTextC, isPropsEqual);

function FormControl(props: FormControlProps) {
  return <FormControlComponent {...props} />;
}

export default React.memo(FormControl, isPropsEqual);
