import * as Yup from "yup";

export const validationSchema = Yup.object({
  newPassword: Yup.string()
    .max(100, "Maximum characters reached")
    .required("New password is required"),
  repeatPassword: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Repeat password is required")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "New and repeat passwords must match"
    ),
});
