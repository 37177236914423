import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import SettingsForm from "../../components/EmailSettingsForm";
import {
  fetchEmailSettings as fetchSettings,
  updateEmailSettings as updateSettings,
  selectEmailSettings as selectSettings,
} from "../../lib/store/features/settings-email.slice";
import { enqueue } from "../../lib/store/features/notifications.slice";
import { EmailSettings as SettingsType } from "../../lib/types";

function SettingsEmail() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleSubmit = async (settings: SettingsType) => {
    const response = await dispatch(
      updateSettings({
        host: settings.host,
        port: settings.port,
        username: settings.username,
        password: settings.password,
        use_SMTP: settings.use_SMTP,
        encryption: settings.encryption ? settings.encryption : null,
      })
    );

    if (response.meta.requestStatus !== "fulfilled") {
      dispatch(
        enqueue({
          message: "Error updating email settings",
          options: { variant: "error" },
        })
      );
      return false;
    }
    dispatch(
      enqueue({
        message: "Email settings updated",
        options: { variant: "success" },
      })
    );
    return true;
  };

  return <SettingsForm settings={settings} onSubmit={handleSubmit} />;
}

export default SettingsEmail;
