import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  API as ApiType,
  EmailSettings as EmailSettingsType,
  ApiFetchEmailSettingsResponse,
  ApiSendTestEmailResponse,
} from "../../types";
import { handleError } from "../../helpers";
import * as settingsApi from "../../api/handlers/settings";

interface SettingsEmailState {
  email: EmailSettingsType;
  emailApi: ApiType;
}

const initialState: SettingsEmailState = {
  email: {
    host: "",
    port: 25,
    username: "",
    password: "",
    use_SMTP: true,
    encryption: null,
  },
  emailApi: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const sendTestEmail = createAsyncThunk(
  "settings-email/sendTestEmail",
  async (
    { from_address, to_address }: { from_address: string; to_address: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { emailApi } = (getState() as RootState).settingsEmail;
      if (emailApi.loading !== "pending" || emailApi.requestId !== requestId) {
        return handleError(rejectWithValue)("Request in progress");
      }

      const response = (await settingsApi.sendTestEmail({
        from_address,
        to_address,
      })) as any;
      return response.data as ApiSendTestEmailResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const fetchEmailSettings = createAsyncThunk(
  "settings-email/fetchEmailSettings",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { emailApi } = (getState() as RootState).settingsEmail;
      if (emailApi.loading !== "pending" || emailApi.requestId !== requestId) {
        return handleError(rejectWithValue)("Request in progress");
      }

      const response = (await settingsApi.fetchEmailSettings()) as any;
      return response.data as ApiFetchEmailSettingsResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateEmailSettings = createAsyncThunk(
  "settings-email/updateEmailSettings",
  async (
    settings: EmailSettingsType,
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { emailApi } = (getState() as RootState).settingsEmail;
      if (emailApi.loading !== "pending" || emailApi.requestId !== requestId) {
        return handleError(rejectWithValue)("Request in progress");
      }

      const response = (await settingsApi.updateEmailSettings(settings)) as any;
      return response.data as boolean;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const settingsEmailSlice = createSlice({
  name: "settings-email",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Email Settings */
    builder.addCase(fetchEmailSettings.pending, (state, { meta }) => {
      state.emailApi.loading = "pending";
      state.emailApi.requestId = meta.requestId;
      state.emailApi.error = null;
    });
    builder.addCase(fetchEmailSettings.fulfilled, (state, { payload }) => {
      state.emailApi.loading = "idle";
      state.emailApi.requestId = undefined;
      state.emailApi.error = null;

      if (payload) {
        state.email = payload;
      }
    });
    builder.addCase(fetchEmailSettings.rejected, (state, action) => {
      state.emailApi.loading = "error";
      state.emailApi.requestId = undefined;
      state.emailApi.error = action.error as string;
    });

    /* Send Test Email */
    builder.addCase(sendTestEmail.pending, (state, { meta }) => {
      state.emailApi.loading = "pending";
      state.emailApi.requestId = meta.requestId;
      state.emailApi.error = null;
    });
    builder.addCase(sendTestEmail.fulfilled, (state, { payload }) => {
      state.emailApi.loading = "idle";
      state.emailApi.requestId = undefined;
      state.emailApi.error = null;
    });
    builder.addCase(sendTestEmail.rejected, (state, action) => {
      state.emailApi.loading = "error";
      state.emailApi.requestId = undefined;
      state.emailApi.error = action.error as string;
    });

    /* Update Email Settings */
    builder.addCase(updateEmailSettings.pending, (state, { meta }) => {
      state.emailApi.loading = "pending";
      state.emailApi.requestId = meta.requestId;
      state.emailApi.error = null;
    });
    builder.addCase(
      updateEmailSettings.fulfilled,
      (state, { payload, meta }) => {
        state.emailApi.loading = "idle";
        state.emailApi.requestId = undefined;
        state.emailApi.error = null;

        if (meta.arg) {
          state.email = {
            host: meta.arg.host,
            port: meta.arg.port,
            username: meta.arg.username,
            password: meta.arg.password,
            use_SMTP: meta.arg.use_SMTP,
            encryption: meta.arg.encryption,
          };
        }
      }
    );
    builder.addCase(updateEmailSettings.rejected, (state, action) => {
      state.emailApi.loading = "error";
      state.emailApi.requestId = undefined;
      state.emailApi.error = action.error as string;
    });
  },
});

// Actions
// export const { } = settingsEmailSlice.settings;

// Selectors
const selector = (state: RootState) => state.settingsEmail;
export const selectEmailSettings = createSelector(
  selector,
  (settings) => settings.email
);

export default settingsEmailSlice.reducer;
