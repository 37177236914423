import ChangePasswordDialog from "../../../../components/ChangeUserPasswordDialog";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../../../lib/store/features/dialogs.slice";
import {
  selectedUser,
  setSelectedUserId,
} from "../../../../lib/store/features/users.slice";
import { changeUserPassword as changePassword } from "../../../../lib/store/features/users.slice";
import { enqueue as showNotif } from "../../../../lib/store/features/notifications.slice";

function ChangeUserPassword() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("CHANGE_USER_PASSWORD"));
  const user = useAppSelector(selectedUser);

  const handleClose = () => {
    dispatch(toggle({ dialog: "CHANGE_USER_PASSWORD", open: false }));
    setTimeout(() => {
      dispatch(setSelectedUserId(null));
    }, 250);
  };

  const handleSubmit = async ({ newPassword }: { newPassword: string }) => {
    if (!user) return false;

    const result = await dispatch(
      changePassword({
        newPassword,
        userId: user.id,
      })
    );

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "User password updated successfully",
        options: { variant: "success" },
      })
    );

    handleClose();

    return true;
  };

  return (
    <ChangePasswordDialog
      name={user?.name || ""}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
}

export default ChangeUserPassword;
