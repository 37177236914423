import { useAppDispatch, useAppSelector } from "../../hooks";
import Dialog from "../../components/Profile/ChangePasswordDialog";
import {
  toggle as toggleDialog,
  selectDialogOpen,
} from "../../lib/store/features/dialogs.slice";
import { updatePassword } from "../../lib/store/features/user.slice";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";

function ChangePasswordDialog() {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectDialogOpen("CHANGE_PASSWORD"));

  const handleClose = (_: any, reason?: "escapeKeyDown" | "backdropClick") => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") return;

    dispatch(toggleDialog({ dialog: "CHANGE_PASSWORD", open: false }));
  };

  const handleSubmit = async ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }): Promise<boolean> => {
    try {
      const respOne = await dispatch(
        updatePassword({ currentPassword, newPassword })
      );

      if ((respOne as any).error) {
        throw new Error(
          (respOne as any).payload?.status === 409
            ? "Incorrect current password. Please try again."
            : "There was a problem updating your password"
        );
      }

      handleClose(null);

      dispatch(
        showNotif({
          message: "Password updated",
          options: { variant: "success" },
        })
      );

      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(
          showNotif({
            message: error.message,
            options: { variant: "error" },
          })
        );
      }
      return false;
    }
  };

  return <Dialog open={isOpen} onClose={handleClose} onSubmit={handleSubmit} />;
}

export default ChangePasswordDialog;
