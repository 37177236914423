import { useMemo, useEffect, useState } from "react";
import { useDropzone, FileWithPath, FileError } from "react-dropzone";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as any,
  alignItems: "center",
  padding: "40px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#999999",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  // display: "flex",
  // flexDirection: "row" as any,
  // flexWrap: "wrap" as any,
  // marginTop: 16,
  width: 200,
  height: 100,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  width: 210,
  height: 110,
  padding: 4,
  boxSizing: "border-box" as any,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface FileType extends FileWithPath {
  preview?: string;
}

interface Props {
  title?: string;
  subtitle?: string;
  maxFiles?: number;
  minSize?: number;
  maxSize?: number;
  accept?: {
    [key: string]: string[];
  };
  onChange: (files: FileType[]) => void;
}

function Dropzone({
  title,
  subtitle,
  maxFiles,
  maxSize,
  minSize,
  accept,
  onChange,
}: Props) {
  const [files, setFiles] = useState<FileType[]>([]);
  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    maxFiles,
    minSize,
    maxSize,
    multiple: false,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      const _files = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      onChange(_files);
      setFiles(_files);
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: FileType) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const fileRejectionItems = fileRejections
    .slice(0, 1)
    .map(({ file, errors }: { file: FileType; errors: FileError[] }) => {
      const error = errors[0];
      const { code } = error;
      let message = "";

      switch (code) {
        case "file-invalid-type":
          message = "File type is not accepted";
          break;
        case "file-too-large":
          message = `File size is too large ${
            maxSize
              ? `- Keep the file size below ${Math.floor(maxSize / 1000)}kb.`
              : ""
          }`;
          break;
        case "too-many-files":
          message = `Too many files ${
            maxFiles ? `- Only select ${maxFiles} file.` : ""
          }`;
          break;
        case "name-too-large":
          message = "Name is too long";
          break;
        default:
          message = "Unknown error";
      }

      return (
        <div key={file.path} style={{ textAlign: "center" }}>
          <Typography
            component="div"
            variant="caption"
            sx={{ color: "red", fontSize: 14 }}
          >
            {message}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            sx={{ color: "GrayText", fontSize: 14 }}
          >
            {file.path}
          </Typography>
        </div>
      );
    });

  const thumbs = files.map((file: FileType) => {
    return (
      <Stack key={file.name} direction="column" spacing={0.2}>
        <div style={thumb}>
          <div style={thumbInner}>
            <img
              src={file.preview}
              style={img}
              // Revoke data uri after image is loaded
              onLoad={() => {
                if (file.preview) {
                  URL.revokeObjectURL(file.preview);
                }
              }}
              alt="preview"
            />
          </div>
        </div>
        <Typography
          variant="caption"
          sx={{ fontSize: 14, textAlign: "center" }}
        >
          <em>{file.name}</em>
        </Typography>
      </Stack>
    );
  });

  return (
    <Box>
      <Box {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography
          component="span"
          sx={{ textAlign: "center", display: "block", mb: !!subtitle ? 1 : 0 }}
        >
          {title || "Drag 'n' drop a file here, or click to select a file"}
        </Typography>
        {!!subtitle && (
          <Typography
            variant="caption"
            sx={{ fontSize: 14, textAlign: "center" }}
          >
            <em>{subtitle}</em>
          </Typography>
        )}
      </Box>
      <Box
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: 1,
        }}
      >
        {fileRejectionItems?.length > 0 && <>{fileRejectionItems}</>}
        {!fileRejectionItems?.length && files.length > 0 && (
          <aside style={thumbsContainer}>{thumbs}</aside>
        )}
      </Box>
      <aside></aside>
    </Box>
  );
}

export default Dropzone;
