import { useEffect, memo } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectLastModifiedDates,
  getQuoteData,
} from "../../lib/store/features/quoteData.slice";

function RefreshQuoteData() {
  const dispatch = useAppDispatch();
  const lastModifiedDates = useAppSelector(selectLastModifiedDates);

  useEffect(() => {
    const { server, local } = lastModifiedDates;
    if (!isEqual(server, local)) {
      const quoteTypes = Object.keys(server).reduce((acc, key) => {
        if (server[key] !== local[key]) acc.push(key);
        return acc;
      }, []);
      dispatch(getQuoteData({ quoteTypes }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastModifiedDates]);

  return null;
}

export default memo(RefreshQuoteData, isEqual);
