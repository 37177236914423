import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { login } from "../../lib/store/features/user.slice";
import SignInPage from "../../components/SignIn";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";
import { APP_PATHS } from "../navigationLinks";

function Login() {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleSubmit = async (email: string, password: string) => {
    const result = await dispatch(login({ email, password }));

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    const getTo = () => {
      const myRole = (result.payload as any)?.role;

      if ((location as any)?.state?.from?.pathname) {
        return (location as any).state.from.pathname;
      }

      if (myRole === "superadmin") {
        return APP_PATHS.reportQuotes;
      }

      return APP_PATHS.createQuote;
    };
    const to = getTo();

    navigate(to, { replace: true });

    return true;
  };

  return (
    <SignInPage
      onSubmit={handleSubmit}
      appVersion={process.env.REACT_APP_VERSION || ""}
    />
  );
}

export default Login;
