import { useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import Select from "../Select";
import Checkbox from "../Checkbox";
import { validationSchema } from "./validationSchema";
import type { EmailSettings as SettingsType } from "../../lib/types";

interface Props {
  settings: SettingsType;
  onSubmit: (settings: SettingsType) => Promise<boolean>;
}

export default function EmailSettingsForm({ settings, onSubmit }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const fields: { [key: string]: string | number | boolean } = {
    host: settings.host,
    port: settings.port,
    username: settings.username,
    password: settings.password,
    encryption: settings.encryption ? settings.encryption : "",
    use_SMTP: settings.use_SMTP,
  };

  const inputProps: { [key: string]: any } = {
    host: {
      label: "Host",
      placeholder: "Enter the host name",
      type: "text",
      Component: TextField,
      grid: {
        xs: 12,
        md: 6,
      },
    },
    port: {
      label: "Port",
      placeholder: "Enter the port number (e.g. 25)",
      type: "number",
      Component: TextField,
      grid: {
        xs: 12,
        md: 6,
      },
    },
    username: {
      label: "Username",
      placeholder: "Enter the username",
      type: "text",
      Component: TextField,
      grid: {
        xs: 12,
        md: 6,
      },
    },
    password: {
      label: "Password",
      placeholder: "Enter the password",
      type: "password",
      Component: TextField,
      grid: {
        xs: 12,
        md: 6,
      },
    },
    encryption: {
      id: "encryption",
      label: "Encryption",
      options: [
        { label: "None", value: "" },
        { label: "TLS", value: "tls" },
        { label: "SSL", value: "ssl" },
      ],
      Component: Select,
      type: "select",
      grid: {
        xs: 12,
        md: 6,
      },
    },
    use_SMTP: {
      id: "use_SMTP",
      title: "",
      type: "checkbox",
      label: "Use SMTP",
      Component: Checkbox,
      evalProps: ({ field, meta, form }: FieldProps) => ({
        onChange: (checked: boolean) => {
          form.setFieldValue("use_SMTP", checked);
        },
      }),
      grid: {
        xs: 12,
        md: 6,
      },
    },
  };

  return (
    <Formik
      initialValues={{ ...fields }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        const result = await onSubmit(values as any);
        setSubmitting(false);
        setIsLoading(false);
        if (result) resetForm();
      }}
    >
      <Form noValidate>
        <Typography variant="h6" component="h1">
          Email Server Settings
        </Typography>

        <Typography variant="body1" component="span" color="GrayText">
          Enter the email server settings below.
        </Typography>
        <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
          {Object.keys(fields).map((fieldName) => {
            const { Component, type, name, grid, evalProps, ...props } =
              inputProps[fieldName];

            return (
              <Grid item xs={12} md={6} key={fieldName} {...grid}>
                <Field type={type} name={fieldName}>
                  {({ field, meta, form }: FieldProps) => {
                    return (
                      <Component
                        {...field}
                        {...props}
                        {...(evalProps ? evalProps({ field, meta, form }) : {})}
                        type={type}
                        disabled={isLoading || form.isSubmitting}
                        required
                        fullWidth
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && !!meta.error ? meta.error : null
                        }
                      />
                    );
                  }}
                </Field>
              </Grid>
            );
          })}
        </Grid>
        <Stack direction="row">
          <LoadingButton
            loading={isLoading}
            loadingPosition="end"
            endIcon={<SaveIcon />}
            variant="contained"
            type="submit"
          >
            Save Settings
          </LoadingButton>
        </Stack>
      </Form>
    </Formik>
  );
}
