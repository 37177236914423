import Typography from "@mui/material/Typography";

function Title({ subtitle, children, ...rest }: any) {
  return (
    <Typography
      component={subtitle ? "h3" : "h2"}
      variant={subtitle ? "h6" : "h5"}
      color="primary"
      gutterBottom
      {...rest}
    >
      {children}
    </Typography>
  );
}

export default Title;
