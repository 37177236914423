import { Page, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { PageHeader } from "./Page";
import type {
  PDFClientDetails,
  PDFQuoteDetails,
  PDFAgentDetails,
  PDFVehicleDetails,
} from "../../../../lib/types";
import styles from "./styles";

interface Props {
  clientDetails: PDFClientDetails;
  quoteDetails: {
    reference: PDFQuoteDetails["reference"];
    dates: PDFQuoteDetails["dates"];
    titles: string[];
  };
  vehicles: PDFVehicleDetails[];
  agentDetails: PDFAgentDetails;
  workersCompensation: boolean;
  publicLiablity: boolean;
}

function Letter({
  clientDetails,
  quoteDetails,
  vehicles,
  agentDetails,
  workersCompensation,
  publicLiablity,
}: Props) {
  const {
    isPrivate,
    clientTitle,
    clientFirstName,
    clientLastName,
    clientAddress,
  } = clientDetails;
  const {
    dates: { quoteDate, expiryDate },
    titles,
  } = quoteDetails;

  const getVehicleText = () => {
    if (vehicles.length === 0) return "";

    if (vehicles.length > 1) {
      const vehicleText = vehicles.map((vehicle) => {
        const { vehicleMake, vehicleModel, vehicleYear } = vehicle;
        return `${vehicleYear} ${vehicleMake} ${vehicleModel}`;
      });
      return (
        "vehicles - " +
        vehicleText
          .slice(0, vehicleText.length - 1)
          .join(", ")
          .concat(" and " + vehicleText[vehicleText.length - 1])
      );
    }

    // just one product
    const { vehicleMake, vehicleModel, vehicleYear } = vehicles[0];
    return `vehicle ${vehicleYear} ${vehicleMake} ${vehicleModel}`;
  };

  const getTitlesText = (postfix?: string) => {
    if (titles.length === 0) return "";

    const _postfix = postfix ? ` ${postfix}` : "";

    if (titles.length > 1) {
      return titles
        .slice(0, titles.length - 1)
        .join(`${_postfix}, `)
        .concat(`${_postfix} and ${titles[titles.length - 1]}${_postfix}`)
        .trim();
    }

    // just one product
    return `${titles[0]}${_postfix}`.trim();
  };

  const getLetterRegardingText = () => {
    let text = [];
    if (workersCompensation) {
      text.push("Workers Compensation");
    }

    if (publicLiablity) {
      text.push("Public Liability");
    }

    if (vehicles.length > 0) {
      text.push(getVehicleText());
    }

    if (text.length > 1) {
      return `Regarding your ${text
        .slice(0, text.length - 1)
        .join(", ")
        .concat(` and ${text[text.length - 1]}`)
        .trim()}`;
    }

    // just one
    return `Regarding your ${text[0]}`;
  };

  const {
    agentName,
    brokerageName,
    // agentEmail, agentPhone
  } = agentDetails;

  const clientName = isPrivate
    ? `${clientTitle} ${clientFirstName} ${clientLastName}`.trim()
    : "Sir / Madam";

  return (
    <Page size="A4" style={styles.page}>
      <PageHeader {...agentDetails} />
      {/* Business Name and Address if !isPrivate */}
      {!isPrivate && (
        <View style={styles.marginBottom30}>
          <Text style={styles.textNormal}>{clientFirstName}</Text>
          <Text style={styles.textNormal}>{clientAddress}</Text>
        </View>
      )}

      {/* Date */}
      <View style={styles.marginBottom20}>
        <Text style={styles.textNormal}>
          {format(quoteDate, "do MMMM yyyy")}
        </Text>
      </View>
      {/* Client Address */}
      {/* <View style={styles.marginBottom80}>
        <Text style={styles.textNormal}>{clientAddress}</Text>
      </View> */}
      {/* Client Name */}
      <View style={styles.marginBottom20}>
        <Text style={styles.textNormal}>Dear {clientName},</Text>
      </View>
      {/* Letter Subject */}
      <View style={styles.marginBottom14}>
        <Text style={styles.textNormal}>
          Your {getTitlesText("Policy")}.
          {/* Quote reference{" "} {quoteDetails.reference}. */}
        </Text>
      </View>
      {/* Client Car */}
      <View style={styles.marginBottom14}>
        <Text style={styles.textBold}>{getLetterRegardingText()}</Text>
      </View>
      {/* Letter */}
      <View style={styles.marginBottom8}>
        <Text style={styles.textNormal}>
          Thank you for requesting a quotation today for your{" "}
          {getTitlesText("")} Insurance.
        </Text>
      </View>
      <View style={styles.marginBottom8}>
        <Text style={styles.textNormal}>
          Please find attached quotation for your review which is based on the
          information you have provided. This quote is valid until{" "}
          {format(expiryDate, "do MMMM yyyy")}.
        </Text>
      </View>
      <View style={styles.marginBottom20}>
        <Text style={styles.textNormal}>
          We look forward to your acceptance and issuing this important
          insurance cover for you.
        </Text>
      </View>
      {/* Signature */}
      <View style={styles.marginBottom80}>
        <Text style={styles.textNormal}>Yours sincerely,</Text>
      </View>
      <View>
        <Text style={styles.textNormal}>{agentName}</Text>
        <Text style={styles.textNormal}>{brokerageName}</Text>
        {/* {(!!agentPhone || !!agentEmail) && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {agentPhone && (
              <Text style={styles.textNote}>{`P: ${agentPhone}`}</Text>
            )}
            {agentEmail && (
              <Text style={styles.textNormal}>{`Email: ${agentEmail}`}</Text>
            )}
          </View>
        )} */}
      </View>
    </Page>
  );
}

export default Letter;
