import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getMe, selectLoading } from "../../lib/store/features/user.slice";
import {
  fetchParentBrokerage,
  selectLoadingParentBrokerage,
} from "../../lib/store/features/brokerages.slice";
import QuoteMain from "./QuoteMain";
import QuoteStatusDialog from "./QuoteStatusDialog";
import QuoteCreateDialog from "./QuoteCreateDialog";
import QuoteEditDialog from "./QuoteEditDialog";

function Quote() {
  const dispatch = useAppDispatch();
  const loadingMe = useAppSelector(selectLoading);
  const loadingParentBrokerage = useAppSelector(selectLoadingParentBrokerage);

  useEffect(() => {
    const init = async () => {
      await dispatch(getMe());
      await dispatch(fetchParentBrokerage());
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Dialog to change the quote status */}
      <QuoteStatusDialog />

      {/* Dialog to add a new product to the quote */}
      <QuoteCreateDialog />
      {/* Dialog to edit an existing product on the quote */}
      <QuoteEditDialog />

      {/* Client and agent details and list of products on quote */}
      <QuoteMain loadingMe={loadingMe || loadingParentBrokerage} />
    </>
  );
}

export default Quote;
