import { Text, View } from "@react-pdf/renderer";
import { useNumberFormatter } from "../../../../../hooks";
import { getTotalsCalculations } from "../../../../../lib/helpers";
import type {
  CalculationsType,
  CalculationsFunctionsType,
  PDFVehicleDetails,
} from "../../../../../lib/types";
import styles from "../styles";

interface Props {
  quoteDetails: {
    yearsRequired: number;
    calculations: CalculationsType;
    calculationFunctions: CalculationsFunctionsType;
    minPremium: number;
    vehicle: PDFVehicleDetails;
    stampDuty: number;
  };
  multipleProducts: boolean;
}

function VehicleTotals({ quoteDetails, multipleProducts }: Props) {
  const {
    yearsRequired,
    calculations,
    calculationFunctions,
    minPremium,
    vehicle,
    stampDuty,
  } = quoteDetails;

  const moneyFormatter = useNumberFormatter();
  const { sumInsured, sumInsuredPremium, discountPercentage } = calculations;
  const { grandTotal, totalCalculations } = getTotalsCalculations({
    sumInsuredPremium,
    yearsRequired,
    sumInsured,
    calculationFunctions,
    discountPercentage,
    minPremium,
    stampDuty,
  });

  const _calculationFields = [
    {
      id: "sumInsured",
      name: "Sum Insured",
      value: 0,
    },
    {
      id: "sumInsuredPremium",
      name: "Sum Insured Premium",
      value: 0,
    },
    {
      id: "discounts",
      name: "Less Discounts",
      value: 0,
      indent: true,
    },
    {
      id: "optionalExtras",
      name: "Plus Optional Extras",
      value: 0,
      indent: true,
    },
    {
      id: "subTotal",
      name: `Sub Total\nMinimum premium ${moneyFormatter.format(minPremium)}`,
      value: 0,
      indent: true,
    },
    {
      id: "stampDuty",
      name: "Stamp Duty",
      value: 0,
    },
  ];

  const totals = [
    {
      id: "periodTotal",
      name: "Period Total",
      value: 0,
    },
    {
      id: 8,
      name: multipleProducts ? "Total" : "Grand Total",
      value: grandTotal,
      isTotal: true,
    },
  ];

  const calculationFields =
    yearsRequired > 1
      ? [..._calculationFields, ...totals]
      : [..._calculationFields, totals[1]];

  return (
    <>
      {/* Quotation Totals */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>
          Quotation for{" "}
          {`${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`}
        </Text>
        <View style={styles.subSectionHeaderLine} />
      </View>

      <View>
        <View style={[styles.flexRow, styles.trBorderBottom]}>
          <View style={[styles.flex1, styles.padding8, styles.flexRow]}>
            <Text> </Text>
          </View>
          {yearsRequired > 1 &&
            [...Array(yearsRequired).keys()].map((year, yearCount) => (
              <View
                key={yearCount}
                style={[
                  styles.flex1,
                  styles.justifyEnd,
                  styles.flexRow,
                  styles.paddingTop8,
                  styles.paddingBottom8,
                ]}
              >
                <Text style={styles.thText}>Year {year + 1}</Text>
              </View>
            ))}
          {yearsRequired === 1 && (
            <View
              style={[
                styles.flex1,
                styles.justifyEnd,
                styles.flexRow,
                styles.paddingTop8,
                styles.paddingBottom8,
              ]}
            >
              <Text style={styles.thText}>Single Year</Text>
            </View>
          )}
        </View>
      </View>
      <View>
        {calculationFields.map(({ id, ...row }) => (
          <View key={id} style={[styles.flexRow, styles.trBorderBottom]}>
            <View style={[styles.flex1, styles.padding8, styles.flexRow]}>
              {(row as any).indent && <View style={{ paddingLeft: 10 }} />}
              <Text
                style={(row as any).isTotal ? styles.thText : styles.tdText}
              >
                {(row as any).name}
              </Text>
            </View>
            {!(row as any).isTotal &&
              [...Array(yearsRequired).keys()].map((_, yearIndex) => {
                const tCalculations: any = totalCalculations[yearIndex];
                return (
                  <View
                    key={yearIndex}
                    style={[
                      styles.flex1,
                      styles.justifyEnd,
                      styles.flexRow,
                      styles.paddingTop8,
                      styles.paddingBottom8,
                    ]}
                  >
                    <Text style={styles.tdText}>
                      {moneyFormatter.format(
                        tCalculations ? tCalculations[id] : 0
                      )}
                    </Text>
                  </View>
                );
              })}
            {(row as any).isTotal && (
              <View
                style={[
                  styles.flex1,
                  styles.justifyEnd,
                  styles.flexRow,
                  styles.paddingTop8,
                  styles.paddingBottom8,
                ]}
              >
                <Text style={styles.thText}>
                  {moneyFormatter.format(row.value)}
                </Text>
              </View>
            )}
          </View>
        ))}
      </View>
    </>
  );
}

VehicleTotals.defaultProps = {
  yearsRequired: 1,
};

export default VehicleTotals;
