import { Text, View } from "@react-pdf/renderer";
import { useNumberFormatter } from "../../../../../hooks";
import type {
  PDFClientDetails,
  InsuranceValuesTypes,
  PDFVehicleDetails,
} from "../../../../../lib/types";
import styles from "../styles";

interface Props {
  clientDetails: PDFClientDetails;
  quoteDetails: {
    title: string;
    withNamedDriverDiscount: boolean;
    insuranceValues: InsuranceValuesTypes;
    vehicle: PDFVehicleDetails;
  };
}

function VehiclePolicy({ clientDetails, quoteDetails }: Props) {
  const {
    title: quoteTitle,
    withNamedDriverDiscount,
    insuranceValues,
    vehicle,
  } = quoteDetails;

  const moneyFormatter = useNumberFormatter();
  const {
    cFreeDiscount,
    cFreeDiscountAmount,
    aExcessDiscount,
    aExcessDiscountAmount,
    nDriverDiscount,
    nDriverDiscountAmount,
    sumInsured,
    premium,
    standardExcess,
    totalLossExcess,
    inexperiencedDriverExcess,
    driverNames,
    tppdLimitInc,
    tppdLimitIncAmount,
  } = insuranceValues;

  const { vehicleMake, vehicleModel, vehicleYear } = vehicle;

  const {
    isPrivate,
    clientTitle,
    clientFirstName,
    clientLastName,
    clientAddress,
  } = clientDetails;

  const clientName = isPrivate
    ? `${clientTitle} ${clientFirstName} ${clientLastName}`.trim()
    : `${clientFirstName}`.trim();

  return (
    <>
      {/* Client & Vehicle Details */}
      <View style={[styles.sectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textWhite]}>
          Client and Vehicle Details
        </Text>
      </View>
      <View style={[styles.flexRow, styles.sectionPadding]}>
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>Name</Text>
            <Text style={styles.textNormal}>{clientName}</Text>
          </View>
          <View>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Address
            </Text>
            <Text style={styles.textNormal}>{clientAddress}</Text>
          </View>
        </View>
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Vehicle Make
            </Text>
            <Text style={styles.textNormal}>{vehicleMake}</Text>
          </View>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Vehicle Model
            </Text>
            <Text style={styles.textNormal}>{vehicleModel}</Text>
          </View>
          <View>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Vehicle Year
            </Text>
            <Text style={styles.textNormal}>{vehicleYear}</Text>
          </View>
        </View>
      </View>
      <View style={styles.marginBottom20} />

      {/* Policy Details */}
      <View style={[styles.sectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textWhite]}>
          {quoteTitle} Details
        </Text>
      </View>
      <View
        style={[styles.flexRow, styles.sectionPadding, styles.marginBottom14]}
      >
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Sum Insured
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(sumInsured ? sumInsured : 0)}
            </Text>
          </View>
          <View>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Premium
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(premium ? premium : 0)}
            </Text>
          </View>
        </View>
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Standard excess for this policy
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(standardExcess ? standardExcess : 0)}
            </Text>
          </View>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Excess in the event of total loss
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(totalLossExcess ? totalLossExcess : 0)}
            </Text>
          </View>
          <View>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Inexperienced driver excess
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(
                inexperiencedDriverExcess ? inexperiencedDriverExcess : 0
              )}
            </Text>
          </View>
        </View>
      </View>
      <View style={[styles.flexRow, styles.justifyCenter]}>
        <Text style={styles.textNote}>
          The excess is the amount you need to pay towards the cost of each
          claim
        </Text>
      </View>
      <View style={styles.marginBottom20} />

      {/* Discounts */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>Discounts</Text>
        <View style={styles.subSectionHeaderLine} />
      </View>
      <View style={[styles.marginBottom8, styles.sectionPadding]}>
        <View>
          <Text style={[styles.itemHeading, styles.textDarkGrey]}>
            Claims free discount
          </Text>
        </View>
        <View style={[styles.flexRow, styles.marginBottom8]}>
          <View
            style={[
              styles.flexRow,
              styles.width50,
              styles.justifyStart,
              styles.alignEnd,
            ]}
          >
            <View>
              <Text style={styles.textNormal}>{cFreeDiscount}</Text>
            </View>
            <View style={styles.dottedLine} />
          </View>
          <View style={styles.width50}>
            <Text style={styles.textNormal}>{cFreeDiscountAmount}%</Text>
          </View>
        </View>
      </View>

      <View style={[styles.marginBottom8, styles.sectionPadding]}>
        <View>
          <Text style={[styles.itemHeading, styles.textDarkGrey]}>
            Additional Excess Discount
          </Text>
        </View>
        <View style={[styles.flexRow, styles.marginBottom8]}>
          <View
            style={[
              styles.flexRow,
              styles.width50,
              styles.justifyStart,
              styles.alignEnd,
            ]}
          >
            <View>
              <Text style={styles.textNormal}>
                {aExcessDiscount === 0
                  ? "No Additional"
                  : `Additional ${moneyFormatter.format(aExcessDiscount)}`}
              </Text>
            </View>
            <View style={styles.dottedLine} />
          </View>
          <View style={styles.width50}>
            <Text style={styles.textNormal}>{aExcessDiscountAmount}%</Text>
          </View>
        </View>
      </View>

      {withNamedDriverDiscount && (
        <View style={[styles.marginBottom8, styles.sectionPadding]}>
          <View>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Named Driver Discount
            </Text>
          </View>
          <View style={[styles.flexRow, styles.marginBottom8]}>
            <View
              style={[
                styles.flexRow,
                styles.width50,
                styles.justifyStart,
                styles.alignEnd,
              ]}
            >
              <View>
                <Text style={styles.textNormal}>{nDriverDiscount}</Text>
              </View>
              <View style={styles.dottedLine} />
            </View>
            <View style={styles.width50}>
              <Text style={styles.textNormal}>{nDriverDiscountAmount}%</Text>
            </View>
          </View>
          {!!driverNames && (
            <View>
              <Text style={[styles.itemHeading, styles.textDarkGrey]}>
                Driver Names
              </Text>
              <Text style={styles.textNormal}>{driverNames}</Text>
            </View>
          )}
        </View>
      )}
      <View style={styles.marginBottom14} />

      {/* Optional Extras */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>
          Optional Extras
        </Text>
        <View style={styles.subSectionHeaderLine} />
      </View>
      <View style={[styles.flexRow, styles.sectionPadding]}>
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              TPPD Limit Increase
            </Text>
            <Text style={styles.textNormal}>
              {tppdLimitInc === 0 ? "No" : moneyFormatter.format(tppdLimitInc)}
            </Text>
          </View>
        </View>
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Premium
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(tppdLimitIncAmount)}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
}

export default VehiclePolicy;
