import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import OtherSettingsForm from "../../components/OtherSettingsForm";
import { enqueue } from "../../lib/store/features/notifications.slice";
import {
  fetchOtherSettings as fetchSettings,
  updateOtherSettings as updateSettings,
  selectIsFetching as selectFetching,
  selectIsSaving as selectSaving,
  selectOtherSettings as selectSettings,
} from "../../lib/store/features/settings-other.slice";
import { getStampDuty } from "../../lib/store/features/quoteData.slice";
import { NotificationTypes, OtherSettingsFormValues } from "../../lib/types";

function OtherSettings() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);
  const loading = useAppSelector(selectFetching);
  const saving = useAppSelector(selectSaving);

  useEffect(() => {
    dispatch(fetchSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveData = async (data: OtherSettingsFormValues) => {
    const response = await dispatch(updateSettings(data));

    let message;
    let variant: NotificationTypes;
    if (response.meta.requestStatus === "rejected") {
      message = "Error saving settings";
      variant = "error";
    } else {
      message = "Settings saved successfully";
      variant = "success";
      dispatch(getStampDuty()); // get the latest stamp duty for the quote data redux slice (which is used to calculate the quote)
    }

    dispatch(enqueue({ message, options: { variant } }));
  };

  return (
    <OtherSettingsForm
      onSave={handleSaveData}
      loading={loading}
      saving={saving}
      settings={settings}
      lastModified={null} // Not implemented
    />
  );
}

export default OtherSettings;
