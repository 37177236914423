import UserDialog from "../../../../components/UserDialog";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../../../lib/store/features/dialogs.slice";
import { selectList as selectBrokeragesList } from "../../../../lib/store/features/brokerages.slice";
import { addUser } from "../../../../lib/store/features/users.slice";
import { enqueue as showNotif } from "../../../../lib/store/features/notifications.slice";
import { User } from "../../../../lib/types";

function AddUser() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("ADD_USER"));
  const brokerages = useAppSelector(selectBrokeragesList);

  const handleClose = () => {
    dispatch(toggle({ dialog: "ADD_USER", open: false }));
  };

  const handleSubmit = async (user: User) => {
    const result = await dispatch(
      addUser({
        name: user.name,
        email: user.email,
        phone: user.phone,
        password: user.password,
        role: user.role,
        isActive: true,
        brokerage_id: user.brokerage_id || null,
      })
    );

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "User successfully created",
        options: { variant: "success" },
      })
    );

    dispatch(toggle({ dialog: "ADD_USER", open: false }));

    return true;
  };

  return (
    <UserDialog
      open={open}
      brokerages={brokerages}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
}

export default AddUser;
