import { Text, View } from "@react-pdf/renderer";
import { useNumberFormatter } from "../../../../../hooks";
import type {
  PDFClientDetails,
  PDFQuotePubLiabDetails,
} from "../../../../../lib/types";
import styles from "../styles";

interface Props {
  clientDetails: PDFClientDetails;
  quoteDetails: {
    title: PDFQuotePubLiabDetails["title"];
    category: PDFQuotePubLiabDetails["category"];
    occupation: PDFQuotePubLiabDetails["occupation"];
    turnover: PDFQuotePubLiabDetails["turnover"];
    rate: PDFQuotePubLiabDetails["rate"];
    claimsHistory: PDFQuotePubLiabDetails["claimsHistory"];
    selectedLOLValues: PDFQuotePubLiabDetails["selectedLOLValues"];
    selectedLOLAmounts: PDFQuotePubLiabDetails["selectedLOLAmounts"];
  };
}

function PubLiabPolicy({ clientDetails, quoteDetails }: Props) {
  const {
    title: quoteTitle,
    category,
    occupation,
    turnover,
    // rate,
    claimsHistory,
    selectedLOLValues,
    selectedLOLAmounts,
  } = quoteDetails;
  const moneyFormatter = useNumberFormatter();
  // const percentFormatter = useNumberFormatter({
  //   style: "percent",
  //   maximumFractionDigits: 3,
  //   minimumFractionDigits: 3,
  // });

  const {
    isPrivate,
    clientTitle,
    clientFirstName,
    clientLastName,
    clientAddress,
  } = clientDetails;

  const clientName = isPrivate
    ? `${clientTitle} ${clientFirstName} ${clientLastName}`.trim()
    : `${clientFirstName}`.trim();

  return (
    <>
      {/* Client & Vehicle Details */}
      <View style={[styles.sectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textWhite]}>
          Client Details
        </Text>
      </View>
      <View style={[styles.flexRow, styles.sectionPadding]}>
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>Name</Text>
            <Text style={styles.textNormal}>{clientName}</Text>
          </View>
          <View>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Address
            </Text>
            <Text style={styles.textNormal}>{clientAddress}</Text>
          </View>
        </View>
      </View>
      <View style={styles.marginBottom20} />

      {/* Policy Details */}
      <View style={[styles.sectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textWhite]}>
          {quoteTitle} Policy Details
        </Text>
      </View>
      <View
        style={[styles.flexColumn, styles.sectionPadding, styles.marginBottom8]}
      >
        <View style={styles.marginBottom8}>
          <Text style={[styles.itemHeading, styles.textDarkGrey]}>
            Category
          </Text>
          <Text style={styles.textNormal}>{category}</Text>
        </View>
        <View style={styles.marginBottom8}>
          <Text style={[styles.itemHeading, styles.textDarkGrey]}>
            Occupation
          </Text>
          <Text style={styles.textNormal}>{occupation}</Text>
        </View>

        <View style={styles.flexRow}>
          <View style={[styles.marginBottom8, styles.width50]}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Turnover
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(turnover)}
            </Text>
          </View>
          {/*
          TPAL requested not to show the rate
          <View style={[styles.marginBottom8, styles.width50]}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>Rate</Text>
            <Text style={styles.textNormal}>
              {percentFormatter.format(rate * 100)}
            </Text>
          </View> */}
        </View>
      </View>
      <View style={styles.marginBottom12} />

      {/* Claims History */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>Claims History</Text>
        <View style={styles.subSectionHeaderLine} />
      </View>
      {claimsHistory.map((claim, index) => (
        <View
          key={index}
          style={[
            styles.marginBottom12,
            styles.sectionPadding,
            styles.flexRow,
            styles.justifySpaceBetween,
            styles.borderBottom,
          ]}
        >
          <View style={[styles.flexColumn, styles.width30]}>
            <View style={styles.marginTop14}>
              <Text style={styles.textNormal}>Year {claim.year}</Text>
            </View>
          </View>
          <View style={[styles.flexColumn, styles.width30]}>
            <View style={styles.textAlignRight}>
              <Text style={[styles.itemHeading, styles.textDarkGrey]}>
                No. of Claims
              </Text>
            </View>
            <View style={styles.textAlignRight}>
              <Text style={styles.textNormal}>{claim.claim_count}</Text>
            </View>
          </View>
          <View
            style={[styles.flexColumn, styles.width30, styles.textAlignRight]}
          >
            <View>
              <Text style={[styles.itemHeading, styles.textDarkGrey]}>
                Claim Amount
              </Text>
            </View>
            <View>
              <Text style={styles.textNormal}>
                {moneyFormatter.format(claim.claim_amount)}
              </Text>
            </View>
          </View>
        </View>
      ))}
      {claimsHistory.length === 0 && (
        <View
          style={[
            styles.marginBottom12,
            styles.sectionPadding,
            styles.alignCenter,
          ]}
        >
          <Text style={styles.textNormal}>- No Claims History -</Text>
        </View>
      )}

      {/* Quotation */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>Quotation</Text>
        <View style={styles.subSectionHeaderLine} />
      </View>
      <View
        style={[
          styles.flexRow,
          styles.marginBottom8,
          styles.sectionPadding,
          styles.justifySpaceBetween,
        ]}
      >
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}> </Text>
        </View>
        <View style={[styles.width30, styles.textAlignRight]}>
          <Text
            style={[
              styles.textDarkGrey,
              styles.textNormal,
              styles.textAlignRight,
            ]}
          >
            Selected
          </Text>
        </View>
        <View style={[styles.width30, styles.textAlignRight]}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}>Amount</Text>
        </View>
      </View>
      {Object.keys(selectedLOLValues).map((lolName, index) => (
        <View
          key={index}
          style={[
            styles.flexRow,
            styles.marginBottom8,
            styles.sectionPadding,
            styles.justifySpaceBetween,
            styles.borderBottom,
          ]}
        >
          <View style={styles.width30}>
            <Text style={[styles.textNormal]}>{lolName}</Text>
          </View>
          <View style={[styles.width30, styles.textAlignRight]}>
            <Text style={[styles.textNormal]}>
              {selectedLOLValues[lolName]}
            </Text>
          </View>
          <View style={[styles.width30, styles.textAlignRight]}>
            <Text style={[styles.textNormal]}>
              {moneyFormatter.format(selectedLOLAmounts[lolName])}
            </Text>
          </View>
        </View>
      ))}
    </>
  );
}

export default PubLiabPolicy;
