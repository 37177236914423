import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { logout } from "../../lib/store/features/user.slice";
import { persistor } from "../../lib/store";

function Logout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const doLogout = async () => {
      try {
        await dispatch(logout());
      } finally {
        persistor?.purge();
        navigate("/login", { replace: true });
      }
    };

    doLogout();
  }, [dispatch, navigate]);

  return null;
}

export default Logout;
