import { useEffect, useState, useCallback } from "react";
import { format, parse } from "date-fns";
import {
  unstable_usePrompt as useNavigationBlock,
  useBeforeUnload,
} from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks";
import MainDetails from "../../components/Quote/Main";
import {
  selectMe,
  makeSelectIsCoCFeatureEnabled,
} from "../../lib/store/features/user.slice";
import {
  reset,
  setClient,
  setEffectiveDate,
  selectClientQuote,
  setSelectedProductId,
  selectHasQuoteChanges,
  saveQuote,
  selectSavingQuote,
  selectUpdatingQuoteStatus,
  selectSendingCoCEmail,
  setHasChanges,
} from "../../lib/store/features/quotes.slice";
import {
  selectLoadingQuoteData,
  selectLoadingLastModifiedDates,
} from "../../lib/store/features/quoteData.slice";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";
import { toggle as toggleDialog } from "../../lib/store/features/dialogs.slice";
import type { ClientFieldType } from "../../lib/types";

function QuoteCreate({ loadingMe }: { loadingMe: boolean }) {
  const dispatch = useAppDispatch();
  const me = useAppSelector(selectMe);
  const isCoCFeatureEnabled = useAppSelector(makeSelectIsCoCFeatureEnabled());
  const loadingQuoteDataDates = useAppSelector(selectLoadingLastModifiedDates);
  const loadingQuoteData = useAppSelector(selectLoadingQuoteData);
  const saving = useAppSelector(selectSavingQuote);
  const updatingStatus = useAppSelector(selectUpdatingQuoteStatus);
  const sendingCoc = useAppSelector(selectSendingCoCEmail);
  const hasChanges = useAppSelector(selectHasQuoteChanges);
  const clientQuote = useAppSelector(selectClientQuote);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  // Block navigation if changes were made
  useNavigationBlock({
    when: hasChanges,
    message:
      "You have unsaved changes. Are you sure you wan to leave this page?",
  });

  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasChanges || clientQuote.products.length > 0) {
          event.preventDefault();
          event.returnValue = "Are you sure you want to leave?";
        }
      },
      [hasChanges, clientQuote.products]
    )
  );

  useEffect(() => {
    if (!clientQuote.effectiveDate) {
      dispatch(setEffectiveDate(format(new Date(), "yyyy-MM-dd")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Event handlers
   */

  const handleChangeClientValue = ({
    field,
    value,
  }: {
    field: ClientFieldType;
    value: string | number | boolean;
  }) => {
    dispatch(
      setClient({
        ...clientQuote.client,
        [field]: value,
      })
    );
  };

  const handleChangeQuoteDateValue = ({ value }: { value: Date }) => {
    dispatch(setEffectiveDate(format(value, "yyyy-MM-dd")));
  };

  const handleAddProduct = () => {
    dispatch(toggleDialog({ dialog: "CREATE_QUOTE", open: true }));
  };

  const handleEditProduct = (productId: number) => {
    dispatch(setSelectedProductId(productId));
    dispatch(toggleDialog({ dialog: "EDIT_QUOTE", open: true }));
  };

  const handleNewQuote = () => {
    if (hasChanges || clientQuote.products.length > 0) {
      if (
        window.confirm("Starting a new quote will remove all unsaved changes")
      ) {
        dispatch(reset());
        dispatch(setEffectiveDate(format(new Date(), "yyyy-MM-dd")));
        dispatch(setHasChanges(false));
      }
    } else {
      dispatch(reset());
      dispatch(setEffectiveDate(format(new Date(), "yyyy-MM-dd")));
      dispatch(setHasChanges(false));
    }
  };

  const handleChangeQuoteStatus = () => {
    dispatch(toggleDialog({ dialog: "CHANGE_QUOTE_STATUS", open: true }));
  };

  const handleSaveQuote = async () => {
    const {
      clientTitle,
      clientFirstName,
      clientLastName,
      clientAddress,
      isPrivate,
    } = clientQuote.client;

    setHasSubmitted(true);

    if (isPrivate) {
      if (
        !clientTitle ||
        !clientFirstName ||
        !clientLastName ||
        !clientAddress
      ) {
        return;
      }
    } else {
      if (!clientFirstName || !clientAddress) {
        return;
      }
    }

    if (clientQuote.products.length === 0) {
      return;
    }

    const result = await dispatch(saveQuote());

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "Quote saved successfully",
        options: { variant: "success" },
      })
    );

    setHasSubmitted(false);
  };

  const getFieldErrors = () => {
    const { clientTitle, clientFirstName, clientLastName, clientAddress } =
      clientQuote.client;

    if (!hasSubmitted) return {};

    return {
      clientTitle: !clientTitle,
      clientFirstName: !clientFirstName,
      clientLastName: !clientLastName,
      clientAddress: !clientAddress,
      products: clientQuote.products.length === 0,
    };
  };

  const effectiveDate = clientQuote.effectiveDate
    ? parse(clientQuote.effectiveDate, "yyyy-MM-dd", new Date())
    : new Date();

  return (
    <MainDetails
      loading={loadingMe || loadingQuoteDataDates || loadingQuoteData}
      saving={saving || sendingCoc || updatingStatus}
      hasChanges={hasChanges}
      errors={getFieldErrors()}
      quoteReference={clientQuote.quoteReference ?? ""} // [AGENT_INITIALS][YYMMDD]-[QUOTETYPE_ABBRV]-[CLIENT_INITIALS][RANDOM_4_DIGITS]
      quoteStatus={clientQuote.quoteStatus ?? ""}
      isPrivate={clientQuote.client.isPrivate}
      isCoCFeatureEnabled={isCoCFeatureEnabled}
      clientTitle={clientQuote.client.clientTitle}
      clientFirstName={clientQuote.client.clientFirstName}
      clientLastName={clientQuote.client.clientLastName}
      clientAddress={clientQuote.client.clientAddress}
      agentName={me?.name ?? ""}
      quoteDate={effectiveDate}
      acceptedDate={
        clientQuote.acceptedDate
          ? parse(clientQuote.acceptedDate, "yyyy-MM-dd", new Date())
          : null
      }
      products={clientQuote.products}
      onChangeClientValue={handleChangeClientValue}
      onChangeQuoteDateValue={handleChangeQuoteDateValue}
      onNewQuote={handleNewQuote}
      onSaveQuote={handleSaveQuote}
      onChangeQuoteStatus={handleChangeQuoteStatus}
      onAddProduct={handleAddProduct}
      onClickProduct={handleEditProduct}
    />
  );
}

export default QuoteCreate;
