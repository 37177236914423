import { useLocation, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedIn } from "axios-jwt";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "../hooks";
import { selectMe, logout } from "../lib/store/features/user.slice";
import { RootState } from "../lib/types";

function CheckAccess({
  requiredRole,
  // isAuth,
  myRole,
  children,
}: {
  // isAuth: boolean;
  requiredRole?: ("admin" | "agent" | "superadmin")[];
  myRole: string;
  children: JSX.Element;
}) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  if (!isLoggedIn()) {
    // display snackbar using notistack
    enqueueSnackbar("Your session has expired. Please login again.", {
      autoHideDuration: 3000,
      variant: "warning",
    });

    dispatch(logout());
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredRole && !requiredRole.includes(myRole as any)) {
    return <Navigate to="/" replace />;
  }

  return children;
}

const mapStateToProps = (state: RootState) => {
  const me = selectMe(state);
  return {
    myRole: me?.role || "",
  };
};

export default connect(mapStateToProps)(CheckAccess);
