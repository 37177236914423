import UserDialog from "../../../../components/UserDialog";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../../../lib/store/features/dialogs.slice";
import {
  selectedUser,
  setSelectedUserId,
} from "../../../../lib/store/features/users.slice";
import { selectList as selectBrokeragesList } from "../../../../lib/store/features/brokerages.slice";
import { updateUser } from "../../../../lib/store/features/users.slice";
import { enqueue as showNotif } from "../../../../lib/store/features/notifications.slice";
import { User } from "../../../../lib/types";

function EditUser() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("EDIT_USER"));
  const brokerages = useAppSelector(selectBrokeragesList);
  const user = useAppSelector(selectedUser);

  const handleClose = () => {
    dispatch(toggle({ dialog: "EDIT_USER", open: false }));
    setTimeout(() => {
      dispatch(setSelectedUserId(null));
    }, 250);
  };

  const handleSubmit = async (updUser: User) => {
    if (!user) return false;

    const result = await dispatch(
      updateUser({
        name: updUser.name,
        email: updUser.email,
        phone: updUser.phone,
        role: updUser.role,
        brokerage_id: updUser.brokerage_id || null,
        userId: user.id,
      })
    );

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "User updated successfully",
        options: { variant: "success" },
      })
    );

    handleClose();

    return true;
  };

  return (
    <UserDialog
      user={user}
      brokerages={brokerages}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
}

export default EditUser;
