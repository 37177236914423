import React from "react";
import Paper from "@mui/material/Paper";
import isPropsEqual from "react-fast-compare";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { SelectChangeEvent } from "@mui/material/Select";
import { TextFieldProps } from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import CalcComponent from "../../components/Quote/PublicLiabilityCalculations";
import Section from "../forms/Section";
import FormControl, { FormHelperText } from "../forms/FormControl";
import TextField from "../forms/TextInput";
import Select, { MenuItem } from "../forms/SelectInput";
import { useNumberFormatter } from "../../hooks";
import type {
  WorkCategory,
  WorkOccupation,
  ClaimHistoryItem,
  PublicLiabilityLOL,
  PublicLiabilityLOLSelectedValues,
  PublicLiabilityLOLSelectedAmounts,
} from "../../lib/types";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@mui/material";

function ReadOnlyText({
  label,
  value,
  color,
}: {
  label: string;
  value: string | number;
  color?: TextFieldProps["color"];
}) {
  return (
    <Stack direction="column">
      <Typography variant="caption" color={color || "rgba(0,0,0,0.6)"}>
        {label}
      </Typography>
      <Typography variant="body1" color={color || "rgba(0,0,0,0.87)"}>
        {value}
      </Typography>
    </Stack>
  );
}

function ClaimsHistoryAddButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body2" color="GrayText">
        {!disabled && (
          <em>Press the plus button to add a claims history item.</em>
        )}
        {disabled && <em>Canot add any more claims history items.</em>}
      </Typography>
      <Tooltip
        title={disabled ? "" : "Click to add a claims history item"}
        placement="right"
      >
        <IconButton
          disabled={disabled}
          onClick={onClick}
          aria-label="add claims history item"
          color="primary"
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

interface Props {
  // Page props
  color: string;
  subTitle: string;
  title: string;
  isLoadingMain: boolean;

  // Quote Data (categories and occupations)
  isLoadingCategories: boolean;
  isLoadingOccupations: boolean;
  claimsHistory: ClaimHistoryItem[];
  selectedCategory: WorkCategory | null;
  selectedOccupation: WorkOccupation | null;
  categories: Array<WorkCategory>;
  occupations: Array<WorkOccupation>;
  turnover: number;
  publicLiabilityLOL: PublicLiabilityLOL[];
  selectedLOLValues: PublicLiabilityLOLSelectedValues;
  selectedLOLAmounts: PublicLiabilityLOLSelectedAmounts;

  // Quote calculation props
  lolPremiumAmount: number;
  stampDuty: number;

  // Quote form values

  // Event handlers
  onChangeLOLValue: (
    lolName: PublicLiabilityLOL["lolName"],
    value: string
  ) => void;
  onChangeCategory: ({
    categoryId,
  }: {
    categoryId: WorkCategory["id"];
  }) => void;
  onChangeOccupation: ({
    occupationId,
  }: {
    occupationId: WorkOccupation["id"];
  }) => void;
  onChangeTurnoverText: ({ value }: { value: number }) => void;
  onAddClaimsHistoryItem: () => void;
  onDeleteClaimsHistoryItem: (id: ClaimHistoryItem["id"]) => void;
  onChangeClaimHistoryClaimItemText: ({
    id,
    field,
    value,
  }: {
    id: ClaimHistoryItem["id"];
    field: "claim_count" | "claim_amount";
    value: number;
  }) => void;
}

function PublicLiabilityForm({
  // Page props
  color,
  subTitle,
  title,
  isLoadingMain,

  // // Quote Data (categories and occupations)
  isLoadingCategories,
  isLoadingOccupations,
  selectedCategory,
  selectedOccupation,
  turnover,
  claimsHistory,
  categories,
  occupations,
  publicLiabilityLOL,
  selectedLOLValues,
  selectedLOLAmounts,

  // Quote calculation props
  lolPremiumAmount,
  stampDuty,

  // // Event handlers
  onChangeLOLValue,
  onChangeCategory,
  onChangeOccupation,
  onChangeTurnoverText,
  onAddClaimsHistoryItem,
  onDeleteClaimsHistoryItem,
  onChangeClaimHistoryClaimItemText,
}: Props) {
  const moneyFormatter = useNumberFormatter();
  const percentFormatter = useNumberFormatter({
    style: "percent",
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  });
  const decimalFormatter = useNumberFormatter({
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const handleChangeCategory = (event: SelectChangeEvent) => {
    const { value } = event.target;
    onChangeCategory({ categoryId: +value });
  };

  const handleChangeOccupation = (event: SelectChangeEvent) => {
    const { value } = event.target;
    onChangeOccupation({ occupationId: +value });
  };

  const handleChangeLOL =
    (lolName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      onChangeLOLValue(lolName, checked ? "Yes" : "No");
    };

  const handleChangeTurnoverText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    onChangeTurnoverText({ value: +value });
  };

  const handleDeleteClaimsHistoryItem = (id: ClaimHistoryItem["id"]) => () => {
    onDeleteClaimsHistoryItem(id);
  };

  const handleChangeClaimHistoryItemText =
    (id: ClaimHistoryItem["id"], field: "claim_amount" | "claim_count") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChangeClaimHistoryClaimItemText({ id, field, value: +value });
    };

  const uninsurableOccupation = selectedOccupation?.rate === null;

  const inputFields = {
    mainFields: [
      {
        id: "category",
        type: "select",
        props: {
          label: "Category",
          loading: isLoadingCategories,
          disabled: isLoadingCategories,
          value: selectedCategory?.id || "",
          handleChange: handleChangeCategory,
          options: categories.map((category) => ({
            value: category.id,
            label: category.category_name,
          })),
          grid: {
            xs: 12,
          },
        },
      },
      {
        id: "occupations",
        type: "select",
        props: {
          label: "Occupation",
          loading: isLoadingOccupations,
          disabled: isLoadingOccupations,
          value: selectedOccupation?.id || "",
          handleChange: handleChangeOccupation,
          options: occupations.map((occupation) => ({
            value: occupation.id,
            label: occupation.occupation_name,
          })),
          grid: {
            xs: 12,
          },
        },
      },
      {
        id: "turnover",
        type: "input",
        props: {
          label: "Turnover",
          name: "turnover",
          value: turnover,
          onChange: handleChangeTurnoverText,
          disabled:
            uninsurableOccupation ||
            isLoadingMain ||
            isLoadingOccupations ||
            isLoadingCategories,
          grid: {
            xs: 12,
            lg: 9,
          },
        },
      },
      {
        id: "occupation_rate",
        type: "input",
        props: {
          label: "Rate (%)",
          value: selectedOccupation?.rate ? selectedOccupation.rate : "",
          type: "percent",
          readOnly: true,
          grid: {
            xs: 12,
            lg: 3,
            sx: { textAlign: "center" },
          },
        },
      },
    ],
  };

  if (isLoadingMain) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            my={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box mr={2}>
              <Typography component="p" variant="body1">
                <em>Loading data</em>
              </Typography>
            </Box>
            <CircularProgress size={24} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: 120,
          zIndex: 0,
          backgroundImage: `linear-gradient(to top, rgba(255,0,0,0), ${color})`,
        }}
      />

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h1" sx={{ mb: 2, mt: 2, px: 2 }}>
            {title}
          </Typography>
        </Grid>
      </Grid>

      <form id="quoteform" autoComplete="off">
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Section title={`${subTitle} Details`} bottomBorder={false}>
              <Grid container spacing={2}>
                {/**
                 * Main Details
                 **/}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {inputFields.mainFields.map(({ id, type, props }) => {
                      return (
                        <React.Fragment key={id}>
                          <Grid item {...props.grid}>
                            {type === "select" && props.options && (
                              <FormControl sx={{ width: "100%" }}>
                                <FormHelperText>
                                  {`${props.label} `}
                                  {props.loading ? <em>(Loading ...)</em> : ""}
                                </FormHelperText>
                                <Select
                                  variant="outlined"
                                  value={props.value}
                                  onChange={props.handleChange as any}
                                  disabled={props.disabled}
                                >
                                  {props.options.map(
                                    ({ value, label }, count) => (
                                      <MenuItem key={count} value={value}>
                                        {label}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                            {type === "input" &&
                              props.readOnly &&
                              (props.type === "percent" ||
                                props.type === "decimal") && (
                                <>
                                  <ReadOnlyText
                                    label={props.label}
                                    value={
                                      isNaN(+props.value)
                                        ? "0.00%"
                                        : props.type === "percent"
                                        ? percentFormatter.format(
                                            +props.value * 100
                                          )
                                        : decimalFormatter.format(+props.value)
                                    }
                                  />
                                </>
                              )}
                            {type === "input" && !props.readOnly && (
                              <TextField
                                autoComplete="off"
                                name={props.name}
                                variant="outlined"
                                sx={{ width: "100%" }}
                                {...props}
                              />
                            )}
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                    {uninsurableOccupation && (
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          component="p"
                          color="error"
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <em>
                            Occupation Unacceptable - DO NOT WRITE BUSINESS
                          </em>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Section>
          </Grid>

          {/**
           * Claims History
           **/}
          <Grid item xs={12}>
            <Section subsection title="Claims History">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Table aria-label="Claims History Table" size="small">
                    <TableBody>
                      {claimsHistory.map((claim) => (
                        <TableRow
                          key={claim.year}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>Year {claim.year}</TableCell>
                          <TableCell align="right">
                            <TextField
                              autoComplete="off"
                              name={`NoOfClaims${claim.id}`}
                              label="No. of Claims"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              size="small"
                              value={claim.claim_count}
                              onChange={handleChangeClaimHistoryItemText(
                                claim.id,
                                "claim_count"
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              autoComplete="off"
                              name={`ClaimsCost${claim.id}`}
                              label="Claims Cost"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              size="small"
                              value={claim.claim_amount}
                              onChange={handleChangeClaimHistoryItemText(
                                claim.id,
                                "claim_amount"
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={handleDeleteClaimsHistoryItem(claim.id)}
                              aria-label="delete claims history item"
                              color="inherit"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <ClaimsHistoryAddButton
                    onClick={onAddClaimsHistoryItem}
                    disabled={claimsHistory.length >= 3}
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>

          {/**
           * LOL Details
           **/}
          <Grid item xs={12}>
            <Section subsection title="Quotation">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Table aria-label="Quotation Table" size="small">
                    <TableBody>
                      {publicLiabilityLOL.map((lol) => (
                        <TableRow
                          key={lol.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell width="25%">
                            <Typography variant="body1">
                              {lol.lolName}
                            </Typography>
                          </TableCell>
                          <TableCell width="30%">
                            <Typography variant="body1">
                              Min Premium is{" "}
                              {moneyFormatter.format(lol.minPremium)}
                            </Typography>
                          </TableCell>
                          <TableCell width="20%">
                            {/* <FormControl sx={{ width: "100%" }}>
                              <Select
                                size="small"
                                variant="outlined"
                                value={selectedLOLValues[lol.lolName]}
                                onChange={handleChangeLOL(lol.lolName) as any}
                              >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                              </Select>
                            </FormControl> */}
                            <Checkbox
                              checked={selectedLOLValues[lol.lolName] === "Yes"}
                              onChange={handleChangeLOL(lol.lolName) as any}
                            />
                          </TableCell>
                          <TableCell width="25%" align="right">
                            <ReadOnlyText
                              label=""
                              value={moneyFormatter.format(
                                selectedLOLAmounts[lol.lolName]
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Section>
          </Grid>

          {/**
           * Quote Calculations
           **/}
          <Grid item xs={12}>
            <Section subsection title="Quotation Totals (REFER P&L >K250k)">
              <CalcComponent
                lolPremium={lolPremiumAmount}
                stampDuty={stampDuty}
                grandTotal={lolPremiumAmount + stampDuty}
              />
            </Section>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default React.memo(PublicLiabilityForm, isPropsEqual);
