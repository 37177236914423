import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector, useDebounce } from "../../hooks";
import {
  selectLoadingPage,
  selectPage,
  clearPage,
  getQuotesPage,
} from "../../lib/store/features/quotesPagination.slice";
import { wrkCmpApiToReact, pubLiabApiToReact } from "../../lib/helpers";
import { setCurrentQuote } from "../../lib/store/features/quotes.slice";
import { getLastModifiedDates } from "../../lib/store/features/quoteData.slice";
import {
  QuotePageResult,
  QuotePageResultProductPubLiab,
  QuotePageResultProductVehicle,
  QuotePageResultProductWrkCmp,
} from "../../lib/types";

function Search() {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>("");
  const debouncedQuery: string = useDebounce<string>(query, 500);
  const loading = useAppSelector(selectLoadingPage);
  const page = useAppSelector(selectPage);

  // Effect for API call
  useEffect(
    () => {
      if (debouncedQuery) {
        dispatch(getQuotesPage({ page: 1, limit: 10, query: debouncedQuery }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedQuery] // Only call effect if debounced query changes
  );

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setQuery(value);
  };

  const handleSelectQuote = (
    event: React.ChangeEvent<{}>,
    value: QuotePageResult | null
  ) => {
    if (value) {
      const { user, ...quote } = value;
      dispatch(
        setCurrentQuote({
          client: {
            isPrivate: quote.isPrivate,
            clientAddress: quote.clientAddress,
            clientFirstName: quote.clientFirstName,
            clientLastName: quote.clientLastName,
            clientTitle: quote.clientTitle,
          },
          effectiveDate: quote.effectiveDate,
          acceptedDate: quote.acceptedDate,
          id: quote.id,
          products: quote.products.map((product) => {
            if (product.quoteType === "workers_compensation") {
              return {
                data: {
                  ...wrkCmpApiToReact(product as QuotePageResultProductWrkCmp),
                },
                id: product.id,
                quoteType: "workers_compensation",
              };
            } else if (product.quoteType === "public_liability") {
              return {
                data: {
                  ...pubLiabApiToReact(
                    product as QuotePageResultProductPubLiab
                  ),
                },
                id: product.id,
                quoteType: "public_liability",
              };
            } else {
              const {
                vehicleMake,
                vehicleModel,
                vehicleYear,
                vehicleColour,
                vehicleTrim,
                vehicleEngineNumber,
                vehicleChassisNumber,
                vehicleRegistrationNumber,
                discountPercentage,
                sumInsured,
                sumInsuredPremium,
                ...rest
              } = product as QuotePageResultProductVehicle;

              return {
                id: rest.id,
                quoteType: rest.quoteType,
                data: {
                  quoteYears: rest.quoteYears,
                  clientVehicleValues: {
                    vehicleMake,
                    vehicleModel,
                    vehicleYear,
                    vehicleColour,
                    vehicleTrim,
                    vehicleEngineNumber,
                    vehicleChassisNumber,
                    vehicleRegistrationNumber,
                  },
                  calculations: {
                    discountPercentage,
                    sumInsured,
                    sumInsuredPremium,
                  },
                  insuranceValues: { ...rest, sumInsured },
                },
              };
            }
          }),
          quoteReference: quote.quoteReference,
          quoteStatus: quote.quoteStatus,
          selectedProductId: null,
          hasChanges: false,
        })
      );
      dispatch(getLastModifiedDates({ quoteType: "all" }));
    } else {
      dispatch(clearPage());
    }
  };

  return (
    <Autocomplete
      id="quote-search"
      onChange={handleSelectQuote}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        `${option.quoteReference} - ${option.clientFirstName} ${option.clientLastName}`
      }
      noOptionsText="No results found"
      options={page.results}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          fullWidth
          onChange={handleChangeQuery}
          label="Quote Search"
          placeholder="Search by reference or client name"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default Search;
