import { useEffect } from "react";
import BrokeragesTable from "../../components/BrokeragesTable";
import AddBrokerageLogoDialog from "./Dialogs/Logo";
import AddBrokerageDialog from "./Dialogs/Add";
import EditBrokerageDialog from "./Dialogs/Edit";
import EditQuoteTypeAccessDialog from "./Dialogs/EditQuoteTypeAccess";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectList as selectBrokeragesList,
  fetchList as fetchBrokeragesList,
  loadingList as loadingBrokerages,
  deleteLogo,
  setSelectedBrokerageId,
} from "../../lib/store/features/brokerages.slice";
import {
  getQuoteTypes,
  getQuoteFeatures,
} from "../../lib/store/features/quoteData.slice";
import {
  selectLoadingQuoteTypes as loadingQuoteTypes,
  selectLoadingQuoteFeatures as loadingQuoteFeatures,
} from "../../lib/store/features/quoteData.slice";
import { toggle as toggleDialog } from "../../lib/store/features/dialogs.slice";
import { Brokerage } from "../../lib/types";

function BrokeragesSettings() {
  const dispatch = useAppDispatch();
  const brokeragesById = useAppSelector(selectBrokeragesList);
  const isLoadingBrokerages = useAppSelector(loadingBrokerages);
  const isLoadingQuoteTypes = useAppSelector(loadingQuoteTypes);
  const isLoadingQuoteFeatures = useAppSelector(loadingQuoteFeatures);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchBrokeragesList());
      await dispatch(getQuoteTypes());
      await dispatch(getQuoteFeatures());
    };

    init();
  }, [dispatch]);

  const handleClickEdit = (brokerageId: Brokerage["id"]) => {
    dispatch(setSelectedBrokerageId(brokerageId));
    dispatch(toggleDialog({ dialog: "EDIT_BROKERAGE", open: true }));
  };

  const handleClickAdd = () => {
    dispatch(toggleDialog({ dialog: "ADD_BROKERAGE", open: true }));
  };

  const handleClickDeleteLogo = (id: Brokerage["id"]) => {
    dispatch(deleteLogo({ brokerageId: id }));
  };

  const handleClickEditLogo = (brokerageId: number) => {
    dispatch(setSelectedBrokerageId(brokerageId));
    dispatch(toggleDialog({ dialog: "BROKERAGE_LOGO", open: true }));
  };

  const handleClickEditQuoteTypeAccess = (brokerageId: number) => {
    dispatch(setSelectedBrokerageId(brokerageId));
    dispatch(
      toggleDialog({ dialog: "EDIT_BROKERAGE_QUOTE_TYPE_ACCESS", open: true })
    );
  };

  return (
    <>
      <AddBrokerageLogoDialog />
      <EditBrokerageDialog />
      <EditQuoteTypeAccessDialog />
      <AddBrokerageDialog />
      <BrokeragesTable
        brokerages={brokeragesById}
        loading={
          isLoadingBrokerages || isLoadingQuoteTypes || isLoadingQuoteFeatures
        }
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickEditLogo={handleClickEditLogo}
        onClickDeleteLogo={handleClickDeleteLogo}
        onClickEditQuoteTypeAccess={handleClickEditQuoteTypeAccess}
      />
    </>
  );
}

export default BrokeragesSettings;
