import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface Props {
  label: string;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  error: boolean;
  fullWidth: boolean;
  required: boolean;
  helperText: null | string;
  name: string;
  type: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

function PasswordTextField({
  label,
  placeholder,
  value,
  onChange,
  disabled,
  error,
  fullWidth,
  helperText,
  name,
  onBlur,
  required,
  type,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl
      error={error}
      required={required}
      variant="outlined"
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {!!label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <OutlinedInput
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        name={name}
        id={name}
        type={showPassword ? "text" : "password"}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      {!!helperText && (
        <FormHelperText id={`${name}-helper-text`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default PasswordTextField;
