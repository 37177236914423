import React from "react";
import isPropsEqual from "react-fast-compare";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCellComponent, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { useNumberFormatter } from "../../hooks";

class TableCell extends React.Component<TableCellProps> {
  shouldComponentUpdate(nextProps: TableCellProps) {
    const { children: nextChildren } = nextProps; // eslint-disable-line
    const { children: thisChildren } = this.props; // eslint-disable-line

    if (typeof thisChildren === "string") return nextChildren !== thisChildren;
    if (
      typeof thisChildren === "object" &&
      (thisChildren as any).type === "span"
    ) {
      const { props: nextProps } = nextChildren as any;
      const { props: thisProps } = thisChildren as any;
      return nextProps.children !== thisProps.children; // eslint-disable-line
    }

    return true;
  }

  render() {
    return <TableCellComponent {...this.props} />;
  }
}

interface Props {
  lolPremium: number;
  stampDuty: number;
  grandTotal: number;
}

function PublicLiabilityCalculations({
  lolPremium,
  stampDuty,
  grandTotal,
}: Props) {
  const moneyFormatter = useNumberFormatter();

  const calculations = [
    {
      id: "lolPremium",
      name: "Selected LOL Premium",
      value: lolPremium,
    },
    {
      id: "stampDuty",
      name: "Stamp Duty",
      value: stampDuty,
    },
    {
      id: 8,
      name: "Grand Total",
      value: grandTotal,
      isTotal: true,
    },
  ];

  return (
    <TableContainer>
      <Table aria-label="calculations table">
        <TableBody>
          {calculations.map(({ id, ...row }) => (
            <TableRow
              key={id}
              sx={{
                ...((row as any).isTotal && {
                  backgroundColor: "#e2e2e2",
                  borderTop: "2px solid #222",
                }),
              }}
            >
              <TableCell>
                <Box
                  component="span"
                  sx={{
                    ...((row as any).indent && {
                      display: "block",
                      pl: 2,
                    }),
                    ...((row as any).isTotal && {
                      fontWeight: "bold",
                      fontSize: "1.1em",
                    }),
                  }}
                >
                  {row.name}
                </Box>
              </TableCell>
              <TableCell
                align="right"
                sx={
                  row.isTotal
                    ? {
                        fontWeight: "bold",
                        fontSize: "1.1em",
                      }
                    : {
                        fontWeight: "normal",
                      }
                }
              >
                {moneyFormatter.format(row.value)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default React.memo(PublicLiabilityCalculations, isPropsEqual);
