import { request } from "../index";
import { apiUrls } from "../api-urls";
import { Brokerage, Role, User } from "../../types";

const urls = apiUrls.users;

/*
 * Users API
 ** */

export function fetchList() {
  return request({
    url: urls.list,
    method: "GET",
  });
}

export function toggleUserStatus({
  userId,
  isActive,
}: {
  userId: number;
  isActive: boolean;
}) {
  const url = urls.byIdStatus.replace(/:USER_ID/, `${userId}`);

  return request({ method: "PUT", url, data: { isActive } });
}

export function addUser({
  name,
  email,
  phone,
  role,
  password,
  isActive,
  brokerage_id,
}: {
  name: string;
  email: string;
  phone: string;
  role: Role;
  password: string;
  isActive: boolean;
  brokerage_id: null | Brokerage["id"];
}) {
  return request({
    method: "POST",
    url: urls.list,
    data: {
      name,
      email,
      phone,
      role,
      password,
      isActive: isActive ? 1 : 0,
      brokerage_id,
    },
  });
}

export function changeUserPassword({
  newPassword,
  userId,
}: {
  newPassword: string;
  userId: User["id"];
}) {
  return request({
    method: "PUT",
    url: urls.changePassword.replace(/:USER_ID/, `${userId}`),
    data: {
      newPassword,
    },
  });
}

export function updateUser({
  name,
  email,
  phone,
  role,
  brokerage_id,
  userId,
}: {
  name: string;
  email: string;
  phone: string;
  role: Role;
  brokerage_id: null | Brokerage["id"];
  userId: User["id"];
}) {
  return request({
    method: "PUT",
    url: urls.byId.replace(/:USER_ID/, `${userId}`),
    data: {
      name,
      email,
      phone,
      role,
      brokerage_id,
    },
  });
}

export function deleteUser({ userId }: { userId: number }) {
  return request({
    method: "DELETE",
    url: urls.byId.replace(/:USER_ID/, `${userId}`),
  });
}
