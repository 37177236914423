import { useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SaveIcon from "@mui/icons-material/Save";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "../Select";
import { addValidationSchema, editValidationSchema } from "./validationSchema";
import { Brokerage, User } from "../../lib/types";

interface Props {
  open: boolean;
  user?: User;
  brokerages: Brokerage[];
  onClose: () => void;
  onSubmit: (user: User) => Promise<boolean>;
}

type SelectOption = {
  label: string;
  value: string | number | null;
};

export default function UserDialog({
  open,
  user,
  brokerages,
  onClose,
  onSubmit,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const fields: { [key: string]: string | boolean | number } = {
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    ...(user ? {} : { password: "" }),
    role: user?.role || "",
    brokerage_id: user?.brokerage_id || "",
  };

  const inputProps: { [key: string]: any } = {
    name: {
      label: "Full Name",
      placeholder: "Enter the full name",
      type: "text",
      Component: TextField,
      required: true,
    },
    email: {
      label: "Email",
      placeholder: "Enter the email (used for login)",
      type: "email",
      Component: TextField,
      required: true,
    },
    phone: {
      label: "Phone",
      placeholder: "Enter the phone number",
      type: "text",
      Component: TextField,
      required: false,
    },
    password: {
      label: "Password",
      placeholder: "Enter the password (used for login)",
      type: "password",
      Component: TextField,
      required: true,
    },
    role: {
      id: "role",
      label: "Role",
      options: [
        { label: "Agent", value: "agent" },
        { label: "Admin", value: "admin" },
        { label: "Superadmin", value: "superadmin" },
      ],
      Component: Select,
      type: "select",
      required: true,
    },
    brokerage_id: {
      id: "brokerage_id",
      label: "Brokerage",
      options: [{ label: "None", value: null } as SelectOption].concat(
        brokerages.map((b) => ({ label: b.name, value: b.id }))
      ),
      Component: Select,
      type: "select",
      required: false,
    },
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Formik
        initialValues={{ ...fields }}
        enableReinitialize
        validationSchema={user ? editValidationSchema : addValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setIsLoading(true);
          const result = await onSubmit(values as any);
          setSubmitting(false);
          setIsLoading(false);
          if (result) resetForm();
        }}
      >
        {({ errors }) => {
          return (
            <Form noValidate>
              <DialogTitle>
                {user ? "Edit User Details" : "Add a New User"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Fill in the below form to {user ? "edit the" : "add a new"}{" "}
                  user.
                </DialogContentText>

                {Object.keys(fields).map((fieldName) => {
                  const { Component, type, name, ...props } =
                    inputProps[fieldName];

                  return (
                    <Field type={type} name={fieldName} key={fieldName}>
                      {({ field, meta, form }: FieldProps) => {
                        return (
                          <Component
                            {...field}
                            {...props}
                            type={type}
                            margin="normal"
                            fullWidth
                            error={meta.touched && !!meta.error}
                            helperText={
                              meta.touched && !!meta.error ? meta.error : null
                            }
                          />
                        );
                      }}
                    </Field>
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton
                  loading={isLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  variant="contained"
                  type="submit"
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
