import { Text, View } from "@react-pdf/renderer";
import { useNumberFormatter } from "../../../../../hooks";
import type {
  PDFClientDetails,
  PDFQuoteWrkCmpDetails,
} from "../../../../../lib/types";
import styles from "../styles";

interface Props {
  clientDetails: PDFClientDetails;
  quoteDetails: {
    title: PDFQuoteWrkCmpDetails["title"];
    category: PDFQuoteWrkCmpDetails["category"];
    occupation: PDFQuoteWrkCmpDetails["occupation"];
    wageRoll: PDFQuoteWrkCmpDetails["wageRoll"];
    rate: PDFQuoteWrkCmpDetails["rate"];
    claimsHistory: PDFQuoteWrkCmpDetails["claimsHistory"];
    hausKraiOption: PDFQuoteWrkCmpDetails["hausKraiOption"];
    hausKraiAmount: PDFQuoteWrkCmpDetails["hausKraiAmount"];
    wethaOption: PDFQuoteWrkCmpDetails["wethaOption"];
    wethaAmount: PDFQuoteWrkCmpDetails["wethaAmount"];
    bekimPeiOption: PDFQuoteWrkCmpDetails["bekimPeiOption"];
    bekimPeiAmount: PDFQuoteWrkCmpDetails["bekimPeiAmount"];
    commonLawOption: PDFQuoteWrkCmpDetails["commonLawOption"];
    commonLawAmount: PDFQuoteWrkCmpDetails["commonLawAmount"];
  };
}

function WrkCmpPolicy({ clientDetails, quoteDetails }: Props) {
  const {
    title: quoteTitle,
    category,
    occupation,
    wageRoll,
    // rate,
    claimsHistory,
    hausKraiOption,
    hausKraiAmount,
    wethaOption,
    wethaAmount,
    bekimPeiOption,
    bekimPeiAmount,
    commonLawOption,
    commonLawAmount,
  } = quoteDetails;

  const moneyFormatter = useNumberFormatter();

  const {
    isPrivate,
    clientTitle,
    clientFirstName,
    clientLastName,
    clientAddress,
  } = clientDetails;

  const clientName = isPrivate
    ? `${clientTitle} ${clientFirstName} ${clientLastName}`.trim()
    : `${clientFirstName}`.trim();

  return (
    <>
      {/* Client & Vehicle Details */}
      <View style={[styles.sectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textWhite]}>
          Client Details
        </Text>
      </View>
      <View style={[styles.flexRow, styles.sectionPadding]}>
        <View style={styles.width50}>
          <View style={styles.marginBottom8}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>Name</Text>
            <Text style={styles.textNormal}>{clientName}</Text>
          </View>
          <View>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Address
            </Text>
            <Text style={styles.textNormal}>{clientAddress}</Text>
          </View>
        </View>
      </View>
      <View style={styles.marginBottom20} />

      {/* Policy Details */}
      <View style={[styles.sectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textWhite]}>
          {quoteTitle} Policy Details
        </Text>
      </View>
      <View
        style={[styles.flexColumn, styles.sectionPadding, styles.marginBottom8]}
      >
        <View style={styles.marginBottom8}>
          <Text style={[styles.itemHeading, styles.textDarkGrey]}>
            Category
          </Text>
          <Text style={styles.textNormal}>{category}</Text>
        </View>
        <View style={styles.marginBottom8}>
          <Text style={[styles.itemHeading, styles.textDarkGrey]}>
            Occupation
          </Text>
          <Text style={styles.textNormal}>{occupation}</Text>
        </View>

        <View style={styles.flexRow}>
          <View style={[styles.marginBottom8, styles.width50]}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>
              Wageroll
            </Text>
            <Text style={styles.textNormal}>
              {moneyFormatter.format(wageRoll)}
            </Text>
          </View>
          {/*
          TPAL requested to to show rate
          <View style={[styles.marginBottom8, styles.width50]}>
            <Text style={[styles.itemHeading, styles.textDarkGrey]}>Rate</Text>
            <Text style={styles.textNormal}>{(rate * 100).toFixed(2)}%</Text>
          </View> */}
        </View>
      </View>
      <View style={styles.marginBottom12} />

      {/* Claims History */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>Claims History</Text>
        <View style={styles.subSectionHeaderLine} />
      </View>
      {claimsHistory.map((claim, index) => (
        <View
          key={index}
          style={[
            styles.marginBottom12,
            styles.sectionPadding,
            styles.flexRow,
            styles.borderBottom,
          ]}
        >
          <View style={[styles.flexColumn, { width: "15%" }]}>
            <View style={styles.marginTop14}>
              <Text style={styles.textNormal}>Year {claim.year}</Text>
            </View>
          </View>
          <View style={[styles.flexColumn, { width: "25%" }]}>
            <View>
              <Text style={[styles.itemHeading, styles.textDarkGrey]}>
                No. of Claims
              </Text>
            </View>
            <View>
              <Text style={styles.textNormal}>{claim.claim_count}</Text>
            </View>
          </View>
          <View style={[styles.flexColumn, { width: "60%" }]}>
            <View>
              <Text style={[styles.itemHeading, styles.textDarkGrey]}>
                Claim Amount
              </Text>
            </View>
            <View>
              <Text style={styles.textNormal}>
                {moneyFormatter.format(claim.claim_amount)}
              </Text>
            </View>
          </View>
        </View>
      ))}
      {claimsHistory.length === 0 && (
        <View
          style={[
            styles.marginBottom12,
            styles.sectionPadding,
            styles.alignCenter,
          ]}
        >
          <Text style={styles.textNormal}>- No Claims History -</Text>
        </View>
      )}

      {/* Optional Extras */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>
          Optional Extras
        </Text>
        <View style={styles.subSectionHeaderLine} />
      </View>
      {/* Haus Krai Optional Extra */}
      <View
        style={[styles.flexRow, styles.marginBottom8, styles.sectionPadding]}
      >
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}></Text>
        </View>
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}>Option</Text>
        </View>
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}>Amount</Text>
        </View>
      </View>

      {/* Haus Krai Optional Extra */}
      <View
        style={[styles.flexRow, styles.marginBottom8, styles.sectionPadding]}
      >
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}>
            Haus Krai
          </Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>
            {typeof hausKraiOption === "number"
              ? moneyFormatter.format(hausKraiOption)
              : hausKraiOption}
          </Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>
            {moneyFormatter.format(hausKraiAmount)}
          </Text>
        </View>
      </View>

      {/* WETHA Optional Extra */}
      <View
        style={[styles.flexRow, styles.marginBottom8, styles.sectionPadding]}
      >
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}>WETHA</Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>{wethaOption}</Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>
            {moneyFormatter.format(wethaAmount)}
          </Text>
        </View>
      </View>

      {/* BEKIM PEI Optional Extra */}
      <View
        style={[styles.flexRow, styles.marginBottom8, styles.sectionPadding]}
      >
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}>
            BEKIM PEI
          </Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>{bekimPeiOption}</Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>
            {moneyFormatter.format(bekimPeiAmount)}
          </Text>
        </View>
      </View>

      {/* Common Law Optional Extra */}
      <View
        style={[styles.flexRow, styles.marginBottom8, styles.sectionPadding]}
      >
        <View style={styles.width30}>
          <Text style={[styles.textDarkGrey, styles.textNormal]}>
            Common Law
          </Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>{commonLawOption}</Text>
        </View>
        <View style={styles.width30}>
          <Text style={styles.textNormal}>
            {moneyFormatter.format(commonLawAmount)}
          </Text>
        </View>
      </View>
    </>
  );
}

export default WrkCmpPolicy;
