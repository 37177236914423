import { PDFDownloadLink } from "@react-pdf/renderer";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import { useAppSelector } from "../../../hooks";
import {
  selectClientQuote,
  selectHasQuoteChanges,
} from "../../../lib/store/features/quotes.slice";
import PDFCoCDocument from "./PDFCertificateOfCurrencyDocument";
import { VEHICLE_QUOTE_TYPES } from "../../../lib/constants";
import "./styles.css";
import { usePdfCoCDetails } from "../../../hooks/usePdfCoCDetails";

interface DisabledProps {
  disabled: boolean;
}

const PDFLink = ({ disabled }: DisabledProps) => {
  const { quoteDetails, agentDetails, clientDetails } = usePdfCoCDetails();

  return (
    <PDFDownloadLink
      document={
        <PDFCoCDocument
          quoteDetails={quoteDetails}
          agentDetails={agentDetails}
          clientDetails={clientDetails}
        />
      }
      fileName={quoteDetails.fileName}
    >
      {({ blob, url, loading, error }) => (
        <Button
          variant="contained"
          color={loading ? "warning" : "success"}
          startIcon={<PDFIcon />}
          component="span"
          fullWidth
          disabled={disabled}
          style={{ textDecoration: "none !important" }}
        >
          {loading ? "..." : "CoC"}
        </Button>
      )}
    </PDFDownloadLink>
  );
};

function PDFLinkButton({ disabled }: DisabledProps) {
  const clientQuote = useAppSelector(selectClientQuote);
  const hasChanges = useAppSelector(selectHasQuoteChanges);

  // Certificate of Currency only applies to Motor Vehicle products (imported from VEHICLE_QUOTE_TYPES)
  // iterate over the products and check if any of them are valid
  const hasValidProducts = clientQuote.products.some((product) =>
    VEHICLE_QUOTE_TYPES.includes(product.quoteType)
  );

  const canGenerateQuote =
    !!clientQuote.id &&
    !hasChanges &&
    hasValidProducts &&
    clientQuote.quoteStatus === "accepted";

  function handleFakeClick() {
    console.log(
      "This is a fake button that fouces the PDFLink button to re-render when a chnage is made to the quote."
    );
  }

  return (
    <Tooltip
      title={
        canGenerateQuote ? "Generate the Certificate of Currency PDF" : null
      }
      placement="top"
    >
      <span>
        {!canGenerateQuote && (
          <Button
            variant="contained"
            color="success"
            startIcon={<PDFIcon />}
            disabled={true}
            onClick={handleFakeClick}
            fullWidth
          >
            CoC
          </Button>
        )}
        {canGenerateQuote && <PDFLink disabled={disabled} />}
      </span>
    </Tooltip>
  );
}

export default PDFLinkButton;
