import React from "react";
import Link from "@mui/material/Link";
import Typography, { TypographyProps } from "@mui/material/Typography";

function Copyright(props: TypographyProps) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      {" - "}
      <Link
        color="inherit"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.transpacific.com.sb"
      >
        Trans Pacific Assurance (SI)
      </Link>
      {"."}
    </Typography>
  );
}

export default Copyright;
