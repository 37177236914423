import { AxiosError } from "axios";
import { setAuthTokens, clearAuthTokens } from "axios-jwt";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  User as UserType,
  API as ApiType,
  ApiLoginResponse,
  ApiMeResponse,
  ApiUpdateResponse,
} from "../../types";
import * as authApi from "../../api/handlers/auth";
import { handleError } from "../../helpers";

interface UserState {
  me: UserType | null;
  api: ApiType;
}

const initialState: UserState = {
  me: null,
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const login = createAsyncThunk(
  "user/login",
  async (
    { email, password }: { email: string; password: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).user;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await authApi.login(email, password)) as any;
      return response.data as ApiLoginResponse;
    } catch (error: any) {
      if (
        (error as AxiosError).isAxiosError &&
        (error as AxiosError).response?.status === 401
      ) {
        return rejectWithValue("Incorrect username or password");
      }

      return rejectWithValue(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (
    {
      currentPassword,
      newPassword,
    }: { currentPassword: string; newPassword: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).user;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await authApi.updatePassword({
        currentPassword,
        newPassword,
      })) as any;
      return response.data as ApiUpdateResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (
    { name, email, phone }: { name: string; email: string; phone: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).user;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await authApi.updateProfile({
        name,
        email,
        phone,
      })) as any;
      return response.data as ApiUpdateResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getMe = createAsyncThunk(
  "user/getMe",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { api } = (getState() as RootState).user;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await authApi.me()) as any;
      return response.data as ApiMeResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.me = null;

      // 5. Clear the auth tokens from localstorage
      clearAuthTokens();
    },
  },
  extraReducers: (builder) => {
    /* Login */
    builder.addCase(login.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        const { auth, me } = payload;
        state.me = me;

        // save tokens to storage
        setAuthTokens({
          accessToken: auth.jwt,
          refreshToken: auth.refresh_token,
        });
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Get Me */
    builder.addCase(getMe.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.me = payload;
      }
    });
    builder.addCase(getMe.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Update Profile */
    builder.addCase(updateProfile.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload, meta }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload && state.me) {
        state.me.email = meta.arg.email;
        state.me.name = meta.arg.name;
        state.me.phone = meta.arg.phone;
      }
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Update Password */
    builder.addCase(updatePassword.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });
  },
});

// Actions
export const { logout } = userSlice.actions;

// Selectors
const selector = (state: RootState) => state.user;
export const selectMe = createSelector(selector, (user) => user.me);
export const makeSelectIsCoCFeatureEnabled = () =>
  createSelector(
    selector,
    (user) =>
      !!user.me?.brokerage?.quote_features_access?.find(
        (f) => f.feature_name === "certificate_of_currency"
      )
  );
export const selectLoading = createSelector(
  selector,
  (user) => user.api.loading === "pending"
);

export default userSlice.reducer;
