import { v4 as uuidv4 } from "uuid";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import {
  Notification as NotificationType,
  NotificationAction as NotificationActionType,
  RootState,
} from "../../types";

type NotificationsState = NotificationType[];

const initialState: NotificationsState = [];

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    enqueue: (state, action: PayloadAction<NotificationActionType>) => {
      const key = action.payload?.key ?? uuidv4();
      state.push({
        ...action.payload,
        key: key || uuidv4(),
      });
    },
    remove(state, action: PayloadAction<NotificationType["key"]>) {
      const key = action.payload;
      return state.filter((notification) => notification.key !== key);
    },
    close(state, action: PayloadAction<NotificationType["key"]>) {
      const key = action.payload;
      const dismissAll = !key;

      return state.map((notification) =>
        dismissAll || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
  },
});

// Actions
export const { enqueue, remove, close } = notificationsSlice.actions;

// Selectors
export const selectNotifications = createSelector(
  (state: RootState) => state.notifications,
  (notifications) => notifications
);

export default notificationsSlice.reducer;
