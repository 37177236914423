import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../types";

interface DashboardState {
  open: boolean;
}

const initialState: DashboardState = {
  open: true,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    toggleNavOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

// Actions
export const { toggleNavOpen } = dashboardSlice.actions;

// Selectors
const selectDashboard = (state: RootState) => state.dashboard;
export const selectDashBoardOpen = createSelector(
  selectDashboard,
  (dashboard) => dashboard.open
);

export default dashboardSlice.reducer;
