import { useAppDispatch, useAppSelector } from "../../hooks";
import Dialog from "../../components/Profile/EditProfileDialog";
import {
  toggle as toggleDialog,
  selectDialogOpen,
} from "../../lib/store/features/dialogs.slice";
import { selectMe, updateProfile } from "../../lib/store/features/user.slice";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";

function EditProfileDialog() {
  const dispatch = useAppDispatch();
  const me = useAppSelector(selectMe);
  const isOpen = useAppSelector(selectDialogOpen("EDIT_MY_PROFILE"));

  const handleClose = (_: any, reason?: "escapeKeyDown" | "backdropClick") => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") return;

    dispatch(toggleDialog({ dialog: "EDIT_MY_PROFILE", open: false }));
  };

  const handleSubmit = async ({
    name,
    email,
    phone,
  }: {
    name: string;
    email: string;
    phone: string;
  }): Promise<boolean> => {
    try {
      const respOne = await dispatch(updateProfile({ name, email, phone }));

      if ((respOne as any).error) {
        throw new Error(
          (respOne as any).payload?.status === 409
            ? "Email already exists. Please use another email."
            : "There was a problem updating your profile"
        );
      }

      handleClose(null);

      dispatch(
        showNotif({
          message: "Profile updated",
          options: { variant: "success" },
        })
      );

      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(
          showNotif({
            message: error.message,
            options: { variant: "error" },
          })
        );
      }
      return false;
    }
  };

  return (
    <Dialog
      open={isOpen}
      name={me?.name ?? ""}
      email={me?.email ?? ""}
      phone={me?.phone ?? ""}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
}

export default EditProfileDialog;
