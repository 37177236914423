import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import SettingsForm from "../../components/QuoteSettingsForm";
import {
  fetchAppSettings as fetchSettings,
  updateAppSettings as updateSettings,
  selectAppSettings as selectSettings,
  updateValue,
  selectHasChanges,
} from "../../lib/store/features/settings-app.slice";
import { enqueue } from "../../lib/store/features/notifications.slice";
import { AppSettings as SettingType } from "../../lib/types";

function AppSettings() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);
  const hasChanges = useAppSelector(selectHasChanges);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleSubmit = async () => {
    const response = await dispatch(updateSettings());

    if (response.meta.requestStatus !== "fulfilled") {
      dispatch(
        enqueue({
          message: "Error updating email settings",
          options: { variant: "error" },
        })
      );
      return false;
    }

    dispatch(
      enqueue({
        message: "Quote Email Settings updated",
        options: { variant: "success" },
      })
    );

    return true;
  };

  const handleChange = (
    key: keyof SettingType["quote_settings"],
    value: string
  ) => {
    dispatch(updateValue({ key, value }));
  };

  return (
    <SettingsForm
      settings={settings["quote_settings"]}
      hasChanges={hasChanges}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
}

export default AppSettings;
