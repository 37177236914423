import { PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

interface Props extends PropsWithChildren {
  title?: string;
  subsection?: boolean;
  bottomBorder?: boolean;
}

function Section({
  title,
  children,
  subsection = false,
  bottomBorder = true,
}: Props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(!subsection && {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          borderBottom: bottomBorder
            ? `1px solid ${theme.palette.primary.main}`
            : "none",
          pb: 2,
        }),
      }}
    >
      {title && (
        <Box
          component="span"
          sx={{
            display: "block",
            marginBottom: theme.spacing(1),
            padding: theme.spacing(0.5, 1),
            ...(!subsection && {
              borderRadius: 1,
              backgroundColor: theme.palette.primary.dark,
              color: "#fff",
            }),
            ...(subsection && {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              borderBottom: `1px solid ${theme.palette.primary.main}`,
            }),
          }}
        >
          {title}
        </Box>
      )}
      {children}
    </Box>
  );
}

export default Section;
