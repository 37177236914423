import ProductFormDialog from "./QuoteFormDialog";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../lib/store/features/dialogs.slice";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";
import {
  updateProduct,
  removeProduct,
  selectSelectedProduct,
  setSelectedProductId,
  selectClientQuote,
} from "../../lib/store/features/quotes.slice";
import type { QuoteFormState, WrkCmpQuoteFormState } from "../../lib/types";

function QuoteEditDialog() {
  const selectedProduct = useAppSelector(selectSelectedProduct);
  const clientQuote = useAppSelector(selectClientQuote);

  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("EDIT_QUOTE"));

  const handleClose = () => {
    dispatch(toggle({ dialog: "EDIT_QUOTE", open: false }));
    setTimeout(() => {
      dispatch(setSelectedProductId(null));
    }, 100);
  };

  const handleSaveProduct = async (
    data: QuoteFormState | WrkCmpQuoteFormState
  ) => {
    if (!selectedProduct) return false;

    dispatch(
      updateProduct({
        id: selectedProduct.id,
        quoteType: selectedProduct.quoteType,
        data,
      })
    );

    dispatch(
      showNotif({
        message: "Product updated",
        options: { variant: "success" },
      })
    );

    handleClose();
    return true;
  };

  const handleRemoveProduct = async () => {
    if (!selectedProduct) return false;

    dispatch(removeProduct({ id: selectedProduct.id }));

    dispatch(
      showNotif({
        message: "Product removed",
        options: { variant: "success" },
      })
    );

    handleClose();
    return true;
  };

  if (!selectedProduct) return null;

  return (
    <ProductFormDialog
      open={open}
      quoteType={selectedProduct.quoteType}
      quoteStatus={clientQuote.quoteStatus}
      selectedProductData={selectedProduct.data}
      onClose={handleClose}
      onRemove={handleRemoveProduct}
      onSubmit={handleSaveProduct}
    />
  );
}

export default QuoteEditDialog;
