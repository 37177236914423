import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import React, { useState, useEffect, PropsWithChildren } from "react";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextFieldProps } from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import TableCellComponent from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import TextInput from "../forms/TextInput";
import Section from "../forms/Section";
import Table from "../QuoteDataTableTemplate";
import {
  ClaimsFreeDiscount,
  DiscountsIncreasedExcess,
  OptionalExtra,
  SumInsuredPremiumRate,
  StandardExcess,
  QuoteDataFormValues,
} from "../../lib/types";

// TODO: @alex: I commented out the optional extras for the quote, because it was too hard to implement. To do this properly, you have to allow the user to create optional extras, and then allow them to toggle each on/off

class TableCell extends React.Component<PropsWithChildren, any> {
  shouldComponentUpdate(nextProps: PropsWithChildren) {
    const { children: nextChildren } = nextProps; // eslint-disable-line
    const { children: thisChildren } = this.props; // eslint-disable-line

    if ((nextChildren as any)?.props && (thisChildren as any)?.props) {
      const { props: nextChildProps } = nextChildren as any;
      const { props: thisChildProps } = thisChildren as any;
      return nextChildProps.value !== thisChildProps.value;
    }

    return nextChildren !== thisChildren;
  }

  render() {
    return <TableCellComponent {...this.props} />;
  }
}

interface QuoteDataProps {
  data: any;
  title: string;
  color: string;
  loading: boolean;
  saving: boolean;
  onSave: (data: QuoteDataState["formValues"]) => void;
  lastModified: null | Date;
}

interface QuoteDataState {
  formValues: QuoteDataFormValues;
}

function QuoteData({
  data,
  title,
  color,
  loading,
  saving,
  onSave,
  lastModified,
}: QuoteDataProps) {
  const [values, setValues] = useState<QuoteDataState>({
    formValues: {
      claimsFreeDiscounts: [],
      discountsIncreasedExcess: [],
      optionalExtras: [],
      sumInsuredPremiumRates: [],
      standardExcess: [],
      minimumPremium: 0,
    },
  });

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setValues((vals) => {
        return {
          ...vals,
          formValues: {
            claimsFreeDiscounts: [...data.claimsFreeDiscounts],
            discountsIncreasedExcess: [...data.discountsIncreasedExcess],
            optionalExtras: data.optionalExtras.map(
              (oe: OptionalExtra) => oe.title
            ),
            sumInsuredPremiumRates: [...data.sumInsuredPremiumRates],
            standardExcess: [...data.standardExcess],
            minimumPremium: data.minimumPremium.premium,
          },
        };
      });
    }
  }, [data, setValues]);

  const {
    claimsFreeDiscounts,
    discountsIncreasedExcess,
    minimumPremium,
    standardExcess,
    sumInsuredPremiumRates,
  } = values.formValues;

  const handleInputChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValues((vals) => ({
        ...vals,
        formValues: {
          ...vals.formValues,
          [field]: parseFloat(value),
        },
      }));
    };

  // const handleOptionalExtraChange = extra => event => {
  //   const { checked } = event.target

  //   setValues(vals => ({
  //     ...vals,
  //     formValues: {
  //       ...vals.formValues,
  //       optionalExtras: checked
  //         ? [...vals.formValues.optionalExtras, extra]
  //         : vals.formValues.optionalExtras.filter(oe => oe !== extra)
  //     }
  //   }))
  // }
  const handleTableInputChange =
    (tableName: any, rowId: number, field: string, isFloat = true) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      let _value: string | number = value;

      if (String(value).length !== 0) {
        _value = isFloat ? parseFloat(value) : parseInt(value, 10);
      }

      setValues((vals) => ({
        ...vals,
        formValues: {
          ...vals.formValues,
          [tableName]: (vals.formValues as any)[tableName].map((val: any) => {
            if (val.id === rowId) {
              return { ...val, [field]: _value };
            }
            return { ...val };
          }),
        },
      }));
    };

  const handleSaveData = () => {
    onSave(values.formValues);
  };

  const defaultTextFieldProps = {
    autoComplete: "off",
    variant: "outlined" as TextFieldProps["variant"],
    type: "number",
    sx: {
      width: "100%",
    },
  };

  const claimsFreeDiscountsTableRenderer = (rowData: {
    id: ClaimsFreeDiscount["id"];
    criteria: ClaimsFreeDiscount["criteria"];
    discountPercentage: ClaimsFreeDiscount["discountPercentage"];
  }) => {
    const { id, criteria, discountPercentage } = rowData;
    return (
      <>
        <TableCell>{criteria}</TableCell>
        <TableCell>
          <TextInput
            name="discountPercentage"
            label="Discount %"
            value={discountPercentage}
            onChange={handleTableInputChange(
              "claimsFreeDiscounts",
              id,
              "discountPercentage",
              true
            )}
            {...defaultTextFieldProps}
          />
        </TableCell>
      </>
    );
  };

  const discountsIncreasedExcessTableRenderer = (
    rowData: DiscountsIncreasedExcess
  ) => {
    const { id, increase, discountPercentage } = rowData;
    return (
      <>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "increase",
              label: "increase",
              value: increase,
              onChange: handleTableInputChange(
                "discountsIncreasedExcess",
                id,
                "increase",
                false
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "discountPercentage",
              label: "Discount %",
              value: discountPercentage,
              onChange: handleTableInputChange(
                "discountsIncreasedExcess",
                id,
                "discountPercentage",
                true
              ),
            }}
          />
        </TableCell>
      </>
    );
  };

  // const optionalExtrasTableRenderer = rowData => {
  //   const { option, discountPercentage } = rowData
  //   return (
  //     <>
  //       <TableCell>{option}</TableCell>
  //       <TableCell>
  //         {discountPercentage}
  //       </TableCell>
  //     </>
  //   )
  // }

  const standardExcessTableRenderer = (rowData: StandardExcess) => {
    const { id, minAmount, maxAmount, excess, excessType } = rowData;

    return (
      <>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "bandMin",
              label: "Min",
              value: minAmount,
              onChange: handleTableInputChange(
                "standardExcess",
                id,
                "minAmount",
                false
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "bandMax",
              label: "Max",
              value: maxAmount,
              onChange: handleTableInputChange(
                "standardExcess",
                id,
                "maxAmount",
                false
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "excess",
              label: `Excess (${excessType === "percentage" ? "%" : "Amount"})`,
              value: excess,
              onChange: handleTableInputChange(
                "standardExcess",
                id,
                "excess",
                excessType === "percentage"
              ),
            }}
          />
        </TableCell>
      </>
    );
  };

  const sumInsuredPremiumRatesTableRenderer = (
    rowData: SumInsuredPremiumRate
  ) => {
    const { id, band, minAmount, maxAmount, rate } = rowData;

    return (
      <>
        <TableCell>{band}</TableCell>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "bandMin",
              label: "Band Min",
              value: String(minAmount),
              onChange: handleTableInputChange(
                "sumInsuredPremiumRates",
                id,
                "minAmount",
                false
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "bandMax",
              label: "Band Max",
              value: String(maxAmount),
              onChange: handleTableInputChange(
                "sumInsuredPremiumRates",
                id,
                "maxAmount",
                false
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <TextInput
            {...{
              ...defaultTextFieldProps,
              name: "rate",
              label: "Band Rate (%)",
              value: String(rate),
              onChange: handleTableInputChange(
                "sumInsuredPremiumRates",
                id,
                "rate",
                true
              ),
            }}
          />
        </TableCell>
      </>
    );
  };

  const hasData = {
    sumInsuredPremiumRates: !!sumInsuredPremiumRates,
    standardExcess: !!standardExcess,
    claimsFreeDiscounts: !!claimsFreeDiscounts,
    discountsIncreasedExcess: !!discountsIncreasedExcess,
  };

  const tableKeyExtractors = {
    sumInsuredPremiumRates: (row: SumInsuredPremiumRate) => row.id,
    standardExcess: (row: StandardExcess) => row.id,
    claimsFreeDiscounts: (row: ClaimsFreeDiscount) => row.id,
    discountsIncreasedExcess: (row: DiscountsIncreasedExcess) => row.id,
    optionalExtras: (row: OptionalExtra) => row.id,
  };

  const fields = {
    minimumPremium: {
      ...defaultTextFieldProps,
      label: "Minimum Premium",
      required: true,
      value: minimumPremium,
      onChange: handleInputChange("minimumPremium"),
    },
  };

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: 120,
            backgroundImage: `linear-gradient(to top, rgba(255,0,0,0), ${color})`,
          }}
        />
        {lastModified && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 20,
            }}
          >
            <Typography
              component="span"
              variant="body2"
              style={{
                fontSize: "0.65em",
                display: "inline-block",
                marginTop: "0.3em",
                fontFamily: "monospace",
              }}
            >
              Last Modified: {format(lastModified, "do LLL yyyy @ h:mmaaa")}
            </Typography>
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box style={{ flexGrow: 1 }}>
                <Typography component="div">
                  <Box
                    textAlign="center"
                    m={1}
                    style={{
                      fontSize: "1.3em",
                      color: "#000000",
                      // backgroundColor: color,
                    }}
                  >
                    {title}
                  </Box>
                </Typography>
              </Box>
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <LoadingButton
                  loadingPosition="end"
                  variant="contained"
                  size="large"
                  endIcon={<SaveIcon />}
                  onClick={handleSaveData}
                  loading={saving}
                  disabled={loading}
                >
                  Save Quote Data
                </LoadingButton>
              </Box>
            </Box>
          </Grid>

          {loading && (
            <Grid item xs={12}>
              <Box
                my={2}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Box mr={2}>
                  <Typography component="p" variant="body1">
                    <em>Loading data</em>
                  </Typography>
                </Box>
                <CircularProgress size={24} />
              </Box>
            </Grid>
          )}

          {!loading && (
            <Grid item xs={12}>
              <form
                id="quotedataform"
                style={{ width: "100%" }}
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Section subsection title="Minimum Premium Amount">
                      <TextInput {...fields.minimumPremium} />
                    </Section>
                  </Grid>
                  <Grid item xs={12}>
                    <Section subsection title="Sum Insured Premium Rates">
                      <Table
                        tableHeaders={[
                          {
                            id: "band",
                            label: "Band",
                          },
                          {
                            id: "sumInsuredMin",
                            label: "Sum Insured Min",
                          },
                          {
                            id: "sumInsuredMax",
                            label: "Sum Insured Max",
                          },
                          {
                            id: "premiumRate",
                            label: "Premium Rate",
                          },
                        ]}
                        data={sumInsuredPremiumRates || []}
                        rowRenderer={sumInsuredPremiumRatesTableRenderer}
                        keyExtractor={tableKeyExtractors.sumInsuredPremiumRates}
                        loading={!hasData.sumInsuredPremiumRates}
                      />
                    </Section>
                  </Grid>
                  <Grid item xs={12}>
                    <Section subsection title="Standard Excess">
                      <Table
                        tableHeaders={[
                          {
                            id: "SIRangeMin",
                            label: "SI Range Min",
                          },
                          {
                            id: "SIRangeMax",
                            label: "SI Range Max",
                          },
                          {
                            id: "Excess",
                            label: "Excess",
                          },
                        ]}
                        data={standardExcess || []}
                        rowRenderer={standardExcessTableRenderer}
                        keyExtractor={tableKeyExtractors.standardExcess}
                        loading={!hasData.standardExcess}
                      />
                    </Section>
                  </Grid>
                  <Grid item xs={12}>
                    <Section subsection title="Claims Free Discounts">
                      <Table
                        tableHeaders={[
                          {
                            id: "discountCriteria",
                            label: "Discount Criteria",
                          },
                          {
                            id: "discountPercent",
                            label: "Discount Percent",
                          },
                        ]}
                        data={claimsFreeDiscounts || []}
                        rowRenderer={claimsFreeDiscountsTableRenderer}
                        keyExtractor={tableKeyExtractors.claimsFreeDiscounts}
                        loading={!hasData.claimsFreeDiscounts}
                      />
                    </Section>
                  </Grid>
                  <Grid item xs={12}>
                    <Section subsection title="Discounts for Increased Excess">
                      <Table
                        tableHeaders={[
                          {
                            id: "increase",
                            label: "Increase",
                          },
                          {
                            id: "discountPercent",
                            label: "Discount Percent",
                          },
                        ]}
                        data={discountsIncreasedExcess || []}
                        rowRenderer={discountsIncreasedExcessTableRenderer}
                        keyExtractor={
                          tableKeyExtractors.discountsIncreasedExcess
                        }
                        loading={!hasData.discountsIncreasedExcess}
                      />
                    </Section>
                  </Grid>

                  {/* Disabled Editing the optional extras for now  */}
                  {/* {optionalExtraDefaults.map((oe, idx) => (
              <Grid key={idx} item xs={12} md={6}>
                <Section subsection title={`Optional Extra - ${oe.title}`}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Switch onChange={handleOptionalExtraChange(oe.title)} name={oe.title} />}
                      label={oe.title}
                      checked={!!values.formValues.optionalExtras.find(o => o === oe.title)}
                    />
                  </FormGroup>
                  {!!values.formValues.optionalExtras.find(o => o === oe.title) && (
                    <Table
                      tableHeaders={['Option', 'Discount Percent']}
                      data={oe.data}
                      rowRenderer={optionalExtrasTableRenderer}
                      keyExtractor={tableKeyExtractors.optionalExtras}
                      isLoading={false}
                      withPagination={false}
                    />
                  )}
                </Section>
              </Grid>
            ))} */}
                </Grid>
              </form>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}

export default QuoteData;
