import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string()
    .max(100, "Maximum 100 characters reached")
    .required("Full name is required"),
  email: Yup.string()
    .max(100, "Maximum 100 characters reached")
    .email("Invalid email address"),
  phone: Yup.string().max(100, "Maximum 100  characters reached"),
  address_line_one: Yup.string().max(100, "Maximum 100 characters reached"),
  address_line_two: Yup.string().max(100, "Maximum 100 characters reached"),
});
