import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppSettings from "./_AppSettings";
import EmailSettings from "./_EmailSettings";
import EmailTest from "./_EmailTest";

function SettingsEmail() {
  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column", mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppSettings />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EmailSettings />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <EmailTest />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default SettingsEmail;
