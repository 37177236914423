import * as React from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MuiCheckbox from "@mui/material/Checkbox";

interface Props {
  title?: string;
  label: string;
  checkboxLabel?: string;
  name: string;
  fullWidth?: boolean;
  disabled?: boolean;
  value: boolean;
  checked: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (checked: boolean) => void;
  onBlur: () => void;
}

function Checkbox({
  title,
  label,
  name,
  fullWidth,
  disabled,
  value,
  checked,
  required,
  error,
  helperText,
  onChange,
  onBlur,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onChange(checked);
  };

  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      variant="standard"
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {title && <FormLabel component="legend">{title}</FormLabel>}
      <FormGroup>
        <FormControlLabel
          control={
            <MuiCheckbox
              checked={checked}
              onChange={handleChange}
              onBlur={onBlur}
              name={name}
            />
          }
          label={label}
        />
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default Checkbox;
