import { useEffect } from "react";
import { usePDF } from "@react-pdf/renderer";
import StatusDialog from "./QuoteStatusFormDialog";
import PDFQuoteDocument from "./PDF/PDFQuoteDocument";
import PDFCoCDocument from "./PDF/PDFCertificateOfCurrencyDocument";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../lib/store/features/dialogs.slice";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";
import type { QuoteStatus } from "../../lib/types";
import {
  updateQuoteStatus,
  sendCoCEmail,
} from "../../lib/store/features/quotes.slice";
import { usePdfQuoteDetails } from "../../hooks/usePdfQuoteDetails";
import { usePdfCoCDetails } from "../../hooks/usePdfCoCDetails";
import { VEHICLE_QUOTE_TYPES } from "../../lib/constants";
import { selectMe } from "../../lib/store/features/user.slice";

function QuoteStatusDialog() {
  const dispatch = useAppDispatch();
  const me = useAppSelector(selectMe);
  const open = useAppSelector(selectDialogOpen("CHANGE_QUOTE_STATUS"));
  const { quoteDetails, agentDetails, clientDetails } = usePdfQuoteDetails();
  const {
    quoteDetails: cocQuoteDetails,
    agentDetails: cocAgentDetails,
    clientDetails: cocClientDetails,
  } = usePdfCoCDetails();

  // If the quote is accepted && has vehicle products && the user has access to the certificate_of_currency feature
  // Send the CoC email
  const hasCoCFeature = !!me?.brokerage?.quote_features_access?.find(
    (feature) => feature.feature_name === "certificate_of_currency"
  );

  const hasVehicleProducts = quoteDetails.products.some((product) =>
    VEHICLE_QUOTE_TYPES.includes(product.quoteType)
  );

  const [quotePdf, updateQuotePdf] = usePDF({
    document: (
      <PDFQuoteDocument
        quoteDetails={quoteDetails}
        agentDetails={agentDetails}
        clientDetails={clientDetails}
      />
    ),
  });

  const [cocPdf, updateCoCPdf] = usePDF({
    document: (
      <PDFCoCDocument
        quoteDetails={quoteDetails}
        agentDetails={agentDetails}
        clientDetails={clientDetails}
      />
    ),
  });

  useEffect(() => {
    updateQuotePdf(
      <PDFQuoteDocument
        quoteDetails={quoteDetails}
        agentDetails={agentDetails}
        clientDetails={clientDetails}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteDetails, agentDetails, clientDetails]);

  useEffect(() => {
    updateCoCPdf(
      <PDFCoCDocument
        quoteDetails={cocQuoteDetails}
        agentDetails={cocAgentDetails}
        clientDetails={cocClientDetails}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cocQuoteDetails, cocAgentDetails, cocClientDetails]);

  const handleClose = () => {
    dispatch(toggle({ dialog: "CHANGE_QUOTE_STATUS", open: false }));
  };

  const handleUpdateStatus = async ({ status }: { status: QuoteStatus }) => {
    const result = await dispatch(
      updateQuoteStatus({
        status,
        blob: quotePdf.blob as Blob,
        fileName: quoteDetails.fileName,
      })
    );

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    return true;
  };

  const handleSendCoCEmail = async ({ status }: { status: QuoteStatus }) => {
    if (hasCoCFeature && status === "accepted" && hasVehicleProducts) {
      const resultCoC = await dispatch(
        sendCoCEmail({
          blob: cocPdf.blob as Blob,
          fileName: cocQuoteDetails.fileName,
        })
      );

      if (resultCoC.meta.requestStatus === "rejected") {
        dispatch(
          showNotif({
            message: resultCoC.payload as string,
            options: { variant: "error" },
          })
        );

        return false;
      }
    }

    return true;
  };

  const handleComplete = () => {
    dispatch(
      showNotif({
        message: "Quote status updated",
        options: { variant: "success" },
      })
    );
  };

  return (
    <StatusDialog
      open={open}
      pdfLoading={quotePdf.loading || cocPdf.loading}
      hasCoCFeature={hasCoCFeature && hasVehicleProducts}
      onClose={handleClose}
      onSubmitStatus={handleUpdateStatus}
      onSubmitCoCEmail={handleSendCoCEmail}
      onComplete={handleComplete}
    />
  );
}

export default QuoteStatusDialog;
