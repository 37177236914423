import { AxiosError } from "axios";
import { clearAuthTokens } from "axios-jwt";

export const handleError = (rejectWithValue: any) => (error: any) => {
  if (error.message?.includes("Unable to refresh access token")) {
    clearAuthTokens();
    return rejectWithValue("You are unauthorised. Please login again.");
  }

  if ((error as AxiosError).isAxiosError) {
    if (
      (error as AxiosError).response?.status === 400 ||
      (error as AxiosError).response?.status === 500
    ) {
      return rejectWithValue(
        error?.response?.data?.message ??
          "There was a problem processing the request"
      );
    }
    if ((error as AxiosError).response?.status === 401) {
      clearAuthTokens();
      return rejectWithValue("You are unauthorised. Please login again.");
    }
    if ((error as AxiosError).response?.status === 404) {
      return rejectWithValue(
        "Sorry, something has gone wrong - we can't find the API path."
      );
    }
  }

  return rejectWithValue(
    error?.response?.status
      ? {
          message: error.message,
          status: error?.response?.status,
        }
      : error.message
  );
};
