import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import ChangePasswordDialog from "./_ChangePasswordDialog";
import EditProfileDialog from "./_EditProfileDialog";
import UserProfile from "../../components/Profile/UserProfile";
import { toggle as toggleDialog } from "../../lib/store/features/dialogs.slice";
import {
  getMe,
  selectMe,
  selectLoading,
} from "../../lib/store/features/user.slice";

function MyProfile() {
  const dispatch = useAppDispatch();
  const me = useAppSelector(selectMe);
  const loading = useAppSelector(selectLoading);

  useEffect(() => {
    dispatch(getMe());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetPassword = () => {
    dispatch(toggleDialog({ dialog: "CHANGE_PASSWORD", open: true }));
  };

  const handleEditProfile = () => {
    dispatch(toggleDialog({ dialog: "EDIT_MY_PROFILE", open: true }));
  };

  return (
    <>
      <ChangePasswordDialog />
      <EditProfileDialog />
      <UserProfile
        loading={loading}
        name={me?.name ?? ""}
        email={me?.email ?? ""}
        phone={me?.phone ?? ""}
        brokerage={me?.brokerage ?? null}
        role={me?.role}
        isActive={!!me?.isActive}
        onEdit={handleEditProfile}
        onResetPassword={handleResetPassword}
      />
    </>
  );
}

export default MyProfile;
