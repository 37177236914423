import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from //  FormControlProps
"@mui/material/FormControl";
import FormHelperText from // FormHelperTextProps,
"@mui/material/FormHelperText";
import MuiSelect from // SelectProps
"@mui/material/Select";

// interface Props {
//   label: SelectProps["label"];
//   id: SelectProps["id"];
//   fullWidth: FormControlProps["fullWidth"];
//   onChange: SelectProps["onChange"];
//   value: SelectProps["value"];
//   size: FormControlProps["size"];
//   error: SelectProps["error"];
//   helperText: FormHelperTextProps["children"];
//   options: { label: string; value: string }[];
//   required: FormControlProps["required"];
//   margin: FormControlProps["margin"];
// }

function Select({
  label,
  id,
  fullWidth,
  onChange,
  value,
  size,
  error,
  helperText,
  options,
  required,
  margin,
  ...props
}: any) {
  return (
    <FormControl
      fullWidth={fullWidth}
      size={size}
      margin={margin}
      required={required}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${id}-label`}
        id={id}
        label={label}
        onChange={onChange}
        value={value}
        error={error}
        {...props}
      >
        {Array.isArray(options) &&
          options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
      </MuiSelect>
      {!!helperText && (
        <FormHelperText error={error} id={`${id}-helper-text`}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default Select;
