import { useState } from "react";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import type { AppSettings as SettingsType } from "../../lib/types";

interface Props {
  settings: SettingsType["quote_settings"];
  hasChanges: boolean;
  onChange: (
    setting: keyof SettingsType["quote_settings"],
    value: string
  ) => void;
  onSubmit: () => Promise<boolean>;
}

export default function QuoteSettingsForm({
  settings,
  hasChanges,
  onSubmit,
  onChange,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChange =
    (setting: keyof SettingsType["quote_settings"]) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onChange(setting, value);
    };

  const handleSubmit = async () => {
    if (settings.quote_from_email.trim().length === 0) return;
    if (settings.quote_from_email_name.trim().length === 0) return;
    // if (settings.quote_accepted_to_email.trim().length === 0) return;

    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  const fields = [
    {
      name: "quote_from_email",
      label: "From Email Address",
      placeholder: "Enter the from email address",
      value: settings.quote_from_email,
      onChange: handleChange("quote_from_email"),
      type: "text",
      error: settings.quote_from_email.trim().length === 0,
      helperText: null,
    },
    {
      name: "quote_from_email_name",
      label: "From Email Address Name",
      placeholder: "Enter the from email name",
      value: settings.quote_from_email_name,
      onChange: handleChange("quote_from_email_name"),
      type: "text",
      error: settings.quote_from_email_name.trim().length === 0,
      helperText: null,
    },
    {
      name: "quote_accepted_to_email",
      label: "Accepted Quote / Certificate of Currency Email Address",
      placeholder: "Enter email address",
      value: settings.quote_accepted_to_email,
      onChange: handleChange("quote_accepted_to_email"),
      type: "email",
      error: null,
      helperText: "To disable this feature, leave this field blank",
    },
  ];

  return (
    <>
      <Typography variant="h6" component="h1">
        Quote Email Settings
      </Typography>

      <Typography variant="body1" component="span" color="GrayText">
        Use these settings to customise where the email is sent when a quote is
        accepted.
      </Typography>
      <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
        {fields.map((field) => {
          const { type, name, value, helperText, error, ...props } = field;

          return (
            <Grid item xs={12} md={6} key={name}>
              <TextField
                {...props}
                value={value}
                type={type}
                disabled={isLoading}
                required
                fullWidth
                error={!!error}
                helperText={error ? "This field is required" : helperText}
              />
            </Grid>
          );
        })}
      </Grid>
      <LoadingButton
        onClick={handleSubmit}
        loading={isLoading}
        disabled={!hasChanges}
        loadingPosition="end"
        endIcon={<SaveIcon />}
        variant="contained"
        type="button"
      >
        Save Settings
      </LoadingButton>
    </>
  );
}
