import { AxiosError } from "axios";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { sort } from "fast-sort";
import type {
  RootState,
  API as ApiType,
  ApiBrokeragesFetchListResponse,
  ApiBrokerageAddResponse,
  ApiBrokerageRemoveResponse,
  ApiBrokerageUpdateResponse,
  ApiBrokerageUploadLogoResponse,
  Brokerage,
  QuoteTypeAccess,
  ParentBrokerage,
  QuoteFeatureAccess,
} from "../../types";
import * as brokeragesApi from "../../api/handlers/brokerages";
import { handleError } from "../../helpers";

interface BrokeragesState {
  listById: {
    [key: Brokerage["id"]]: Brokerage;
  };
  selectedId?: null | Brokerage["id"];
  parentBrokerage: null | Brokerage;
  parentApi: ApiType;
  api: ApiType;
  featuresApi: ApiType;
}

const initialState: BrokeragesState = {
  listById: {},
  parentBrokerage: null,
  parentApi: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  featuresApi: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const fetchParentBrokerage = createAsyncThunk(
  "brokerages/fetchParentBrokerage",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const response = (await brokeragesApi.fetchParentBrokerage()) as any;
      return response.data as ParentBrokerage;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const fetchList = createAsyncThunk(
  "brokerages/fetchList",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { api } = (getState() as RootState).brokerages;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await brokeragesApi.fetchList()) as any;
      return response.data as ApiBrokeragesFetchListResponse[];
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const addBrokerage = createAsyncThunk(
  "brokerages/addBrokerage",
  async (
    {
      name,
      email,
      phone,
      address_line_one,
      address_line_two,
      quote_types_access,
    }: {
      name: Brokerage["name"];
      email?: Brokerage["email"];
      phone?: Brokerage["phone"];
      address_line_one?: Brokerage["address_line_one"];
      address_line_two?: Brokerage["address_line_two"];
      quote_types_access: Brokerage["quote_types_access"];
    },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).brokerages;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await brokeragesApi.addBrokerage({
        name,
        email,
        phone,
        address_line_one,
        address_line_two,
      })) as any;

      return response.data as ApiBrokerageAddResponse;
    } catch (error: any) {
      if (
        (error as AxiosError).isAxiosError &&
        (error as AxiosError).response?.status === 409
      ) {
        return rejectWithValue("Email address already exists");
      }
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateBrokerage = createAsyncThunk(
  "brokerages/updateBrokerage",
  async (
    {
      id,
      data: { name, email, phone, address_line_one, address_line_two },
    }: {
      id: Brokerage["id"];
      data: {
        name: Brokerage["name"];
        email: Brokerage["email"];
        phone: Brokerage["phone"];
        address_line_one: Brokerage["address_line_one"];
        address_line_two: Brokerage["address_line_two"];
      };
    },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).brokerages;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await brokeragesApi.updateBrokerage({
        id,
        brokerage_data: {
          name,
          email,
          phone,
          address_line_one,
          address_line_two,
        },
      })) as any;

      return response.data as ApiBrokerageUpdateResponse;
    } catch (error: any) {
      if (
        (error as AxiosError).isAxiosError &&
        (error as AxiosError).response?.status === 409
      ) {
        return rejectWithValue("Email address already exists");
      }
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateQuoteTypeAccess = createAsyncThunk(
  "brokerages/updateQuoteTypeAccess",
  async (
    {
      id,
      quote_type_ids,
      selected_quote_types,
    }: {
      id: Brokerage["id"];
      quote_type_ids: number[];
      selected_quote_types: QuoteTypeAccess[];
    },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).brokerages;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await brokeragesApi.updateBrokerageQuoteTypeAccess({
        id,
        quote_type_ids,
      })) as any;

      return response.data as ApiBrokerageUpdateResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateQuoteFeatureAccess = createAsyncThunk(
  "brokerages/updateQuoteFeatureAccess",
  async (
    {
      id,
      quote_feature_ids,
      selected_quote_features,
    }: {
      id: Brokerage["id"];
      quote_feature_ids: number[];
      selected_quote_features: QuoteFeatureAccess[];
    },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { featuresApi } = (getState() as RootState).brokerages;
      if (
        featuresApi.loading !== "pending" ||
        featuresApi.requestId !== requestId
      ) {
        return null;
      }

      const response = (await brokeragesApi.updateBrokerageQuoteFeatureAccess({
        id,
        quote_feature_ids,
      })) as any;

      return response.data as ApiBrokerageUpdateResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const deleteById = createAsyncThunk(
  "brokerages/deleteById",
  async (
    { id }: { id: Brokerage["id"] },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).brokerages;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

      await delay(2000);

      const response = (await brokeragesApi.deleteBrokerage({
        id,
      })) as any;

      return response.data as ApiBrokerageRemoveResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const uploadLogo = createAsyncThunk(
  "brokerages/uploadLogo",
  async (
    { id, logo }: { id: Brokerage["id"]; logo: File },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).brokerages;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await brokeragesApi.uploadLogo({
        id,
        logo,
      })) as any;

      return response.data as ApiBrokerageUploadLogoResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const deleteLogo = createAsyncThunk(
  "brokerages/deleteLogo",
  async (
    { brokerageId }: { brokerageId: Brokerage["id"] },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).brokerages;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await brokeragesApi.deleteLogo({
        id: brokerageId,
      })) as any;

      return response.data as ApiBrokerageUploadLogoResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const brokeragesSlice = createSlice({
  name: "brokerages",
  initialState,
  reducers: {
    setSelectedBrokerageId: (
      state,
      action: PayloadAction<Brokerage["id"] | null>
    ) => {
      state.selectedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* Update Brokerage Quote Types Access */
    builder.addCase(updateQuoteTypeAccess.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(
      updateQuoteTypeAccess.fulfilled,
      (state, { payload, meta }) => {
        state.api.loading = "idle";
        state.api.requestId = undefined;
        state.api.error = null;

        if (payload.updated === true) {
          const { selected_quote_types, id } = meta.arg;
          state.listById[id] = {
            ...state.listById[id],
            quote_types_access: selected_quote_types,
          };
        }
      }
    );
    builder.addCase(updateQuoteTypeAccess.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Update Brokerage Quote Feature Access */
    builder.addCase(updateQuoteFeatureAccess.pending, (state, { meta }) => {
      state.featuresApi.loading = "pending";
      state.featuresApi.requestId = meta.requestId;
      state.featuresApi.error = null;
    });
    builder.addCase(
      updateQuoteFeatureAccess.fulfilled,
      (state, { payload, meta }) => {
        state.featuresApi.loading = "idle";
        state.featuresApi.requestId = undefined;
        state.featuresApi.error = null;

        if (payload.updated === true) {
          const { selected_quote_features, id } = meta.arg;
          state.listById[id] = {
            ...state.listById[id],
            quote_features_access: selected_quote_features,
          };
        }
      }
    );
    builder.addCase(updateQuoteFeatureAccess.rejected, (state, action) => {
      state.featuresApi.loading = "error";
      state.featuresApi.requestId = undefined;
      state.featuresApi.error = action.error as string;
    });

    /* Fetch Parent Brokerage */
    builder.addCase(fetchParentBrokerage.pending, (state, { meta }) => {
      state.parentApi.loading = "pending";
      state.parentApi.requestId = meta.requestId;
      state.parentApi.error = null;
    });
    builder.addCase(fetchParentBrokerage.fulfilled, (state, { payload }) => {
      state.parentApi.loading = "idle";
      state.parentApi.requestId = undefined;
      state.parentApi.error = null;

      if (payload) {
        state.parentBrokerage = payload;
      }
    });
    builder.addCase(fetchParentBrokerage.rejected, (state, action) => {
      state.parentApi.loading = "error";
      state.parentApi.requestId = undefined;
      state.parentApi.error = action.error as string;
    });

    /* Fetch Brokerages List */
    builder.addCase(fetchList.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(fetchList.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.listById = payload.reduce((acc: any, cur: Brokerage) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
      }
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Add Brokerage */
    builder.addCase(addBrokerage.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(addBrokerage.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.listById[payload.id] = { ...payload };
      }
    });
    builder.addCase(addBrokerage.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Upload Brokerage Logo */
    builder.addCase(uploadLogo.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(uploadLogo.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.listById[meta.arg.id] = {
          ...state.listById[meta.arg.id],
          logo: payload.logo,
        };
      }
    });
    builder.addCase(uploadLogo.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Delete Brokerage Logo */
    builder.addCase(deleteLogo.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(deleteLogo.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.listById[meta.arg.brokerageId] = {
          ...state.listById[meta.arg.brokerageId],
          logo: null,
        };
      }
    });
    builder.addCase(deleteLogo.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Update Brokerage */
    builder.addCase(updateBrokerage.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(updateBrokerage.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload && state.listById[meta.arg.id]) {
        state.listById[meta.arg.id] = {
          ...state.listById[meta.arg.id],
          ...meta.arg.data,
        };
      }
    });
    builder.addCase(updateBrokerage.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Delete Brokerage */
    builder.addCase(deleteById.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(deleteById.fulfilled, (state, { meta, payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload.deleted && state.listById[meta.arg.id]) {
        delete state.listById[meta.arg.id];
      }
    });
    builder.addCase(deleteById.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });
  },
});

// Actions
export const { setSelectedBrokerageId } = brokeragesSlice.actions;

// Selectors
const selector = (state: RootState) => state.brokerages;
export const selectedBrokerage = createSelector(selector, (brokerages) => {
  return brokerages.selectedId
    ? brokerages.listById[brokerages.selectedId]
    : undefined;
});
export const selectList = createSelector(selector, (brokerages) =>
  sort(Object.values(brokerages.listById)).asc((b: Brokerage) => b.name)
);
export const selectListById = createSelector(
  selector,
  (brokerages) => brokerages.listById
);
export const selectParentBrokerage = createSelector(
  selector,
  (brokerages) => brokerages.parentBrokerage
);
export const selectLoadingParentBrokerage = createSelector(
  selector,
  (brokerages) => brokerages.parentApi.loading === "pending"
);

export const loadingList = createSelector(
  selector,
  (users) => users.api.loading === "pending"
);

export default brokeragesSlice.reducer;
