import { Text, View, Image } from "@react-pdf/renderer";
import styles from "./styles";
import type { PDFPage } from "../../../../lib/types";

interface Props {
  brokerageAddressOne?: PDFPage["brokerageAddressOne"];
  brokerageAddressTwo?: PDFPage["brokerageAddressTwo"];
  brokerageEmail?: PDFPage["brokerageEmail"];
  brokeragePhone?: PDFPage["brokeragePhone"];
  brokerageLogo?: PDFPage["brokerageLogo"];
  brokerageWebsite?: string;
  prefixes?: { phone?: string; email?: string; website?: string };
}

export function PageHeader({
  brokerageAddressOne,
  brokerageAddressTwo,
  brokerageEmail,
  brokeragePhone,
  brokerageLogo,
  brokerageWebsite,
  prefixes,
}: Props) {
  return (
    <View style={styles.headerImageContainer}>
      <View>
        {!!brokerageLogo && (
          <Image
            src={{
              uri: brokerageLogo,
              method: "GET",
              headers: {},
              body: "",
            }}
            style={styles.image}
          />
        )}
      </View>
      <View>
        {!!brokerageAddressOne && (
          <Text style={[styles.headerText, styles.textDarkGrey]}>
            {brokerageAddressOne}
          </Text>
        )}
        {!!brokerageAddressTwo && (
          <Text style={[styles.headerText, styles.textDarkGrey]}>
            {brokerageAddressTwo}
          </Text>
        )}
        {!!brokeragePhone && (
          <Text style={[styles.headerText, styles.textDarkGrey]}>
            {prefixes?.phone ?? "P"}: {brokeragePhone}
          </Text>
        )}
        {!!brokerageEmail && (
          <Text style={[styles.headerText, styles.textDarkGrey]}>
            {prefixes?.email ?? "E"}: {brokerageEmail}
          </Text>
        )}
        {!!brokerageWebsite && (
          <Text style={[styles.headerText, styles.textDarkGrey]}>
            {prefixes?.website ?? "W"}: {brokerageWebsite}
          </Text>
        )}
      </View>
    </View>
  );
}

export function PageFooter({
  pageNumber,
  brokerageName,
}: {
  pageNumber: number;
  brokerageName?: string;
}) {
  return (
    <View style={styles.pageFooter}>
      <View
        style={[styles.flexRow, styles.justifySpaceBetween, styles.padding10]}
      >
        {!!brokerageName && (
          <View>
            <Text style={styles.pageFooterText}>{brokerageName}</Text>
          </View>
        )}
        <View>
          <Text style={styles.pageFooterText}>Page {pageNumber}</Text>
        </View>
      </View>
    </View>
  );
}
