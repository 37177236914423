import { useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SaveIcon from "@mui/icons-material/Save";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { validationSchema } from "./validationSchema";
import { Brokerage } from "../../lib/types";

interface Props {
  open: boolean;
  brokerage?: Brokerage;
  onClose: () => void;
  onSubmit: (brokerage: Brokerage) => Promise<boolean>;
  onClickDeleteBrokerage?: () => Promise<boolean>;
}

export default function BrokerageDialog({
  open,
  brokerage,
  onClose,
  onSubmit,
  onClickDeleteBrokerage,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const fields: { [key: string]: string | boolean | number } = {
    name: brokerage?.name || "",
    email: brokerage?.email || "",
    phone: brokerage?.phone || "",
    address_line_one: brokerage?.address_line_one || "",
    address_line_two: brokerage?.address_line_two || "",
  };

  const inputProps: { [key: string]: any } = {
    name: {
      label: "Brokerage Name",
      placeholder: "Enter the brokerage name",
      type: "text",
      Component: TextField,
      required: true,
    },
    email: {
      label: "Email",
      placeholder: "Enter the brokerage email",
      type: "email",
      Component: TextField,
      required: false,
    },
    phone: {
      label: "Phone",
      placeholder: "Enter the brokerage phone number",
      type: "text",
      Component: TextField,
      required: false,
    },
    address_line_one: {
      label: "Address Line One",
      placeholder: "Enter the brokerage address line one",
      type: "text",
      Component: TextField,
      required: false,
    },
    address_line_two: {
      label: "Address Line Two",
      placeholder: "Enter the brokerage address line two",
      type: "text",
      Component: TextField,
      required: false,
    },
  };

  const handleClickRemoveBrokerage = async () => {
    if (!onClickDeleteBrokerage) return;

    if (window.confirm("Are you sure you want to delete this brokerage?")) {
      setIsDeleting(true);
      await onClickDeleteBrokerage();
      setIsDeleting(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Formik
        initialValues={{ ...fields }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setIsLoading(true);
          const result = await onSubmit(values as any);
          setSubmitting(false);
          setIsLoading(false);
          if (result) resetForm();
        }}
      >
        {({ errors }) => {
          return (
            <Form noValidate>
              <DialogTitle>
                {brokerage ? "Edit Brokerage Details" : "Add a New Brokerage"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Fill in the below form to{" "}
                  {brokerage ? "edit the" : "add a new"} brokerage.
                </DialogContentText>

                {Object.keys(fields).map((fieldName) => {
                  const { Component, type, name, ...props } =
                    inputProps[fieldName];

                  return (
                    <Field type={type} name={fieldName} key={fieldName}>
                      {({ field, meta, form }: FieldProps) => {
                        return (
                          <Component
                            {...field}
                            {...props}
                            type={type}
                            margin="normal"
                            fullWidth
                            disabled={isLoading || isDeleting}
                            error={meta.touched && !!meta.error}
                            helperText={
                              meta.touched && !!meta.error ? meta.error : null
                            }
                          />
                        );
                      }}
                    </Field>
                  );
                })}
              </DialogContent>
              <DialogActions>
                {brokerage && (
                  <>
                    <LoadingButton
                      onClick={handleClickRemoveBrokerage}
                      loading={isDeleting}
                      disabled={isLoading}
                      color="error"
                      loadingPosition="end"
                      endIcon={<SaveIcon />}
                      variant="outlined"
                      type="button"
                    >
                      Delete Brokerage
                    </LoadingButton>
                    <div style={{ flex: "1 0 0" }} />
                  </>
                )}
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton
                  loading={isLoading}
                  disabled={isDeleting}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  variant="contained"
                  type="submit"
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
