import React from "react";
import grey from "@mui/material/colors/grey";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getContrast } from "../../lib/helpers";

function Badge({
  status,
  color,
  textProps,
}: {
  status: string;
  color?: string;
  textProps?: TypographyProps;
}) {
  const bgColor = color || grey[200];
  return (
    <Box
      sx={{
        paddingLeft: 1,
        paddingRight: 1,
        backgroundColor: bgColor,
        borderRadius: 1,
        display: "inline-block",
      }}
      component="div"
    >
      <Typography
        variant="caption"
        sx={{
          color: getContrast(bgColor),
          p: 0,
          m: 0,
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
        }}
        {...textProps}
      >
        {status}
      </Typography>
    </Box>
  );
}

export default Badge;
