import { Document } from "@react-pdf/renderer";
import Letter from "./Letter";
import QuoteDetails from "./Quote";
import type {
  PDFClientDetails,
  PDFQuoteDetails,
  PDFAgentDetails,
  PDFQuoteVehicleDetails,
} from "../../../../lib/types";
import { PL_QUOTE_TYPES, WRK_CMP_QUOTE_TYPES } from "../../../../lib/constants";

interface Props {
  clientDetails: PDFClientDetails;
  quoteDetails: PDFQuoteDetails;
  agentDetails: PDFAgentDetails;
}

function PDFQuoteDocument({
  clientDetails,
  quoteDetails,
  agentDetails,
}: Props) {
  return (
    <Document>
      <Letter
        clientDetails={clientDetails}
        quoteDetails={{
          dates: quoteDetails.dates,
          reference: quoteDetails.reference,
          titles: quoteDetails.products.map((product) => product.title),
        }}
        vehicles={quoteDetails.products
          .filter(
            (product) =>
              product.quoteType !== WRK_CMP_QUOTE_TYPES[0] &&
              product.quoteType !== PL_QUOTE_TYPES[0]
          )
          .map((product) => (product as PDFQuoteVehicleDetails).vehicle)}
        workersCompensation={
          !!quoteDetails.products.find(
            (product) => product.quoteType === WRK_CMP_QUOTE_TYPES[0]
          )
        }
        publicLiablity={
          !!quoteDetails.products.find(
            (product) => product.quoteType === PL_QUOTE_TYPES[0]
          )
        }
        agentDetails={agentDetails}
      />
      <QuoteDetails
        clientDetails={clientDetails}
        agentDetails={agentDetails}
        quoteDetails={quoteDetails}
      />
    </Document>
  );
}

export default PDFQuoteDocument;
