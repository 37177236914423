import { useRouteError } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function RoutingError() {
  let error = useRouteError();
  console.error(`Routing Error: ${error}`);

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          m: 5,
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
      >
        <Typography variant="h3">That's an error!</Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Sorry about this! We've encrountered a navigational or programming
          error. Press the back button or reload the page to get back to where
          you were.
        </Typography>
      </Paper>
    </Container>
  );
}

export default RoutingError;
