import React, { useEffect } from "react";
import CalcComponent from "../../components/Quote/Calculations";
import { getTotalsCalculations } from "../../lib/helpers";
import type {
  CalculationsFunctionsType,
  CalculationsType,
} from "../../lib/types";

interface Props {
  quoteYears?: number;
  minPremium: number;
  calculations: CalculationsType;
  stampDuty: number;
  calculationFunctions: CalculationsFunctionsType;
  onChange: ({ field, value }: { field: "quoteYears"; value: number }) => void;
}

function Calculations({
  quoteYears,
  minPremium,
  calculations,
  stampDuty,
  calculationFunctions,
  onChange,
}: Props) {
  const [yearsRequired, setYearsRequired] = React.useState(
    typeof quoteYears === "number" ? quoteYears : 1
  );

  useEffect(() => {
    if (typeof quoteYears === "number" && quoteYears !== yearsRequired) {
      setYearsRequired(quoteYears);
    }
  }, [quoteYears, yearsRequired]);

  const { sumInsured, sumInsuredPremium, discountPercentage } = calculations;
  const { grandTotal, totalCalculations } = getTotalsCalculations({
    sumInsuredPremium,
    yearsRequired,
    sumInsured,
    calculationFunctions,
    discountPercentage,
    minPremium,
    stampDuty,
  });

  const handleSetYears = (years: number) => {
    setYearsRequired(years);
    onChange({ field: "quoteYears", value: years });
  };

  return (
    <CalcComponent
      minPremium={minPremium}
      // sumInsuredPremium={sumInsuredPremium}
      totalCalculations={totalCalculations}
      grandTotal={grandTotal}
      onSetYears={handleSetYears}
      yearsRequired={yearsRequired}
    />
  );
}

export default Calculations;
