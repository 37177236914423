import { useState } from "react";
import SelectProductDialog from "../../components/QuoteSelectProductDialog";
import ProductFormDialog from "./QuoteFormDialog";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../lib/store/features/dialogs.slice";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";
import { getLastModifiedDates } from "../../lib/store/features/quoteData.slice";
import { selectMe } from "../../lib/store/features/user.slice";
import { addProduct } from "../../lib/store/features/quotes.slice";
import { QUOTE_INFO } from "../../lib/constants";
import type {
  QuoteType,
  QuoteFormState,
  WrkCmpQuoteFormState,
} from "../../lib/types";

function QuoteCreateDialog() {
  const [selectedQuoteType, setSelectedQuoteType] = useState<QuoteType | null>(
    null
  );

  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("CREATE_QUOTE"));
  const me = useAppSelector(selectMe);

  const handleClose = () => {
    setSelectedQuoteType(null);
    dispatch(toggle({ dialog: "CREATE_QUOTE", open: false }));
  };

  const handleChooseProduct = async (quoteType: QuoteType) => {
    const result = await dispatch(getLastModifiedDates({ quoteType }));

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    setSelectedQuoteType(quoteType);

    return true;
  };

  const handleSaveProductToQuote = async (
    data: QuoteFormState | WrkCmpQuoteFormState
  ) => {
    dispatch(addProduct({ quoteType: selectedQuoteType, data }));
    handleClose();

    dispatch(
      showNotif({
        message: "Product added",
        options: { variant: "success" },
      })
    );

    return true;
  };

  const products = Object.keys(QUOTE_INFO)
    .map((key: any) => ({
      title: QUOTE_INFO[key as keyof typeof QUOTE_INFO].title,
      subtitle: QUOTE_INFO[key as keyof typeof QUOTE_INFO].subTitle,
      quoteType: key as QuoteType,
    }))
    .filter((product) =>
      me?.brokerage?.quote_types_access?.find(
        (quoteType) => quoteType.name === product.quoteType
      )
    );

  return (
    <>
      {!selectedQuoteType && (
        <SelectProductDialog
          open={open}
          products={products}
          onClose={handleClose}
          onSubmit={handleChooseProduct}
        />
      )}
      {selectedQuoteType && (
        <ProductFormDialog
          open={open}
          quoteType={selectedQuoteType}
          quoteStatus="pending"
          onClose={handleClose}
          onSubmit={handleSaveProductToQuote}
        />
      )}
    </>
  );
}

export default QuoteCreateDialog;
