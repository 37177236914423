import CalcComponent from "../../components/Quote/WorkersCompensationCalculations";
import type { WrkCmpCalculationsType } from "../../lib/types";

interface Props {
  minPremium: number;
  calculations: WrkCmpCalculationsType;
  stampDuty: number;
}

function CalculationsWrkCmp({ minPremium, calculations, stampDuty }: Props) {
  const { subTotal, sumInsuredPremium, optionalExtras } = calculations;
  const grandTotal = sumInsuredPremium + optionalExtras + stampDuty;

  return (
    <CalcComponent
      minPremium={minPremium}
      sumInsuredPremium={sumInsuredPremium}
      optionalExtras={optionalExtras}
      subTotal={subTotal}
      stampDuty={stampDuty}
      grandTotal={grandTotal}
    />
  );
}

export default CalculationsWrkCmp;
