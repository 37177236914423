import { request } from "../index";
import { apiUrls } from "../api-urls";

const authUrls = apiUrls.auth;
const profileUrls = apiUrls.profile;

export const login = async (email: string, password: string) => {
  return request({
    data: { email, password },
    url: authUrls.login,
    method: "POST",
  });
};

export const me = async () => {
  return request({
    url: profileUrls.me,
    method: "GET",
  });
};

export const updateProfile = async ({
  email,
  phone,
  name,
}: {
  email: string;
  phone: string;
  name: string;
}) => {
  return request({
    data: {
      email,
      phone,
      name,
    },
    url: profileUrls.me,
    method: "PUT",
  });
};

export const updatePassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  return request({
    data: { currentPassword, newPassword },
    url: profileUrls.updatePassword,
    method: "PUT",
  });
};
