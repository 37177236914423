import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  API as ApiType,
  ApiGetQuotesPageResponse,
} from "../../types";
import * as quoteReportsApi from "../../api/handlers/quoteReports";
import { handleError } from "../../helpers";

interface QuotePaginationState {
  api: ApiType;
}

const initialState: QuotePaginationState = {
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const search = createAsyncThunk(
  "quoteReports/search",
  async (
    { from, to }: { from: string; to: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).quoteReports;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await quoteReportsApi.searchQuotes({
        from,
        to,
      })) as any;
      return response.data as ApiGetQuotesPageResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const quoteReportsSlice = createSlice({
  name: "quoteReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* Get Quotes Page  */
    builder.addCase(search.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(search.fulfilled, (state, { payload, meta }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;
    });
    builder.addCase(search.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });
  },
});

// Selectors
const selector = (state: RootState) => state.quoteReports;
export const selectLoading = createSelector(
  selector,
  (data) => data.api.loading === "pending"
);

export default quoteReportsSlice.reducer;
