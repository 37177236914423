import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  API as ApiType,
  QuoteProduct,
  QuoteStatus,
  ApiPostSaveQuoteResponse,
  ApiPostUpdateQuoteResponse,
} from "../../types";
import * as quotesApi from "../../api/handlers/quotes";
import { handleError } from "../../helpers";

interface QuotesState {
  current: {
    id: null | number;
    quoteReference: null | string;
    quoteStatus: QuoteStatus;
    hasChanges: boolean;
    client: {
      isPrivate: boolean;
      clientFirstName: string;
      clientLastName: string;
      clientTitle: string;
      clientAddress: string;
    };
    effectiveDate: null | string;
    acceptedDate: null | string;
    products: Array<QuoteProduct>;
    selectedProductId: null | number;
  };
  api: ApiType;
  updateQuoteApi: ApiType;
  sendCocApi: ApiType;
}

const initialState: QuotesState = {
  current: {
    hasChanges: false,
    id: null,
    quoteReference: null,
    quoteStatus: "pending",
    client: {
      isPrivate: true,
      clientTitle: "",
      clientFirstName: "",
      clientLastName: "",
      clientAddress: "",
    },
    effectiveDate: null,
    acceptedDate: null,
    products: [],
    selectedProductId: null,
  },
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  updateQuoteApi: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  sendCocApi: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const saveQuote = createAsyncThunk(
  "quotes/saveQuote",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { api, current } = (getState() as RootState).quotes;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const _quote = {
        id: current.id,
        isPrivate: current.client.isPrivate,
        clientFirstName: current.client.clientFirstName,
        clientLastName: current.client.isPrivate
          ? current.client.clientLastName
          : "",
        clientTitle: current.client.isPrivate ? current.client.clientTitle : "",
        clientAddress: current.client.clientAddress,
        quoteReference: current.quoteReference,
        quoteStatus: current.quoteStatus,
        effectiveDate: current.effectiveDate,
        products: current.products,
      };

      // If there is a quote Id, then we are updating an existing quote
      // otherwise we are creating a new quote
      if (current.id) {
        const response = (await quotesApi.updateQuote(_quote)) as any;
        return response.data as ApiPostUpdateQuoteResponse;
      } else {
        const response = (await quotesApi.saveQuote(_quote)) as any;
        return response.data as ApiPostSaveQuoteResponse;
      }
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateQuoteStatus = createAsyncThunk(
  "quotes/updateQuoteStatus",
  async (
    {
      status,
      blob,
      fileName,
    }: { status: QuoteStatus; blob?: Blob; fileName?: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { updateQuoteApi, current } = (getState() as RootState).quotes;
      if (
        updateQuoteApi.loading !== "pending" ||
        updateQuoteApi.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quotesApi.updateQuoteStatus(
        current.id,
        status,
        blob,
        fileName
      )) as any;
      return response.data as ApiPostUpdateQuoteResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const sendCoCEmail = createAsyncThunk(
  "quotes/sendCoCEmail",
  async (
    { blob, fileName }: { blob: Blob; fileName: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { sendCocApi, current } = (getState() as RootState).quotes;
      if (
        sendCocApi.loading !== "pending" ||
        sendCocApi.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quotesApi.sendCoCEmail(
        current.id,
        blob,
        fileName
      )) as any;
      return response.data as ApiPostUpdateQuoteResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    setCurrentQuote(state, { payload }: { payload: QuotesState["current"] }) {
      state.current = payload;
      state.current.hasChanges = false;
    },
    setHasChanges(state, { payload }) {
      state.current.hasChanges = payload;
    },
    setClient(state, { payload }) {
      state.current.client = payload;
      state.current.hasChanges = true;
    },
    setEffectiveDate(state, { payload }) {
      state.current.effectiveDate = payload;
      state.current.hasChanges = true;
    },
    addProduct(state, { payload }) {
      state.current.products.push({
        ...payload,
        id: state.current.products.length + 1,
      });
      state.current.hasChanges = true;
    },
    setSelectedProductId(state, { payload }) {
      state.current.selectedProductId = payload;
    },
    updateProduct(state, { payload }) {
      state.current.products = state.current.products.map((product) => {
        if (product.id === payload.id) {
          return {
            ...product,
            data: payload.data,
          };
        }

        return product;
      });
      state.current.hasChanges = true;
    },
    removeProduct(state, { payload }) {
      state.current.products = state.current.products.filter(
        (product) => product.id !== payload.id
      );
      state.current.hasChanges = true;
    },
    reset(state) {
      state.current = { ...initialState.current };
    },
  },
  extraReducers: (builder) => {
    /* Save Quote */
    builder.addCase(saveQuote.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(saveQuote.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (state.current.id && payload) {
        // this was an update to an existing quote
        state.current.hasChanges = false;
      } else if (!state.current.id && payload) {
        // this was a new quote
        state.current.hasChanges = false;
        state.current.id = +payload.quoteID;
        state.current.quoteReference = payload.quoteReference;
        state.current.quoteStatus = payload.quoteStatus;
      }
    });
    builder.addCase(saveQuote.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Update Quote Status  */
    builder.addCase(updateQuoteStatus.pending, (state, { meta }) => {
      state.updateQuoteApi.loading = "pending";
      state.updateQuoteApi.requestId = meta.requestId;
      state.updateQuoteApi.error = null;

      state.current.hasChanges = true;
    });
    builder.addCase(updateQuoteStatus.fulfilled, (state, { payload, meta }) => {
      state.updateQuoteApi.loading = "idle";
      state.updateQuoteApi.requestId = undefined;
      state.updateQuoteApi.error = null;

      if (payload) {
        state.current.quoteStatus = meta.arg.status;
        state.current.acceptedDate = payload.acceptedDate;
      }

      state.current.hasChanges = false;
    });
    builder.addCase(updateQuoteStatus.rejected, (state, action) => {
      state.updateQuoteApi.loading = "error";
      state.updateQuoteApi.requestId = undefined;
      state.updateQuoteApi.error = action.error as string;
    });

    /* Send CoC Email  */
    builder.addCase(sendCoCEmail.pending, (state, { meta }) => {
      state.sendCocApi.loading = "pending";
      state.sendCocApi.requestId = meta.requestId;
      state.sendCocApi.error = null;

      state.current.hasChanges = true;
    });
    builder.addCase(sendCoCEmail.fulfilled, (state, { payload, meta }) => {
      state.sendCocApi.loading = "idle";
      state.sendCocApi.requestId = undefined;
      state.sendCocApi.error = null;

      state.current.hasChanges = false;
    });
    builder.addCase(sendCoCEmail.rejected, (state, action) => {
      state.sendCocApi.loading = "error";
      state.sendCocApi.requestId = undefined;
      state.sendCocApi.error = action.error as string;
    });
  },
});

// Actions
export const {
  reset,
  addProduct,
  setEffectiveDate,
  setClient,
  updateProduct,
  removeProduct,
  setSelectedProductId,
  setCurrentQuote,
  setHasChanges,
} = quotesSlice.actions;

// Selectors
const selector = (state: RootState) => state.quotes;
export const selectClientQuote = createSelector(
  selector,
  (quotes) => quotes.current
);
export const selectHasQuoteChanges = createSelector(
  selector,
  (quotes) => quotes.current.hasChanges
);
export const selectSavingQuote = createSelector(
  selector,
  (data) => data.api.loading === "pending"
);
export const selectUpdatingQuoteStatus = createSelector(
  selector,
  (data) => data.updateQuoteApi.loading === "pending"
);
export const selectSendingCoCEmail = createSelector(
  selector,
  (data) => data.sendCocApi.loading === "pending"
);
export const selectSelectedProduct = createSelector(selector, (quotes) => {
  if (quotes.current.selectedProductId) {
    return (
      quotes.current.products.find(
        (p) => p.id === quotes.current.selectedProductId
      ) || null
    );
  }

  return null;
});

export default quotesSlice.reducer;
