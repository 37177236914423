import * as Yup from "yup";

export const addValidationSchema = Yup.object({
  name: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Full name is required"),
  email: Yup.string()
    .max(100, "Maximum characters reached")
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .max(16, "Maximum 16 characters")
    .required("Password is required"),
  role: Yup.string().required("User role is required"),
});

export const editValidationSchema = Yup.object({
  name: Yup.string()
    .max(100, "Maximum characters reached")
    .required("Full name is required"),
  email: Yup.string()
    .max(100, "Maximum characters reached")
    .email("Invalid email address")
    .required("Email is required"),
  role: Yup.string().required("User role is required"),
});
