import { useAppDispatch, useAppSelector } from "../../hooks";
import TestForm from "../../components/EmailTestForm";
import {
  selectEmailSettings as selectSettings,
  sendTestEmail,
} from "../../lib/store/features/settings-email.slice";
import { enqueue } from "../../lib/store/features/notifications.slice";

function EmailTest() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);

  const handleSubmit = async ({
    from_address,
    to_address,
  }: {
    from_address: string;
    to_address: string;
  }) => {
    const response = await dispatch(
      sendTestEmail({
        from_address,
        to_address,
      })
    );

    if (response.meta.requestStatus !== "fulfilled") {
      dispatch(
        enqueue({
          message: response?.payload ?? "Error sending test email",
          options: { variant: "error", autoHideDuration: 20000 },
        })
      );
      return false;
    }
    dispatch(
      enqueue({
        message: `Email sent. Please check the inbox for ${to_address}.`,
        options: { variant: "success" },
      })
    );
    return true;
  };

  // Disable testing email settings, if no hostname, username or password is set.
  const disabled = !settings.host || !settings.username || !settings.password;

  return <TestForm onSubmit={handleSubmit} disabled={disabled} />;
}

export default EmailTest;
