import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  API as ApiType,
  ApiGetQuotesPageResponse,
  QuotePage,
} from "../../types";
import { reset } from "./quotes.slice";
import * as quotesPageApi from "../../api/handlers/quotesPagination";
import { handleError } from "../../helpers";

interface QuotePaginationState {
  page: QuotePage;
  api: ApiType;
}

const initialState: QuotePaginationState = {
  page: {
    currentPage: 1,
    prevPage: null,
    nextPage: null,
    totalPages: 1,
    start: 1,
    end: 1,
    results: [],
  },
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const getQuotesPage = createAsyncThunk(
  "quoteSearch/getQuotesPage",
  async (
    { page, limit, query }: { page: number; limit: number; query?: string },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { api } = (getState() as RootState).quotesPagination;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await quotesPageApi.getPage({
        page,
        limit,
        query,
      })) as any;
      return response.data as ApiGetQuotesPageResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const quotesPaginationSlice = createSlice({
  name: "quotesPagination",
  initialState,
  reducers: {
    clearPage: (state) => {
      state.page = initialState.page;
    },
  },
  extraReducers: (builder) => {
    /* New Quote  */
    builder.addCase(reset, (state) => {
      state.page = initialState.page;
    });

    /* Get Quotes Page  */
    builder.addCase(getQuotesPage.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(getQuotesPage.fulfilled, (state, { payload, meta }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload) {
        state.page = payload;
      }
    });
    builder.addCase(getQuotesPage.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });
  },
});

export const { clearPage } = quotesPaginationSlice.actions;

// Selectors
const selector = (state: RootState) => state.quotesPagination;
export const selectPage = createSelector(
  selector,
  (pagination) => pagination.page
);
export const selectLoadingPage = createSelector(
  selector,
  (data) => data.api.loading === "pending"
);

export default quotesPaginationSlice.reducer;
