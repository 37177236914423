import { request } from "../index";
import { apiUrls } from "../api-urls";
import { Brokerage, User } from "../../types";

const urls = apiUrls.brokerages;

/*
 * Brokerages API
 ** */

interface BrokerageData {
  name: Brokerage["name"];
  address_line_one?: Brokerage["address_line_one"];
  address_line_two?: Brokerage["address_line_two"];
  email?: Brokerage["email"];
  phone?: Brokerage["phone"];
  logo?: Brokerage["logo"];
}

export function fetchById({ id }: { id: Brokerage["id"] }) {
  return request({
    url: urls.byId.replace(/:BROKERAGE_ID/, `${id}`),
    method: "GET",
  });
}

export function fetchParentBrokerage() {
  return request({
    url: urls.parent,
    method: "GET",
  });
}

export function fetchList() {
  return request({
    url: urls.list,
    method: "GET",
  });
}

export function addBrokerage({
  name,
  address_line_one,
  address_line_two,
  email,
  phone,
}: BrokerageData) {
  return request({
    method: "POST",
    url: urls.list,
    data: {
      name,
      address_line_one,
      address_line_two,
      email,
      phone,
    },
  });
}

export function updateBrokerage({
  id,
  brokerage_data,
}: {
  id: Brokerage["id"];
  brokerage_data: BrokerageData;
}) {
  return request({
    method: "PUT",
    url: urls.byId.replace(/:BROKERAGE_ID/, `${id}`),
    data: { ...brokerage_data },
  });
}

export function updateBrokerageQuoteTypeAccess({
  id,
  quote_type_ids,
}: {
  id: Brokerage["id"];
  quote_type_ids: number[];
}) {
  return request({
    method: "PUT",
    url: urls.byIdQuoteTypesAccess.replace(/:BROKERAGE_ID/, `${id}`),
    data: { quote_type_ids },
  });
}

export function updateBrokerageQuoteFeatureAccess({
  id,
  quote_feature_ids,
}: {
  id: Brokerage["id"];
  quote_feature_ids: number[];
}) {
  return request({
    method: "PUT",
    url: urls.byIdQuoteFeaturesAccess.replace(/:BROKERAGE_ID/, `${id}`),
    data: { quote_feature_ids },
  });
}

export function uploadLogo({ id, logo }: { id: Brokerage["id"]; logo: File }) {
  const formData = new FormData();
  formData.append("logo", logo);

  return request({
    data: formData,
    method: "POST",
    url: urls.byIdLogo.replace(/:BROKERAGE_ID/, `${id}`),
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteLogo({ id }: { id: Brokerage["id"] }) {
  return request({
    method: "DELETE",
    url: urls.byIdLogo.replace(/:BROKERAGE_ID/, `${id}`),
  });
}

export function deleteBrokerage({ id }: { id: Brokerage["id"] }) {
  return request({
    method: "DELETE",
    url: urls.byId.replace(/:BROKERAGE_ID/, `${id}`),
  });
}

export function assignUsersToBrokerage({
  brokerage_id,
  user_ids,
}: {
  user_ids: User["id"][];
  brokerage_id: Brokerage["id"];
}) {
  return request({
    method: "PUT",
    data: { user_ids },
    url: urls.assignUsers.replace(/:BROKERAGE_ID/, `${brokerage_id}`),
  });
}

export function unassignUsersFromBrokerage({
  brokerage_id,
  user_ids,
}: {
  user_ids: User["id"][];
  brokerage_id: Brokerage["id"];
}) {
  return request({
    method: "PUT",
    data: { user_ids },
    url: urls.assignUsers.replace(/:BROKERAGE_ID/, `${brokerage_id}`),
  });
}
