import { useEffect } from "react";
import UsersTable from "../../components/UsersTable";
import AddUserDialog from "./Dialogs/Add";
import ChangePasswordDialog from "./Dialogs/ChangePassword";
import EditUserDialog from "./Dialogs/Edit";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  fetchList as fetchUsersList,
  toggleStatus,
  selectList as selectUsersList,
  deleteById,
  loadingList as loadingUsers,
  setSelectedUserId,
} from "../../lib/store/features/users.slice";
import {
  selectListById as selectBrokeragesListById,
  fetchList as fetchBrokeragesList,
  loadingList as loadingBrokerages,
} from "../../lib/store/features/brokerages.slice";
import { selectMe } from "../../lib/store/features/user.slice";
import { toggle as toggleDialog } from "../../lib/store/features/dialogs.slice";
import { User } from "../../lib/types";

function UsersSettings() {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsersList);
  const brokeragesById = useAppSelector(selectBrokeragesListById);
  const me = useAppSelector(selectMe);
  const isLoadingUsers = useAppSelector(loadingUsers);
  const isLoadingBrokerages = useAppSelector(loadingBrokerages);

  useEffect(() => {
    dispatch(fetchUsersList());
    dispatch(fetchBrokeragesList());
  }, [dispatch]);

  const handleToggleActive = async (userId: number, isActive: boolean) => {
    await dispatch(toggleStatus({ userId, isActive }));
  };

  const handleClickEdit = (userId: User["id"]) => {
    dispatch(setSelectedUserId(userId));
    dispatch(toggleDialog({ dialog: "EDIT_USER", open: true }));
  };

  const handleClickAdd = () => {
    dispatch(toggleDialog({ dialog: "ADD_USER", open: true }));
  };

  const handleClickRemove = (userId: number) => {
    dispatch(deleteById({ userId }));
  };

  const handleClickResetUserPassword = (userId: number) => {
    dispatch(setSelectedUserId(userId));
    dispatch(toggleDialog({ dialog: "CHANGE_USER_PASSWORD", open: true }));
  };

  return (
    <>
      <EditUserDialog />
      <AddUserDialog />
      <ChangePasswordDialog />
      <UsersTable
        brokerages={brokeragesById}
        loading={isLoadingBrokerages || isLoadingUsers}
        users={users}
        myId={me?.id}
        onClickToggleActive={handleToggleActive}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickRemove={handleClickRemove}
        onResetPassword={handleClickResetUserPassword}
      />
    </>
  );
}

export default UsersSettings;
