import { parse, addDays } from "date-fns";
import { useAppSelector } from "./redux-hooks";
import type {
  PDFQuotePubLiabDetails,
  PublicLiabilityQuoteFormState,
  WrkCmpQuoteFormState,
} from "../lib/types";
import { selectClientQuote } from "../lib/store/features/quotes.slice";
import { selectParentBrokerage } from "../lib/store/features/brokerages.slice";
import { makeSelectAllQuoteData } from "../lib/store/features/quoteData.slice";
import {
  calculatePremium,
  calculateTppdLimitIncreaseAmount,
} from "../lib/helpers";
import {
  QUOTE_INFO,
  WRK_CMP_QUOTE_TYPES,
  PL_QUOTE_TYPES,
} from "../lib/constants";
import type {
  QuoteFormData,
  QuoteFormState,
  QuoteType,
  PDFQuoteVehicleDetails,
  PDFQuoteWrkCmpDetails,
} from "../lib/types";
import { selectWrkCmpOptionalExtras } from "../lib/store/features/quoteData.slice";

export function usePdfCoCDetails() {
  const clientQuote = useAppSelector(selectClientQuote);
  const wrkCmpOptionalExtras = useAppSelector(selectWrkCmpOptionalExtras);
  const parentBrokerage = useAppSelector(selectParentBrokerage);
  const allQuoteData = useAppSelector(makeSelectAllQuoteData());
  const calculatePremiumValue =
    (quoteType: QuoteType) => (sumInsured: number) => {
      if (!allQuoteData[quoteType]) return 0;

      return calculatePremium({
        sumInsured,
        quoteData: allQuoteData[quoteType] as QuoteFormData,
      });
    };

  const calculateTppdLimitIncreaseValue =
    (
      quoteType: QuoteType,
      insuranceValues: QuoteFormState["insuranceValues"]
    ) =>
    (premium: number) => {
      if (!allQuoteData[quoteType]) return 0;

      return calculateTppdLimitIncreaseAmount({
        quoteData: allQuoteData[quoteType] as QuoteFormData,
        premium,
        insuranceValues,
      });
    };

  const withNamedDriverDiscount = ["business", "private"];

  // const linkProps = {
  const clientDetails = {
    isPrivate: clientQuote.client.isPrivate,
    clientTitle: clientQuote.client.clientTitle,
    clientFirstName: clientQuote.client.clientFirstName,
    clientLastName: clientQuote.client.clientLastName,
    clientAddress: clientQuote.client.clientAddress,
  };
  const agentDetails = {
    agentName: "",
    agentPhone: "",
    agentEmail: "",
    brokerageName: parentBrokerage?.name ?? "Trans Pacific Assurance (SI) Ltd",
    brokerageAddressOne:
      parentBrokerage?.address_line_one ??
      "Ground Level, City Centre Building\nMendana Avenue",
    brokerageAddressTwo:
      parentBrokerage?.address_line_two ??
      "PO Box 231\nHoniara, SI01\nSolomon Islands",
    brokerageEmail: parentBrokerage?.email ?? "info@transpacific.com.sb",
    brokeragePhone: parentBrokerage?.phone ?? "(+677) 22955",
    brokerageLogo: parentBrokerage?.logo ?? "",
    brokerageWebsite: "www.transpacific.com.sb",
    prefixes: {
      phone: "Ph",
      email: "Email",
      website: "Web",
    },
  };
  const quoteDetails = {
    fileName: `certificate-of-currency-${
      clientQuote.quoteReference || "quote"
    }.pdf`,
    reference: clientQuote.quoteReference,
    dates: {
      quoteDate: clientQuote.effectiveDate
        ? parse(clientQuote.effectiveDate, "yyyy-MM-dd", new Date())
        : new Date(),
      expiryDate: clientQuote.effectiveDate
        ? addDays(
            parse(clientQuote.effectiveDate, "yyyy-MM-dd", new Date()),
            30
          )
        : new Date(),
      acceptedDate:
        clientQuote.quoteStatus === "accepted"
          ? clientQuote.acceptedDate
            ? parse(clientQuote.acceptedDate, "yyyy-MM-dd", new Date())
            : clientQuote.effectiveDate
            ? parse(clientQuote.effectiveDate, "yyyy-MM-dd", new Date())
            : new Date()
          : new Date(),
    },
    products: clientQuote.products.map(({ data, quoteType }) => {
      if (WRK_CMP_QUOTE_TYPES.includes(quoteType)) {
        const {
          selectedCategory,
          selectedOccupation,
          wageRoll,
          claimsHistory,
          calculations: calc,
          selectedOptionalExtraAmounts: soea,
          selectedOptionalExtras: soe,
        } = data as WrkCmpQuoteFormState;
        return {
          title: QUOTE_INFO[quoteType].quoteTitle,
          quoteType,
          category: selectedCategory?.category_name ?? "",
          occupation: selectedOccupation?.occupation_name ?? "",
          wageRoll,
          rate: selectedOccupation?.rate ?? 0,
          claimsHistory: claimsHistory,
          hausKraiOption:
            wrkCmpOptionalExtras?.["Haus Krai"]?.data[soe["Haus Krai"]]
              ?.option ?? "",
          hausKraiAmount: soea["Haus Krai"],
          wethaOption:
            wrkCmpOptionalExtras?.["WETHA"]?.data[soe["WETHA"]]?.option ?? "",
          wethaAmount: soea.WETHA,
          bekimPeiOption:
            wrkCmpOptionalExtras?.["BEKIM PEI"]?.data[soe["BEKIM PEI"]]
              ?.option ?? "",
          bekimPeiAmount: soea["BEKIM PEI"],
          commonLawOption:
            wrkCmpOptionalExtras?.["Common Law"]?.data[soe["Common Law"]]
              ?.option ?? "",
          commonLawAmount: soea["Common Law"],
          minPremium:
            allQuoteData?.workers_compensation?.minimumPremium?.premium ?? 0,
          sumInsuredPremium: calc.sumInsuredPremium,
          optionalExtras: calc.optionalExtras,
          subTotal: calc.subTotal,
        } as PDFQuoteWrkCmpDetails;
      } else if (PL_QUOTE_TYPES.includes(quoteType)) {
        const {
          selectedCategory,
          selectedOccupation,
          turnover,
          claimsHistory,
          selectedLOLValues,
          selectedLOLAmounts,
          lolPremiumAmount,
        } = data as PublicLiabilityQuoteFormState;

        return {
          title: QUOTE_INFO[quoteType].quoteTitle,
          quoteType,
          category: selectedCategory?.category_name ?? "",
          occupation: selectedOccupation?.occupation_name ?? "",
          turnover,
          rate: selectedOccupation?.rate ?? 0,
          claimsHistory: claimsHistory,
          selectedLOLValues,
          selectedLOLAmounts,
          lolPremium: lolPremiumAmount,
        } as PDFQuotePubLiabDetails;
      } else {
        const {
          clientVehicleValues,
          calculations,
          insuranceValues,
          quoteYears,
        } = data as QuoteFormState;

        return {
          title: QUOTE_INFO[quoteType].quoteTitle,
          vehicle: {
            ...clientVehicleValues,
          },
          quoteType,
          withNamedDriverDiscount: withNamedDriverDiscount.includes(quoteType),
          insuranceValues,
          yearsRequired: quoteYears,
          calculations,
          calculationFunctions: {
            calculatePremium: calculatePremiumValue(quoteType),
            calculateExtras: calculateTppdLimitIncreaseValue(
              quoteType,
              insuranceValues
            ),
          },
          minPremium: allQuoteData[quoteType]?.minimumPremium.premium || 0,
        } as PDFQuoteVehicleDetails;
      }
    }),
  };

  return {
    clientDetails,
    agentDetails,
    quoteDetails,
  };
}
