import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type { RootState, Dialog } from "../../types";

type DialogsState = {
  [key in Dialog]: boolean;
};

const initialState: DialogsState = {
  ADD_USER: false,
  EDIT_USER: false,
  EDIT_BROKERAGE_QUOTE_TYPE_ACCESS: false,
  ADD_BROKERAGE: false,
  EDIT_BROKERAGE: false,
  BROKERAGE_LOGO: false,
  EDIT_MY_PROFILE: false,
  CHANGE_PASSWORD: false,
  CHANGE_USER_PASSWORD: false,
  CREATE_QUOTE: false,
  EDIT_QUOTE: false,
  CHANGE_QUOTE_STATUS: false,
};

export const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    toggle: (
      state,
      action: PayloadAction<{
        dialog: Dialog;
        open?: boolean;
        passThroughData?: any;
      }>
    ) => {
      state[action.payload.dialog] = action.payload?.open
        ? action.payload?.open
        : !state[action.payload.dialog];
    },
  },
});

// Actions
export const { toggle } = dialogsSlice.actions;

// Selectors
const selectDialogs = (state: RootState) => state.dialogs;
export const selectDialogOpen = (dialog: Dialog) =>
  createSelector(selectDialogs, (dialogs) => dialogs[dialog]);

export default dialogsSlice.reducer;
