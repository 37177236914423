import BrokerageLogoDialog from "../../../../components/BrokerageLogoDialog";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  toggle,
  selectDialogOpen,
} from "../../../../lib/store/features/dialogs.slice";
import {
  selectedBrokerage,
  setSelectedBrokerageId,
  uploadLogo,
} from "../../../../lib/store/features/brokerages.slice";
import { enqueue as showNotif } from "../../../../lib/store/features/notifications.slice";

function LogoAdd() {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectDialogOpen("BROKERAGE_LOGO"));
  const brokerage = useAppSelector(selectedBrokerage);

  const handleClose = () => {
    dispatch(toggle({ dialog: "BROKERAGE_LOGO", open: false }));
    setTimeout(() => {
      dispatch(setSelectedBrokerageId(null));
    }, 250);
  };

  const handleSubmit = async (file: File) => {
    if (!brokerage) {
      dispatch(
        showNotif({
          message: "No brokerage selected",
          options: { variant: "error" },
        })
      );

      return false;
    }

    const result = await dispatch(uploadLogo({ id: brokerage.id, logo: file }));

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return false;
    }

    dispatch(
      showNotif({
        message: "Brokerage logo successfully uploaded",
        options: { variant: "success" },
      })
    );

    handleClose();

    return true;
  };

  if (!brokerage) return null;

  return (
    <BrokerageLogoDialog
      open={open}
      logo={brokerage?.logo}
      name={brokerage?.name}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
}

export default LogoAdd;
