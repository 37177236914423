import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";

interface Props {
  homeLink?: string;
}

export default function PageNotFound({ homeLink }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: grey[100],
      }}
    >
      <Typography variant="h1" style={{ color: grey[500] }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: grey[500], marginBottom: 8 }}>
        We cannot find the page you're looking for.
      </Typography>
      {!!homeLink && (
        <Typography variant="body2" style={{ color: grey[500] }}>
          Click <Link to={homeLink}>here</Link> to go back home.
        </Typography>
      )}
    </Box>
  );
}
