import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  API as ApiType,
  OptionalExtra,
  ApiGetOptionalExtrasResponse,
  ApiGetLastModifiedResponse,
  ApiGetQuoteDataResponse,
  ApiSaveQuoteDataResponse,
  QuoteType,
  QuoteFormDataObj,
  QuoteDataFormValuesSave,
  ApiGetQuoteTypesResponse,
  QuoteTypeAccess,
  ApiGetWrkCmpCategoriesResponse,
  WorkCategory,
  WorkOccupation,
  ApiGetWrkCmpOccupationsResponse,
  ApiGetQuoteFeaturesResponse,
  QuoteFeatureAccess,
  ApiGetStampDutyResponse,
} from "../../types";
import * as quoteDataApi from "../../api/handlers/quoteData";
import { handleError } from "../../helpers";

const extractQuoteData = (quoteData: any) => {
  const {
    // eslint-disable-next-line
    modifiedOn, // this field is stored in "lastModified[server][QUOTE_TYPE]"
    optionalExtras,
  } = quoteData;

  return {
    ...quoteData,
    optionalExtras: optionalExtras
      ? optionalExtras.reduce((acc: any, cur: any) => {
          const index = acc.findIndex((o: any) => o.title === cur.title);
          if (index < 0) {
            const oe = {
              title: cur.title,
              data: [
                {
                  id: "1",
                  option: isNaN(parseFloat(cur.optionText))
                    ? cur.optionText
                    : parseFloat(cur.optionText),
                  discountPercentage: cur.discountPercentage,
                },
              ],
            };
            acc.push(oe);
          } else {
            acc[index].data.push({
              id: `${acc[index].data.length + 1}`,
              option: isNaN(parseFloat(cur.optionText))
                ? cur.optionText
                : parseFloat(cur.optionText),
              discountPercentage: cur.discountPercentage,
            });
          }
          return acc;
        }, [])
      : [],
  };
};

interface QuoteDataState {
  quoteData: QuoteFormDataObj;
  optionalExtras: OptionalExtra[];
  wrkCmpCategories: Array<WorkCategory>;
  wrkCmpCategoryOccupations: Array<WorkOccupation>;
  pubLiabCategories: Array<WorkCategory>;
  pubLiabCategoryOccupations: Array<WorkOccupation>;
  quoteTypes: Array<QuoteTypeAccess>;
  quoteFeatures: Array<QuoteFeatureAccess>;
  lastModified: {
    server: {
      private: null | string;
      business: null | string;
      commercial: null | string;
      pmv: null | string;
      taxihirecar: null | string;
      workers_compensation: null | string;
      public_liability: null | string;
    };
    local: {
      private: null | string;
      business: null | string;
      commercial: null | string;
      pmv: null | string;
      taxihirecar: null | string;
      workers_compensation: null | string;
      public_liability: null | string;
    };
  };
  stampDuty: number;
  api: ApiType;
  apiLastModified: ApiType;
  apiQuoteTypes: ApiType;
  apiQuoteFeatures: ApiType;
  apiQuoteData: ApiType;
  apiWCCategories: ApiType;
  apiWCOccupations: ApiType;
  apiPLCategories: ApiType;
  apiPLOccupations: ApiType;
  apiSaveQuoteData: ApiType;
  apiStampDuty: ApiType;
}

const initialState: QuoteDataState = {
  quoteData: {
    private: undefined,
    business: undefined,
    commercial: undefined,
    pmv: undefined,
    taxihirecar: undefined,
    workers_compensation: undefined,
    public_liability: undefined,
  },
  optionalExtras: [],
  quoteTypes: [],
  quoteFeatures: [],
  wrkCmpCategories: [],
  wrkCmpCategoryOccupations: [],
  pubLiabCategories: [],
  pubLiabCategoryOccupations: [],
  lastModified: {
    server: {
      private: null,
      business: null,
      commercial: null,
      pmv: null,
      taxihirecar: null,
      workers_compensation: null,
      public_liability: null,
    },
    local: {
      private: null,
      business: null,
      commercial: null,
      pmv: null,
      taxihirecar: null,
      workers_compensation: null,
      public_liability: null,
    },
  },
  stampDuty: 0,
  api: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiLastModified: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiPLCategories: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiPLOccupations: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiWCCategories: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiWCOccupations: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiQuoteData: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiQuoteTypes: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiQuoteFeatures: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiSaveQuoteData: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  apiStampDuty: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const getStampDuty = createAsyncThunk(
  "quoteData/getStampDuty",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { apiStampDuty } = (getState() as RootState).quoteData;
      if (
        apiStampDuty.loading !== "pending" ||
        apiStampDuty.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quoteDataApi.fetchStampDuty()) as any;
      return response.data as ApiGetStampDutyResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getWrkCmpCategories = createAsyncThunk(
  "quoteData/getWrkCmpCategories",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { apiWCCategories } = (getState() as RootState).quoteData;
      if (
        apiWCCategories.loading !== "pending" ||
        apiWCCategories.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quoteDataApi.fetchWrkCmpCategories()) as any;
      return response.data as ApiGetWrkCmpCategoriesResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getWrkCmpCategoryOccupations = createAsyncThunk(
  "quoteData/getWrkCmpCategoryOccupations",
  async (
    { categoryId }: { categoryId: WorkCategory["id"] },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { apiWCOccupations } = (getState() as RootState).quoteData;
      if (
        apiWCOccupations.loading !== "pending" ||
        apiWCOccupations.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quoteDataApi.fetchWrkCmpCategoryOccupations(
        categoryId
      )) as any;
      return response.data as ApiGetWrkCmpOccupationsResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getPubLiabCategories = createAsyncThunk(
  "quoteData/getPubLiabCategories",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { apiPLCategories } = (getState() as RootState).quoteData;
      if (
        apiPLCategories.loading !== "pending" ||
        apiPLCategories.requestId !== requestId
      ) {
        return null;
      }

      const response =
        (await quoteDataApi.fetchPublicLiabilityCategories()) as any;
      return response.data as ApiGetWrkCmpCategoriesResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getPubLiabCategoryOccupations = createAsyncThunk(
  "quoteData/getPubLiabCategoryOccupations",
  async (
    { categoryId }: { categoryId: WorkCategory["id"] },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { apiPLOccupations } = (getState() as RootState).quoteData;
      if (
        apiPLOccupations.loading !== "pending" ||
        apiPLOccupations.requestId !== requestId
      ) {
        return null;
      }

      const response =
        (await quoteDataApi.fetchPublicLiabilityCategoryOccupations(
          categoryId
        )) as any;
      return response.data as ApiGetWrkCmpOccupationsResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getOptionalExtras = createAsyncThunk(
  "quoteData/getOptionalExtras",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { api } = (getState() as RootState).quoteData;
      if (api.loading !== "pending" || api.requestId !== requestId) {
        return null;
      }

      const response = (await quoteDataApi.fetchOptionalExtras()) as any;
      return response.data as ApiGetOptionalExtrasResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getLastModifiedDates = createAsyncThunk(
  "quoteData/getLastModifiedDates",
  async (
    { quoteType }: { quoteType: QuoteType | "all" },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { apiLastModified } = (getState() as RootState).quoteData;
      if (
        apiLastModified.loading !== "pending" ||
        apiLastModified.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quoteDataApi.fetchLastModified({
        quoteType,
      })) as any;
      return response.data as ApiGetLastModifiedResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getQuoteTypes = createAsyncThunk(
  "quotes/getQuoteTypes",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { apiQuoteTypes } = (getState() as RootState).quoteData;
      if (
        apiQuoteTypes.loading !== "pending" ||
        apiQuoteTypes.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quoteDataApi.fetchQuoteTypes()) as any;
      return response.data as ApiGetQuoteTypesResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const getQuoteFeatures = createAsyncThunk(
  "quotes/getQuoteFeatures",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { apiQuoteFeatures } = (getState() as RootState).quoteData;
      if (
        apiQuoteFeatures.loading !== "pending" ||
        apiQuoteFeatures.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quoteDataApi.fetchQuoteFeatures()) as any;
      return response.data as ApiGetQuoteFeaturesResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

const fetchQuoteData = async (requiredQuoteTypes: QuoteType[]) => {
  const quoteData: QuoteFormDataObj = {};
  await requiredQuoteTypes.reduce(async (promise, quoteType) => {
    return promise
      .then(async () => {
        const response = await quoteDataApi.fetchQuoteData({ quoteType });
        quoteData[quoteType] = response.data.quoteData;
      })
      .catch((error) => {
        throw new Error(error.message || "Get Quote data failed");
      });
  }, Promise.resolve());
  return quoteData;
};

export const getQuoteData = createAsyncThunk(
  "quoteData/getQuoteData",
  async (
    { quoteTypes }: { quoteTypes: QuoteType[] },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { apiQuoteData } = (getState() as RootState).quoteData;
      if (
        apiQuoteData.loading !== "pending" ||
        apiQuoteData.requestId !== requestId
      ) {
        return null;
      }

      const data = (await fetchQuoteData(quoteTypes)) as any;
      return data as ApiGetQuoteDataResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const saveQuoteData = createAsyncThunk(
  "quoteData/saveQuoteData",
  async (
    {
      quoteType,
      quoteData,
    }: { quoteType: QuoteType; quoteData: QuoteDataFormValuesSave },
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { apiSaveQuoteData } = (getState() as RootState).quoteData;
      if (
        apiSaveQuoteData.loading !== "pending" ||
        apiSaveQuoteData.requestId !== requestId
      ) {
        return null;
      }

      const response = (await quoteDataApi.saveQuoteData({
        quoteData,
        quoteType,
      })) as any;

      return response.data as ApiSaveQuoteDataResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const quoteDataSlice = createSlice({
  name: "quoteData",
  initialState,
  reducers: {
    // example: (state, action) => {
    // },
  },
  extraReducers: (builder) => {
    /* Get Quote Data */
    builder.addCase(getQuoteData.pending, (state, { meta }) => {
      state.apiQuoteData.loading = "pending";
      state.apiQuoteData.requestId = meta.requestId;
      state.apiQuoteData.error = null;
    });
    builder.addCase(getQuoteData.fulfilled, (state, { payload, meta }) => {
      state.apiQuoteData.loading = "idle";
      state.apiQuoteData.requestId = undefined;
      state.apiQuoteData.error = null;

      state.quoteData = {
        ...state.quoteData,
        private: payload.private
          ? extractQuoteData({ ...payload.private })
          : state.quoteData.private,
        business: payload.business
          ? extractQuoteData({ ...payload.business })
          : state.quoteData.business,
        commercial: payload.commercial
          ? extractQuoteData({ ...payload.commercial })
          : state.quoteData.commercial,
        pmv: payload.pmv
          ? extractQuoteData({ ...payload.pmv })
          : state.quoteData.pmv,
        taxihirecar: payload.taxihirecar
          ? extractQuoteData({ ...payload.taxihirecar })
          : state.quoteData.taxihirecar,
        workers_compensation: payload.workers_compensation
          ? extractQuoteData({ ...payload.workers_compensation })
          : state.quoteData.workers_compensation,
        public_liability: payload.public_liability
          ? extractQuoteData({ ...payload.public_liability })
          : state.quoteData.public_liability,
      };

      state.lastModified = {
        ...state.lastModified,
        local: {
          private:
            payload.private?.modifiedOn ?? state.lastModified.local.private,
          business:
            payload.business?.modifiedOn ?? state.lastModified.local.business,
          commercial:
            payload.commercial?.modifiedOn ??
            state.lastModified.local.commercial,
          pmv: payload.pmv?.modifiedOn ?? state.lastModified.local.pmv,
          taxihirecar:
            payload.taxihirecar?.modifiedOn ??
            state.lastModified.local.taxihirecar,
          workers_compensation:
            payload.workers_compensation?.modifiedOn ??
            state.lastModified.local.workers_compensation,
          public_liability:
            payload.public_liability?.modifiedOn ??
            state.lastModified.local.public_liability,
        },
      };
    });
    builder.addCase(getQuoteData.rejected, (state, action) => {
      state.apiQuoteData.loading = "error";
      state.apiQuoteData.requestId = undefined;
      state.apiQuoteData.error = action.error as string;
    });

    /* Get Stamp Duty */
    builder.addCase(getStampDuty.pending, (state, { meta }) => {
      state.apiStampDuty.loading = "pending";
      state.apiStampDuty.requestId = meta.requestId;
      state.apiStampDuty.error = null;
    });
    builder.addCase(getStampDuty.fulfilled, (state, { payload, meta }) => {
      state.apiStampDuty.loading = "idle";
      state.apiStampDuty.requestId = undefined;
      state.apiStampDuty.error = null;

      state.stampDuty = payload.stampDuty;
    });
    builder.addCase(getStampDuty.rejected, (state, action) => {
      state.apiStampDuty.loading = "idle";
      state.apiStampDuty.requestId = undefined;
      state.apiStampDuty.error = action.error as string;
    });

    /* Save Quote Data */
    builder.addCase(saveQuoteData.pending, (state, { meta }) => {
      state.apiSaveQuoteData.loading = "pending";
      state.apiSaveQuoteData.requestId = meta.requestId;
      state.apiSaveQuoteData.error = null;
    });
    builder.addCase(saveQuoteData.fulfilled, (state, { payload, meta }) => {
      state.apiSaveQuoteData.loading = "idle";
      state.apiSaveQuoteData.requestId = undefined;
      state.apiSaveQuoteData.error = null;

      if (payload.lastModified) {
        const { quoteData, quoteType } = meta.arg;

        state.quoteData = {
          ...state.quoteData,
          [quoteType]: {
            ...state.quoteData[quoteType],
            claimsFreeDiscounts: [...quoteData.claimsFreeDiscounts],
            discountsIncreasedExcess: [...quoteData.discountsIncreasedExcess],
            minimumPremium: {
              ...(state.quoteData[quoteType] as any).minimumPremium,
              premium: quoteData.minimumPremium,
            },
            optionalExtras: quoteData.optionalExtras.map((oeTitle) =>
              state.optionalExtras.find((oe: any) => oe.title === oeTitle)
            ),
            standardExcess: [...quoteData.standardExcess],
            sumInsuredPremiumRates: [...quoteData.sumInsuredPremiumRates],
          },
        };

        // Don't save the lastModified. There is a bug in the above code that is saving the data in the
        // incorrect format for the "Create Quote" to use. But this quote data is refreshed when the user
        // goes to create the quote anyway, as it checks for the lastModified date, which will not be correct
        // so it will refresh the data and save it correctly using the getQuoteData action.

        // state.lastModified = {
        //   server: {
        //     ...state.lastModified.server,
        //     [quoteType]: payload.lastModified,
        //   },
        //   local: {
        //     ...state.lastModified.local,
        //     [quoteType]: payload.lastModified,
        //   },
        // };
      }
    });
    builder.addCase(saveQuoteData.rejected, (state, action) => {
      state.apiSaveQuoteData.loading = "error";
      state.apiSaveQuoteData.requestId = undefined;
      state.apiSaveQuoteData.error = action.error as string;
    });

    /* Get Quote Types */
    builder.addCase(getQuoteTypes.pending, (state, { meta }) => {
      state.apiQuoteTypes.loading = "pending";
      state.apiQuoteTypes.requestId = meta.requestId;
      state.apiQuoteTypes.error = null;
    });
    builder.addCase(getQuoteTypes.fulfilled, (state, { payload }) => {
      state.apiQuoteTypes.loading = "idle";
      state.apiQuoteTypes.requestId = undefined;
      state.apiQuoteTypes.error = null;

      if (payload) {
        state.quoteTypes = payload;
      }
    });
    builder.addCase(getQuoteTypes.rejected, (state, action) => {
      state.apiQuoteTypes.loading = "error";
      state.apiQuoteTypes.requestId = undefined;
      state.apiQuoteTypes.error = action.error as string;
    });

    /* Get Quote Features */
    builder.addCase(getQuoteFeatures.pending, (state, { meta }) => {
      state.apiQuoteFeatures.loading = "pending";
      state.apiQuoteFeatures.requestId = meta.requestId;
      state.apiQuoteFeatures.error = null;
    });
    builder.addCase(getQuoteFeatures.fulfilled, (state, { payload }) => {
      state.apiQuoteFeatures.loading = "idle";
      state.apiQuoteFeatures.requestId = undefined;
      state.apiQuoteFeatures.error = null;

      if (payload) {
        state.quoteFeatures = payload;
      }
    });
    builder.addCase(getQuoteFeatures.rejected, (state, action) => {
      state.apiQuoteFeatures.loading = "error";
      state.apiQuoteFeatures.requestId = undefined;
      state.apiQuoteFeatures.error = action.error as string;
    });

    /* Get Workers Compensation Categories */
    builder.addCase(getWrkCmpCategories.pending, (state, { meta }) => {
      state.apiWCCategories.loading = "pending";
      state.apiWCCategories.requestId = meta.requestId;
      state.apiWCCategories.error = null;
    });
    builder.addCase(getWrkCmpCategories.fulfilled, (state, { payload }) => {
      state.apiWCCategories.loading = "idle";
      state.apiWCCategories.requestId = undefined;
      state.apiWCCategories.error = null;

      if (payload) {
        state.wrkCmpCategories = [...payload];
      }
    });
    builder.addCase(getWrkCmpCategories.rejected, (state, action) => {
      state.apiWCCategories.loading = "error";
      state.apiWCCategories.requestId = undefined;
      state.apiWCCategories.error = action.error as string;
    });

    /* Get Workers Compensation Category Occupations */
    builder.addCase(getWrkCmpCategoryOccupations.pending, (state, { meta }) => {
      state.apiWCOccupations.loading = "pending";
      state.apiWCOccupations.requestId = meta.requestId;
      state.apiWCOccupations.error = null;
    });
    builder.addCase(
      getWrkCmpCategoryOccupations.fulfilled,
      (state, { payload }) => {
        state.apiWCOccupations.loading = "idle";
        state.apiWCOccupations.requestId = undefined;
        state.apiWCOccupations.error = null;

        if (payload) {
          state.wrkCmpCategoryOccupations = [...payload];
        }
      }
    );
    builder.addCase(getWrkCmpCategoryOccupations.rejected, (state, action) => {
      state.apiWCOccupations.loading = "error";
      state.apiWCOccupations.requestId = undefined;
      state.apiWCOccupations.error = action.error as string;
    });

    /* Get Public Liability Categories */
    builder.addCase(getPubLiabCategories.pending, (state, { meta }) => {
      state.apiPLCategories.loading = "pending";
      state.apiPLCategories.requestId = meta.requestId;
      state.apiPLCategories.error = null;
    });
    builder.addCase(getPubLiabCategories.fulfilled, (state, { payload }) => {
      state.apiPLCategories.loading = "idle";
      state.apiPLCategories.requestId = undefined;
      state.apiPLCategories.error = null;

      if (payload) {
        state.pubLiabCategories = [...payload];
      }
    });
    builder.addCase(getPubLiabCategories.rejected, (state, action) => {
      state.apiPLCategories.loading = "error";
      state.apiPLCategories.requestId = undefined;
      state.apiPLCategories.error = action.error as string;
    });

    /* Get Public Liability Category Occupations */
    builder.addCase(
      getPubLiabCategoryOccupations.pending,
      (state, { meta }) => {
        state.apiPLOccupations.loading = "pending";
        state.apiPLOccupations.requestId = meta.requestId;
        state.apiPLOccupations.error = null;
      }
    );
    builder.addCase(
      getPubLiabCategoryOccupations.fulfilled,
      (state, { payload }) => {
        state.apiPLOccupations.loading = "idle";
        state.apiPLOccupations.requestId = undefined;
        state.apiPLOccupations.error = null;

        if (payload) {
          state.pubLiabCategoryOccupations = [...payload];
        }
      }
    );
    builder.addCase(getPubLiabCategoryOccupations.rejected, (state, action) => {
      state.apiPLOccupations.loading = "error";
      state.apiPLOccupations.requestId = undefined;
      state.apiPLOccupations.error = action.error as string;
    });

    /* Get Optional Extras */
    builder.addCase(getOptionalExtras.pending, (state, { meta }) => {
      state.api.loading = "pending";
      state.api.requestId = meta.requestId;
      state.api.error = null;
    });
    builder.addCase(getOptionalExtras.fulfilled, (state, { payload }) => {
      state.api.loading = "idle";
      state.api.requestId = undefined;
      state.api.error = null;

      if (payload.optionalExtras) {
        state.optionalExtras = payload.optionalExtras;
      }
    });
    builder.addCase(getOptionalExtras.rejected, (state, action) => {
      state.api.loading = "error";
      state.api.requestId = undefined;
      state.api.error = action.error as string;
    });

    /* Get Last Modified Date */
    builder.addCase(getLastModifiedDates.pending, (state, { meta }) => {
      state.apiLastModified.loading = "pending";
      state.apiLastModified.requestId = meta.requestId;
      state.apiLastModified.error = null;
    });
    builder.addCase(
      getLastModifiedDates.fulfilled,
      (state, { payload, meta }) => {
        state.apiLastModified.loading = "idle";
        state.apiLastModified.requestId = undefined;
        state.apiLastModified.error = null;

        if (meta.arg.quoteType === "all") {
          const { modifiedOn } = payload;
          state.lastModified["server"] = { ...modifiedOn };
        } else {
          state.lastModified["server"][meta.arg.quoteType] = payload.modifiedOn;
        }
      }
    );
    builder.addCase(getLastModifiedDates.rejected, (state, action) => {
      state.apiLastModified.loading = "error";
      state.apiLastModified.requestId = undefined;
      state.apiLastModified.error = action.error as string;
    });

    /* Example */
    // builder.addCase(example.pending, (state, { meta }) => {
    //   state.api.loading = "pending";
    //   state.api.requestId = meta.requestId;
    //   state.api.error = null;
    // });
    // builder.addCase(example.fulfilled, (state, { payload }) => {
    //   state.api.loading = "idle";
    //   state.api.requestId = undefined;
    //   state.api.error = null;
    // });
    // builder.addCase(example.rejected, (state, action) => {
    //   state.api.loading = "error";
    //   state.api.requestId = undefined;
    //   state.api.error = action.error as string;
    // });
  },
});

// Actions
// export const { example } = quoteDataSlice.actions;

// Selectors
const selector = (state: RootState) => state.quoteData;
export const selectLastModifiedDates = createSelector(
  selector,
  (data) => data.lastModified
);
export const makeSelectLastModifiedDateByType = (quoteType: QuoteType) =>
  createSelector(selector, (data) => data.lastModified.server[quoteType]);
export const selectLoadingLastModifiedDates = createSelector(
  selector,
  (data) => data.apiLastModified.loading === "pending"
);
export const makeSelectQuoteData = (quoteType: QuoteType) =>
  createSelector(selector, (data) => data.quoteData[quoteType]);
export const makeSelectAllQuoteData = () =>
  createSelector(selector, (data) => data.quoteData);

export const makeSelectStampDuty = () =>
  createSelector(selector, (data) => data.stampDuty);

export const selectWrkCmpOptionalExtras = createSelector(selector, (data) => {
  const defaultExtras = {
    "Haus Krai": { data: [], title: "Haus Krai" },
    WETHA: { data: [], title: "WETHA" },
    "BEKIM PEI": { data: [], title: "BEKIM PEI" },
    "Common Law": { data: [], title: "Common Law" },
  };

  if (data.quoteData?.workers_compensation?.optionalExtras) {
    return data.quoteData.workers_compensation.optionalExtras.reduce(
      (acc: any, cur) => {
        acc[cur.title] = cur;
        return acc;
      },
      defaultExtras
    );
  }

  return defaultExtras;
});

export const selectWrkCmpCategories = createSelector(
  selector,
  (data) => data.wrkCmpCategories
);

export const selectWrkCmpCategoryOccupations = createSelector(
  selector,
  (data) => data.wrkCmpCategoryOccupations
);

export const selectPubLiabCategories = createSelector(
  selector,
  (data) => data.pubLiabCategories
);

export const selectPubLiabCategoryOccupations = createSelector(
  selector,
  (data) => data.pubLiabCategoryOccupations
);

export const selectLoadingQuoteData = createSelector(
  selector,
  (data) => data.apiQuoteData.loading === "pending"
);

export const selectSavingQuoteData = createSelector(
  selector,
  (data) => data.apiSaveQuoteData.loading === "pending"
);

export const selectQuoteTypes = createSelector(
  selector,
  (data) => data.quoteTypes
);

export const selectQuoteFeatures = createSelector(
  selector,
  (data) => data.quoteFeatures
);

export const selectLoadingQuoteTypes = createSelector(
  selector,
  (data) => data.apiQuoteTypes.loading === "pending"
);

export const selectLoadingQuoteFeatures = createSelector(
  selector,
  (data) => data.apiQuoteFeatures.loading === "pending"
);

export const selectLoadingWrkCmpCategories = createSelector(
  selector,
  (data) => data.apiWCCategories.loading === "pending"
);

export const selectLoadingWrkCmpCategoryOccupations = createSelector(
  selector,
  (data) => data.apiWCOccupations.loading === "pending"
);

export const selectLoadingPubLiabCategories = createSelector(
  selector,
  (data) => data.apiPLCategories.loading === "pending"
);

export const selectLoadingPubLiabCategoryOccupations = createSelector(
  selector,
  (data) => data.apiPLOccupations.loading === "pending"
);

export default quoteDataSlice.reducer;
