import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import isPropsEqual from "react-fast-compare";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/PostAdd";
import SaveIcon from "@mui/icons-material/Save";
import AcceptIcon from "@mui/icons-material/IndeterminateCheckBox";
import NewIcon from "@mui/icons-material/AutoMode";
import { ButtonProps } from "@mui/material/Button";
import { blueGrey, lightBlue, purple } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import PDFQuoteLinkButton from "../../containers/QuotesCreate/PDF/PDFQuoteLink";
import PDFCoCLinkButton from "../../containers/QuotesCreate/PDF/PDFCoCLink";
import QuotesSearch from "../../containers/QuotesSearch";
import Badge from "../Badge";
import Section from "../forms/Section";
import TextField from "../forms/TextInput";
import Button from "../forms/Button";
import type {
  ClientFieldType,
  QuoteDateValueType,
  PDFAgentDetails,
  QuoteProduct,
  QuoteFieldErrors,
  Quote,
  WrkCmpQuoteFormState,
  QuoteFormState,
  PublicLiabilityQuoteFormState,
} from "../../lib/types";
import { useNumberFormatter } from "../../hooks";
import { QUOTE_INFO, STATUS_COLORS } from "../../lib/constants";
import arrowUpImg from "../../_assets/arrow-up.png";
import { CircularProgress } from "@mui/material";
import format from "date-fns/format";

const NewQuoteButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[600]),
  backgroundColor: blueGrey[400],
  "&:hover": {
    backgroundColor: blueGrey[500],
  },
}));

const SaveQuoteButton = styled(LoadingButton)<LoadingButtonProps>(
  ({ theme }) => ({
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[500],
    },
  })
);

const AcceptQuoteButton = styled(LoadingButton)<LoadingButtonProps>(
  ({ theme }) => ({
    color: theme.palette.getContrastText(purple[400]),
    backgroundColor: purple[400],
    "&:hover": {
      backgroundColor: purple[500],
    },
  })
);

interface Props {
  loading: boolean; // used for loading "me" details
  saving: boolean;
  hasChanges: boolean | null;
  errors: QuoteFieldErrors;

  // Client
  isPrivate: boolean;
  clientTitle: string;
  clientFirstName: string;
  clientLastName: string;
  clientAddress: string;
  // Agent name and brokerage props
  agentName: PDFAgentDetails["agentName"];
  // Quote
  quoteReference: Quote["quoteReference"];
  quoteStatus: Quote["quoteStatus"];
  products: QuoteProduct[];
  quoteDate: QuoteDateValueType;
  acceptedDate: null | QuoteDateValueType;

  // Quote Features
  isCoCFeatureEnabled: boolean;

  // Event handlers
  onChangeQuoteDateValue: ({ value }: { value: Date }) => void;
  onChangeClientValue: ({
    field,
    value,
  }: {
    field: ClientFieldType;
    value: string | number | boolean;
  }) => void;
  onNewQuote: () => void;
  onSaveQuote: () => void;
  onChangeQuoteStatus: () => void;
  onAddProduct: () => void;
  onClickProduct: (productId: number) => void;
}

function QuoteMain({
  loading,
  saving,
  errors,
  hasChanges,

  // Client
  isPrivate,
  clientTitle,
  clientFirstName,
  clientLastName,
  clientAddress,

  // Agent
  agentName,

  // Quote
  quoteReference,
  quoteStatus,
  products,
  quoteDate,
  acceptedDate,

  // Quote Features
  isCoCFeatureEnabled,

  // Event handlers
  onChangeQuoteDateValue,
  onChangeClientValue,
  onNewQuote,
  onSaveQuote,
  onChangeQuoteStatus,
  onAddProduct,
  onClickProduct,
}: Props) {
  const moneyFormatter = useNumberFormatter();

  const handleChangeClientValue =
    (field: ClientFieldType) =>
    (
      event: React.ChangeEvent<HTMLInputElement>
      // event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      const { value } = event.target;
      onChangeClientValue({ field, value });
    };

  const handleChangeDateValue = (
    date: Date | null,
    keyboardInputValue?: string | undefined
  ) => {
    if (!date) return;

    onChangeQuoteDateValue({ value: date });
  };

  const inputFields = {
    businessFields: [
      {
        name: "clientFirstName",
        disabled: loading || quoteStatus !== "pending",
        label: "Business Entity Name",
        autoComplete: "off",
        fullWidth: true,
        value: clientFirstName,
        error: errors.clientFirstName,
        onChange: handleChangeClientValue("clientFirstName"),
        grid: {
          xs: 12,
        },
      },
      {
        name: "clientAddress",
        disabled: loading || quoteStatus !== "pending",
        label: "Business Address",
        multiline: true,
        minRows: "4",
        autoComplete: "off",
        fullWidth: true,
        value: clientAddress,
        error: errors.clientAddress,
        onChange: handleChangeClientValue("clientAddress"),
        grid: {
          xs: 12,
          lg: 7,
        },
      },
    ],
    clientFields: [
      {
        name: "clientTitle",
        disabled: loading || quoteStatus !== "pending",
        label: "Title",
        autoComplete: "off",
        fullWidth: true,
        value: clientTitle,
        error: isPrivate && errors.clientTitle,
        onChange: handleChangeClientValue("clientTitle"),
        grid: {
          xs: 12,
          lg: 2,
        },
      },
      {
        name: "clientFirstName",
        disabled: loading || quoteStatus !== "pending",
        label: "Client First Name",
        autoComplete: "off",
        fullWidth: true,
        value: clientFirstName,
        error: errors.clientFirstName,
        onChange: handleChangeClientValue("clientFirstName"),
        grid: {
          xs: 12,
          lg: 5,
        },
      },
      {
        name: "clientLastName",
        disabled: loading || quoteStatus !== "pending",
        label: "Client Last Name",
        autoComplete: "off",
        fullWidth: true,
        value: clientLastName,
        error: isPrivate && errors.clientLastName,
        onChange: handleChangeClientValue("clientLastName"),
        grid: {
          xs: 12,
          lg: 5,
        },
      },
      {
        name: "clientAddress",
        disabled: loading || quoteStatus !== "pending",
        label: "Client Address",
        multiline: true,
        minRows: "4",
        autoComplete: "off",
        fullWidth: true,
        value: clientAddress,
        error: errors.clientAddress,
        onChange: handleChangeClientValue("clientAddress"),
        grid: {
          xs: 12,
          lg: 7,
        },
      },
    ],
  };

  const handleClickProduct = (productId: number) => () => {
    onClickProduct(productId);
  };

  const occupationSecondaryText = ({
    occupation,
    title,
    amount,
  }: {
    occupation: string;
    title: string;
    amount: number;
  }) => {
    let occupationText = occupation;
    // max length of occupation is 30 followed by ...
    if (occupationText.length > 30) {
      occupationText = occupation.slice(0, 30) + "...";
    }

    return `${occupationText} | ${title}: ${moneyFormatter.format(amount)}`;
  };

  const vehicleSecondaryText = ({
    make,
    model,
    year,
    sumInsured,
  }: {
    make: string;
    model: string;
    year: string;
    sumInsured: number;
  }) => {
    let text = "";
    if (make || model) {
      text = `${make} ${model}` + (!!year ? ` (${year})` : "");
    }

    if (!!sumInsured) {
      text += ` | Sum Insured: ${moneyFormatter.format(sumInsured)}`;
    }

    return text;
  };

  const productSecondaryText = (product: QuoteProduct) => {
    switch (product.quoteType) {
      case "workers_compensation":
        return occupationSecondaryText({
          occupation:
            (product.data as WrkCmpQuoteFormState)?.selectedOccupation
              ?.occupation_name ?? "",
          title: "Sum Insured Premium",
          amount: (product.data as WrkCmpQuoteFormState).calculations
            .sumInsuredPremium,
        });
      case "private":
      case "business":
      case "commercial":
      case "pmv":
      case "taxihirecar":
        return vehicleSecondaryText({
          make: (product.data as QuoteFormState).clientVehicleValues
            .vehicleMake,
          model: (product.data as QuoteFormState).clientVehicleValues
            .vehicleModel,
          year: (product.data as QuoteFormState).clientVehicleValues
            .vehicleYear,
          sumInsured: (product.data as QuoteFormState).insuranceValues
            .sumInsured,
        });
      case "public_liability":
        return occupationSecondaryText({
          occupation:
            (product.data as PublicLiabilityQuoteFormState)?.selectedOccupation
              ?.occupation_name ?? "",
          title: "Selected LOL Premium",
          amount:
            (product.data as PublicLiabilityQuoteFormState)?.lolPremiumAmount ??
            0,
        });
      default:
        return "";
    }
  };

  const fields = isPrivate
    ? inputFields.clientFields
    : inputFields.businessFields;

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          mb: 1,
        }}
        elevation={0}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <NewQuoteButton
              variant="contained"
              startIcon={<NewIcon />}
              onClick={onNewQuote}
              disabled={loading}
            >
              New Quote
            </NewQuoteButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <QuotesSearch />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={12} md={5}>
            <Stack direction="column">
              {/* Quote reference */}
              <div>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    display: "inline-block",
                    fontSize: "0.7rem",
                  }}
                >
                  REFERENCE
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    component="span"
                    sx={{
                      fontFamily: "monospace",
                      display: "inline-block",
                      ml: 1,
                    }}
                  >
                    {quoteReference || "N/A - New Quote"}
                  </Typography>
                </Typography>
              </div>

              {/* Quote status */}
              <Stack direction="row" alignItems="baseline" spacing={1} mt={0.5}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    display: "inline-block",
                    fontSize: "0.7rem",
                  }}
                >
                  STATUS
                </Typography>
                <Badge
                  status={
                    quoteStatus === "accepted"
                      ? acceptedDate
                        ? `${quoteStatus.toLocaleUpperCase()} (${format(
                            acceptedDate,
                            "dd MMM yyyy"
                          )})`
                        : quoteStatus.toLocaleUpperCase()
                      : quoteStatus.toLocaleUpperCase()
                  }
                  color={STATUS_COLORS[quoteStatus]}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={1} justifyContent="flex-end">
              {isCoCFeatureEnabled && (
                <Grid item xs={12} md={3}>
                  <PDFCoCLinkButton disabled={loading} />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <PDFQuoteLinkButton disabled={loading} />
              </Grid>
              <Grid item xs={12} md={3}>
                <AcceptQuoteButton
                  variant="contained"
                  loading={saving}
                  fullWidth
                  loadingPosition="start"
                  startIcon={<AcceptIcon />}
                  onClick={onChangeQuoteStatus}
                  disabled={
                    loading ||
                    hasChanges ||
                    quoteStatus !== "pending" ||
                    !quoteReference
                  }
                  sx={{ mb: 0.2 }}
                >
                  PROCESS
                </AcceptQuoteButton>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack direction="column" alignItems="flex-end" spacing={0}>
                  <SaveQuoteButton
                    variant="contained"
                    loading={saving}
                    fullWidth
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    onClick={onSaveQuote}
                    disabled={loading || !hasChanges}
                    sx={{ mb: 0.2 }}
                  >
                    Save{"  " + (hasChanges ? "*" : "")}
                  </SaveQuoteButton>
                  {!loading && (
                    <Typography
                      variant="caption"
                      color={hasChanges ? "error" : "grey.700"}
                    >
                      {hasChanges ? "Changes not saved" : <>{"\u00A0"}</>}
                    </Typography>
                  )}
                  {loading && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        variant="caption"
                        color={"grey.700"}
                        fontStyle="italic"
                      >
                        Loading ...
                      </Typography>
                      <CircularProgress size={12} color="inherit" />
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <form id="quoteform" autoComplete="off">
          <Grid container spacing={2}>
            {/**
             * Client Details
             **/}
            <Grid item xs={12}>
              <Section title="Client">
                <Grid container spacing={2} direction="row">
                  {/**
                   * Client Type
                   **/}
                  <Grid item xs={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={isPrivate} />}
                        label="Private"
                        sx={{
                          color: isPrivate ? undefined : "rgba(0, 0, 0, 0.9)",
                        }}
                        onClick={() =>
                          onChangeClientValue({
                            field: "isPrivate",
                            value: true,
                          })
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox checked={!isPrivate} />}
                        label="Business"
                        sx={{
                          color: !isPrivate ? undefined : "rgba(0, 0, 0, 0.9)",
                        }}
                        onClick={() =>
                          onChangeClientValue({
                            field: "isPrivate",
                            value: false,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>

                  {fields.map(({ name, grid, ...fieldProps }) => (
                    <Grid key={name} item {...grid}>
                      <TextField
                        name={name}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        {...fieldProps}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Section>
            </Grid>

            {/**
             * Agent Details
             **/}
            <Grid item xs={12}>
              <Section bottomBorder={false}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      disabled
                      label="Agent / Broker"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={agentName}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DatePicker
                      label="Quote Effective Date"
                      disabled={loading || quoteStatus !== "pending"}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                      onChange={handleChangeDateValue}
                      value={quoteDate || null}
                      inputFormat="dd MMMM yyyy"
                      disableMaskedInput
                    />
                  </Grid>
                </Grid>
              </Section>
            </Grid>

            {/* Products */}
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={loading || quoteStatus !== "pending"}
                  onClick={onAddProduct}
                >
                  Add Product ...
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Section title="Products">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {products.length === 0 ? (
                      <Stack
                        direction="row"
                        alignItems="baseline"
                        justifyContent="center"
                      >
                        <Typography
                          variant="body1"
                          textAlign="center"
                          sx={{ my: 2 }}
                          color={errors.products ? "error" : "inherit"}
                        >
                          <em>
                            Click the "Add Product" button to start a quote
                            {inputFields.clientFields[1].value
                              ? ` for ${inputFields.clientFields[1].value}`
                              : ""}
                          </em>
                        </Typography>
                        <img
                          src={arrowUpImg}
                          width="58"
                          height="35"
                          alt="arrow up"
                        />
                      </Stack>
                    ) : (
                      <List component="nav">
                        {products.map((product) => (
                          <ListItemButton
                            key={product.id}
                            disabled={loading}
                            onClick={handleClickProduct(product.id)}
                          >
                            <ListItemText
                              primary={QUOTE_INFO[product.quoteType]?.subTitle}
                              secondary={productSecondaryText(product)}
                              sx={{ textTransform: "capitalize" }}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    )}
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default React.memo(QuoteMain, isPropsEqual);
