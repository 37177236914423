import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
  RootState,
  API as ApiType,
  OtherSettings,
  ApiFetchOtherSettingsResponse,
  OtherSettingsFormValues,
} from "../../types";
import { handleError } from "../../helpers";
import * as settingsApi from "../../api/handlers/settings";

interface SettingsOtherState {
  other: OtherSettings["other_settings"];
  otherApiFetching: ApiType;
  otherApiSaving: ApiType;
}

const initialState: SettingsOtherState = {
  other: {
    stamp_duty: 0,
  },
  otherApiFetching: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
  otherApiSaving: {
    loading: "idle",
    error: null,
    requestId: undefined,
  },
};

export const fetchOtherSettings = createAsyncThunk(
  "settings-other/fetchOtherSettings",
  async (_, { getState, requestId, rejectWithValue }) => {
    try {
      const { otherApiFetching } = (getState() as RootState).settingsOther;
      if (
        otherApiFetching.loading !== "pending" ||
        otherApiFetching.requestId !== requestId
      ) {
        return handleError(rejectWithValue)("Request in progress");
      }

      const response = (await settingsApi.fetchOtherSettings()) as any;
      return response.data as ApiFetchOtherSettingsResponse;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const updateOtherSettings = createAsyncThunk(
  "settings-other/updateOtherSettings",
  async (
    settings: OtherSettingsFormValues,
    { getState, requestId, rejectWithValue }
  ) => {
    try {
      const { otherApiSaving } = (getState() as RootState).settingsOther;
      if (
        otherApiSaving.loading !== "pending" ||
        otherApiSaving.requestId !== requestId
      ) {
        return handleError(rejectWithValue)("Request in progress");
      }

      // Save stamp_duty
      const stampDutyResponse = (await settingsApi.updateOtherSettingValue({
        key: "stamp_duty",
        value: settings.stamp_duty,
      })) as any;

      return stampDutyResponse.data as boolean;
    } catch (error: any) {
      return handleError(rejectWithValue)(error);
    }
  }
);

export const settingsOtherSlice = createSlice({
  name: "settingsOther",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Other Settings */
    builder.addCase(fetchOtherSettings.pending, (state, { meta }) => {
      state.otherApiFetching.loading = "pending";
      state.otherApiFetching.requestId = meta.requestId;
      state.otherApiFetching.error = null;
    });
    builder.addCase(fetchOtherSettings.fulfilled, (state, { payload }) => {
      state.otherApiFetching.loading = "idle";
      state.otherApiFetching.requestId = undefined;
      state.otherApiFetching.error = null;

      if (payload.other_settings) {
        state.other = payload.other_settings;
      }
    });
    builder.addCase(fetchOtherSettings.rejected, (state, action) => {
      state.otherApiFetching.loading = "idle";
      state.otherApiFetching.requestId = undefined;
      state.otherApiFetching.error = action.error as string;
    });

    /* Update Other Settings */
    builder.addCase(updateOtherSettings.pending, (state, { meta }) => {
      state.otherApiSaving.loading = "pending";
      state.otherApiSaving.requestId = meta.requestId;
      state.otherApiSaving.error = null;
    });
    builder.addCase(
      updateOtherSettings.fulfilled,
      (state, { payload, meta }) => {
        state.otherApiSaving.loading = "idle";
        state.otherApiSaving.requestId = undefined;
        state.otherApiSaving.error = null;

        if (meta.arg) {
          state.other = {
            stamp_duty: meta.arg.stamp_duty,
          };
        }
      }
    );
    builder.addCase(updateOtherSettings.rejected, (state, action) => {
      state.otherApiSaving.loading = "idle";
      state.otherApiSaving.requestId = undefined;
      state.otherApiSaving.error = action.error as string;
    });
  },
});

// Actions
// export const { } = settingsOtherSlice.settings;

// Selectors
const selector = (state: RootState) => state.settingsOther;
export const selectOtherSettings = createSelector(
  selector,
  (settings) => settings.other
);

export const selectIsFetching = createSelector(
  selector,
  (settings) => settings.otherApiFetching.loading !== "idle"
);

export const selectIsSaving = createSelector(
  selector,
  (settings) => settings.otherApiSaving.loading !== "idle"
);

export default settingsOtherSlice.reducer;
