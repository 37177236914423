import React, { useEffect, useState, Ref } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import PublicLiabilityForm from "../../../components/Quote/PublicLiabilityForm";
import type {
  PublicLiabilityQuoteFormState,
  WorkCategory,
  WorkOccupation,
  ClaimHistoryItem,
  PublicLiabilityLOL,
} from "../../../lib/types";
import {
  getPubLiabCategories,
  getPubLiabCategoryOccupations,
  selectLoadingPubLiabCategories,
  selectLoadingPubLiabCategoryOccupations,
  selectPubLiabCategories,
  selectPubLiabCategoryOccupations,
  makeSelectQuoteData,
  makeSelectStampDuty,
} from "../../../lib/store/features/quoteData.slice";
import { enqueue as showNotif } from "../../../lib/store/features/notifications.slice";

interface Props {
  title: string;
  subTitle: string;
  quoteTitle: string;
  color: string;
  innerRef?: Ref<any>;
  existingData?: PublicLiabilityQuoteFormState;
}

// Public Liability Quote Form
function PublicLiabilityQuoteForm({
  title,
  subTitle,
  quoteTitle,
  color,
  innerRef,
  existingData,
}: Props) {
  const [values, setValues] = useState<PublicLiabilityQuoteFormState>({
    selectedCategory: null,
    selectedOccupation: null,
    selectedLOLAmounts: {
      "K1million LOL": 0,
      "K2million LOL": 0,
      "K5million LOL": 0,
      "K10million LOL": 0,
    },
    selectedLOLValues: {
      "K1million LOL": "No",
      "K2million LOL": "No",
      "K5million LOL": "No",
      "K10million LOL": "No",
    },
    claimsHistory: [],
    loading: true,
    turnover: 0,
    lolPremiumAmount: 0,
  });
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectPubLiabCategories);
  const stampDuty = useAppSelector(makeSelectStampDuty());
  const occupations = useAppSelector(selectPubLiabCategoryOccupations);
  const loadingCategories = useAppSelector(selectLoadingPubLiabCategories);
  const loadingOccupations = useAppSelector(
    selectLoadingPubLiabCategoryOccupations
  );
  const data = useAppSelector(makeSelectQuoteData("public_liability"));

  useEffect(() => {
    const init = async () => {
      // get the categories
      const result = await dispatch(getPubLiabCategories());

      if (result.meta.requestStatus === "rejected") {
        dispatch(
          showNotif({
            message: result.payload as string,
            options: { variant: "error" },
          })
        );

        return;
      }

      // get the occupations for category with the first id
      if (result.payload && result.payload.length > 0) {
        const categoryId = existingData
          ? existingData?.selectedCategory?.id
          : result.payload[0].id;
        const occupationsResult = await dispatch(
          getPubLiabCategoryOccupations({ categoryId })
        );

        if (occupationsResult.meta.requestStatus === "rejected") {
          dispatch(
            showNotif({
              message: result.payload as string,
              options: { variant: "error" },
            })
          );

          return;
        }

        setValues((vals) => ({
          ...vals,
          selectedCategory: result.payload[0],
          ...(!!existingData && { ...existingData }),
          loading: false,
        }));
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is used for the parent node to get access to this component's state values (to store in redux)
   * @returns State
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dumpData = () => {
    return values;
  };

  // assign the dumpData function to the ref
  if (innerRef) {
    // @ts-ignore
    innerRef.current = { dumpData };
  }

  const doCalculations = (latestValues: PublicLiabilityQuoteFormState) => {
    const { turnover, selectedOccupation, selectedLOLValues } = latestValues;

    const is1MillLOLSelected = selectedLOLValues["K1million LOL"] === "Yes";
    const is2MillLOLSelected = selectedLOLValues["K2million LOL"] === "Yes";
    const is5MillLOLSelected = selectedLOLValues["K5million LOL"] === "Yes";
    const is10MillLOLSelected = selectedLOLValues["K10million LOL"] === "Yes";

    const rate = (selectedOccupation?.rate ?? 0) * 100;

    const oneMillLOLData = data?.publicLiabilityLOL?.find(
      (lol) => lol.lolName === "K1million LOL"
    ) ?? {
      minPremium: 1000, // default to K1000, as per the Public Liability execl spreadsheet
      multiplier: 1.7, // default to 1.7 as the multipler, as per the Public Liability execl spreadsheet
    };
    const twoMillLOLData = data?.publicLiabilityLOL?.find(
      (lol) => lol.lolName === "K2million LOL"
    ) ?? {
      minPremium: 1800, // default to K1800, as per the Public Liability execl spreadsheet
      multiplier: 1.7, // default to 1.7 as the multipler, as per the Public Liability execl spreadsheet
    };
    const fiveMillLOLData = data?.publicLiabilityLOL?.find(
      (lol) => lol.lolName === "K5million LOL"
    ) ?? {
      minPremium: 3500, // default to K3500, as per the Public Liability execl spreadsheet
      multiplier: 1.7, // default to 1.7 as the multipler, as per the Public Liability execl spreadsheet
    };
    const tenMillLOLData = data?.publicLiabilityLOL?.find(
      (lol) => lol.lolName === "K10million LOL"
    ) ?? {
      minPremium: 6750, // default to K6750, as per the Public Liability execl spreadsheet
      multiplier: 1.7, // default to 1.7 as the multipler, as per the Public Liability execl spreadsheet
    };

    const oneMillionLOL = turnover * rate;
    const twoMillionLOL = oneMillionLOL * (twoMillLOLData.multiplier || 0);
    const fiveMillionLOL = twoMillionLOL * (fiveMillLOLData.multiplier || 0);
    const tenMillionLOL = fiveMillionLOL * (tenMillLOLData.multiplier || 0);

    const oneMillionLOLAmt =
      oneMillionLOL < oneMillLOLData.minPremium
        ? oneMillLOLData.minPremium
        : oneMillionLOL;
    const twoMillionLOLAmt =
      twoMillionLOL < twoMillLOLData.minPremium
        ? twoMillLOLData.minPremium
        : twoMillionLOL;
    const fiveMillionLOLAmt =
      fiveMillionLOL < fiveMillLOLData.minPremium
        ? fiveMillLOLData.minPremium
        : fiveMillionLOL;
    const tenMillionLOLAmt =
      tenMillionLOL < tenMillLOLData.minPremium
        ? tenMillLOLData.minPremium
        : tenMillionLOL;

    // this is not cool - the process changed, and I didn't have time to refactor
    const lolPremAmt = is1MillLOLSelected
      ? oneMillionLOLAmt
      : is2MillLOLSelected
      ? twoMillionLOLAmt
      : is5MillLOLSelected
      ? fiveMillionLOLAmt
      : is10MillLOLSelected
      ? tenMillionLOLAmt
      : 0;

    return {
      selectedLOLAmounts: {
        "K1million LOL": oneMillionLOLAmt, // turnover * rate
        "K2million LOL": twoMillionLOLAmt, // "K1million LOL" * multiplier
        "K5million LOL": fiveMillionLOLAmt, // "K5million LOL" * multiplier
        "K10million LOL": tenMillionLOLAmt, // "K10million LOL" * multiplier
      },
      lolPremiumAmount: lolPremAmt,
    };
  };

  const handleChangeCategory = async ({
    categoryId,
  }: {
    categoryId: WorkCategory["id"];
  }) => {
    const selectedCategory = categories.find(
      (category) => category.id === categoryId
    );

    if (!selectedCategory || categoryId === values.selectedCategory?.id) {
      return;
    }

    setValues((vals) => ({
      ...vals,
      selectedCategory: selectedCategory,
      selectedOccupation: null,
      ...doCalculations({ ...vals, selectedOccupation: null }),
    }));

    const result = await dispatch(
      getPubLiabCategoryOccupations({ categoryId })
    );

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return;
    }
  };

  const handleChangeOccupation = async ({
    occupationId,
  }: {
    occupationId: WorkOccupation["id"];
  }) => {
    const selectedOccupation = occupations.find(
      (occupation) => occupation.id === occupationId
    );

    if (!selectedOccupation) {
      return;
    }

    setValues((vals) => ({
      ...vals,
      selectedOccupation: selectedOccupation,
      ...doCalculations({ ...vals, selectedOccupation }),
    }));
  };

  const handeChangeTurnoverText = ({ value }: { value: number }) => {
    setValues((vals) => {
      return {
        ...vals,
        turnover: value,
        ...doCalculations({ ...vals, turnover: value }),
      };
    });
  };

  const handleAddClaimsHistoryItem = () => {
    setValues((vals) => ({
      ...vals,
      claimsHistory: [
        ...vals.claimsHistory,
        {
          id: vals.claimsHistory.length + 1,
          year: vals.claimsHistory.length + 1,
          claim_count: 0,
          claim_amount: 0,
        },
      ],
    }));
  };

  const handleDeleteClaimsHistoryItem = (id: ClaimHistoryItem["id"]) => {
    setValues((vals) => ({
      ...vals,
      claimsHistory: vals.claimsHistory
        .filter((item) => item.id !== id)
        .map((item, index) => ({
          ...item,
          year: index + 1,
        })),
    }));
  };

  const handleChangeClaimHistoryItemText = ({
    id,
    field,
    value,
  }: {
    id: ClaimHistoryItem["id"];
    field: "claim_count" | "claim_amount";
    value: any;
  }) => {
    setValues((vals) => ({
      ...vals,
      claimsHistory: vals.claimsHistory.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
          };
        }

        return item;
      }),
    }));
  };

  const handleChangeLOL = (
    lolName: PublicLiabilityLOL["lolName"],
    value: string
  ) => {
    setValues((vals) => ({
      ...vals,
      selectedLOLValues: {
        ...vals.selectedLOLValues,
        "K1million LOL": "No",
        "K2million LOL": "No",
        "K5million LOL": "No",
        "K10million LOL": "No",
        [lolName]: value,
      },
      ...doCalculations({
        ...vals,
        selectedLOLValues: {
          ...vals.selectedLOLValues,
          "K1million LOL": "No",
          "K2million LOL": "No",
          "K5million LOL": "No",
          "K10million LOL": "No",
          [lolName]: value,
        },
      }),
    }));
  };

  // const handleChangeLOLPremiumAmount = (value: number) => {
  //   setValues((vals) => ({
  //     ...vals,
  //     lolPremiumAmount: value,
  //     ...doTaxCalculations({ ...vals, lolPremiumAmount: value }),
  //   }));
  // };

  return (
    <PublicLiabilityForm
      color={color}
      subTitle={subTitle}
      title={title}
      isLoadingMain={values.loading}
      isLoadingCategories={loadingCategories}
      isLoadingOccupations={loadingOccupations}
      selectedOccupation={values.selectedOccupation}
      selectedCategory={values.selectedCategory}
      turnover={values.turnover}
      lolPremiumAmount={values.lolPremiumAmount}
      claimsHistory={values.claimsHistory}
      categories={categories}
      occupations={occupations}
      stampDuty={stampDuty}
      publicLiabilityLOL={data?.publicLiabilityLOL ?? []}
      selectedLOLValues={values.selectedLOLValues}
      selectedLOLAmounts={values.selectedLOLAmounts}
      onChangeLOLValue={handleChangeLOL}
      onChangeCategory={handleChangeCategory}
      onChangeOccupation={handleChangeOccupation}
      onChangeTurnoverText={handeChangeTurnoverText}
      onAddClaimsHistoryItem={handleAddClaimsHistoryItem}
      onDeleteClaimsHistoryItem={handleDeleteClaimsHistoryItem}
      onChangeClaimHistoryClaimItemText={handleChangeClaimHistoryItemText}
    />
  );
}

export default React.forwardRef((props: Props, ref?: Ref<any>) => (
  <PublicLiabilityQuoteForm {...props} innerRef={ref} />
));
